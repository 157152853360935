







































































import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import { CallDirection, TriggerCall } from '@infinity/ca-tester/models/triggerCall'
import { AuthUtil } from '@infinity/shared/utils/auth'
import { formatTriggerDateTime } from '@infinity/ca-tester/utils/time'
import PhoneNumberComponent from '@infinity/ca-tester/components/utils/PhoneNumber.vue'
import { find } from 'lodash'
import InfinityPhoneNumber from '@/components/utils/InfinityPhoneNumber.vue'

@Component({
  components: {
    PhoneNumberComponent,
    InfinityPhoneNumber
  }
})
export default class CallRow extends Vue {
  @Prop({ required: true })
  call!: TriggerCall

  get installationId () {
    return AuthUtil.installationId
  }

  get formattedDate () {
    return formatTriggerDateTime(this.call.getTriggerDatetime())
  }

  get callIcon () {
    if (this.call) {
      if (this.isSelected) {
        return 'check'
      }

      if (this.isVoicemail) {
        return 'voicemail'
      }

      if (this.isMissed) {
        return 'telephone-x'
      }

      if (this.isInbound) {
        return 'telephone-inbound'
      }

      if (this.isOutbound) {
        return 'telephone-outbound'
      }

      return 'telephone'
    }
  }

  get isVoicemail () {
    return this.call.getCallStage() === 'voicemail'
  }

  get isInbound () {
    return this.call.getCallDirection() === CallDirection.In
  }

  get isOutbound () {
    return this.call.getCallDirection() === CallDirection.Out
  }

  get isSelected () {
    return find(this.$store.state.calls.selectedCalls, { rowId: this.call.getRowId() }) !== undefined
  }

  get callIconVariant () {
    if (this.isSelected) {
      return 'info'
    }

    if (this.isMissed) {
      return 'danger'
    }

    return 'success'
  }

  get isMissed () {
    return this.call.getBridgeDuration() === '0'
  }

  @Emit('select')
  doClick () {
    return this.call
  }
}
