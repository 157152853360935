var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-card",
        [
          _vm._t("default"),
          _c(
            "div",
            {
              staticClass: "d-flex align-items-center justify-content-between"
            },
            [
              _c(
                "div",
                { staticClass: "d-flex align-items-center" },
                [
                  _c(
                    "h5",
                    { staticClass: "mb-0" },
                    [_c("b-icon-sliders"), _vm._v(" Keyword Spotting")],
                    1
                  ),
                  _vm.$store.state.caConfig.lastCompareGroupId > 0
                    ? _c(
                        "b-button",
                        {
                          directives: [
                            {
                              name: "test",
                              rawName: "v-test",
                              value: "addGroup",
                              expression: "'addGroup'"
                            }
                          ],
                          staticClass: "mx-2",
                          attrs: { variant: "outline" },
                          on: { click: _vm.addGroup }
                        },
                        [_c("b-icon-plus"), _vm._v(" Add group ")],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ]
          ),
          _c(
            "div",
            {
              staticClass: "d-flex align-items-center justify-content-between"
            },
            [
              _c(
                "div",
                { staticClass: "col-md-3 d-flex align-items-center p-0" },
                [
                  _vm._v(" Sort "),
                  _c(
                    "div",
                    { staticClass: "ml-2 p-0" },
                    [
                      _c(
                        "b-button",
                        {
                          ref: "sortKeywords",
                          staticClass: "ml-auto",
                          attrs: { variant: "outline-secondary" },
                          on: { click: _vm.toggleDropdownMenu }
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.caConfig.selectedKwgsSort) + " "
                          ),
                          _c("b-icon-chevron-down")
                        ],
                        1
                      ),
                      _c(
                        "infinity-popover",
                        {
                          staticClass: "sort-keywords",
                          attrs: {
                            variant: "outline",
                            target: _vm.$refs.sortKeywords,
                            show: _vm.sortMenuOpen
                          },
                          on: {
                            hidden: _vm.onDropdownHide,
                            clickOutside: _vm.onDropdownHide
                          }
                        },
                        [
                          _vm.sortMenuOpen
                            ? _c(
                                "b-list-group",
                                _vm._l(_vm.caConfig.kwgsSorts, function(
                                  sort,
                                  key
                                ) {
                                  return _c(
                                    "b-list-group-item",
                                    {
                                      key: sort,
                                      staticClass:
                                        "d-flex align-items-center dropdown-menu-items",
                                      on: {
                                        click: function($event) {
                                          _vm.caConfig.sortKeywordSpottingConfig(
                                            sort
                                          )
                                          _vm.onDropdownHide()
                                        }
                                      }
                                    },
                                    [
                                      _c("b-icon", {
                                        attrs: {
                                          "shift-h": "-2",
                                          scale: "1.5",
                                          icon:
                                            key ===
                                            _vm.caConfig.selectedKwgsSort
                                              ? "check"
                                              : ""
                                        }
                                      }),
                                      _vm._v(" " + _vm._s(key) + " ")
                                    ],
                                    1
                                  )
                                }),
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "d-flex w-50 justify-content-end align-items-center"
                },
                [
                  _vm.$store.state.caConfig.lastBaseGroupId > 0
                    ? _c(
                        "b-form-checkbox",
                        {
                          directives: [
                            {
                              name: "infinity-tooltip",
                              rawName: "v-infinity-tooltip",
                              value: "Show / Hide Deleted",
                              expression: "'Show / Hide Deleted'"
                            },
                            {
                              name: "test",
                              rawName: "v-test",
                              value: "showDeleted",
                              expression: "'showDeleted'"
                            }
                          ],
                          staticClass: "mx-2",
                          attrs: { name: "check-button", switch: "" },
                          on: { change: _vm.toggleShowDeleted },
                          model: {
                            value: _vm.showDeleted,
                            callback: function($$v) {
                              _vm.showDeleted = $$v
                            },
                            expression: "showDeleted"
                          }
                        },
                        [_c("b-icon-trash")],
                        1
                      )
                    : _vm._e(),
                  _vm.$store.state.caConfig.lastCompareKeywordId > 0
                    ? _c(
                        "b-button",
                        {
                          directives: [
                            {
                              name: "infinity-tooltip",
                              rawName: "v-infinity-tooltip",
                              value: "Expand / Collapse all",
                              expression: "'Expand / Collapse all'"
                            }
                          ],
                          staticClass: "mx-2",
                          attrs: { variant: "outline" },
                          on: {
                            click: function($event) {
                              _vm.toggleAll = !_vm.toggleAll
                            }
                          }
                        },
                        [
                          _c("b-icon", {
                            attrs: {
                              icon: _vm.toggleAll
                                ? "arrows-collapse"
                                : "arrows-expand"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.$store.state.caConfig.lastCompareKeywordId > 0
                    ? _c("b-input", {
                        staticClass: "w-50",
                        attrs: {
                          type: "search",
                          debounce: "250",
                          placeholder: "Find by group or phrase..."
                        },
                        model: {
                          value: _vm.search,
                          callback: function($$v) {
                            _vm.search = $$v
                          },
                          expression: "search"
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            ]
          ),
          _c(
            "b-list-group",
            { staticClass: "mt-3" },
            _vm._l(_vm.visibleConfig, function(config) {
              return _c("keyword-group-row", {
                directives: [
                  {
                    name: "test",
                    rawName: "v-test",
                    value: ["keywordGroup", config.getKeywordGroup().getId()],
                    expression:
                      "['keywordGroup', config.getKeywordGroup().getId()]"
                  }
                ],
                key: config.getKeywordGroup().getId(),
                staticClass: "mb-2",
                attrs: {
                  "ca-config": _vm.caConfig,
                  "keyword-group": config.getKeywordGroup(),
                  "show-deleted": _vm.showDeleted,
                  query: _vm.search,
                  open: _vm.toggleAll || _vm.search.length > 0
                }
              })
            }),
            1
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }