






import { Component, Prop, Vue } from 'vue-property-decorator'
import PhoneNumberComponent from '@/components/utils/PhoneNumber.vue'
import { TriggerCall } from '@/models/triggerCall'
import { get as lodashGet } from 'lodash'

const RedactedValue = 'Redacted (GDPR)'

@Component({
  components: {
    PhoneNumberComponent
  }
})
export default class InfinityPhoneNumber extends Vue {
  @Prop({ type: Object, required: true })
  callTrigger!: TriggerCall

  @Prop({ type: String, required: true })
  field!: string

  @Prop({ type: String, default: '' })
  country!: string

  @Prop({ type: String, default: '' })
  lineType!: string

  get numberValue () {
    if (this.isRedacted) {
      return RedactedValue
    }

    return lodashGet(this.callTrigger, this.field, '')
  }

  get isRedacted () {
    return lodashGet(this.callTrigger, `${this.field}Redacted`, false)
  }
}
