












import { Component, Prop, Vue } from 'vue-property-decorator'
import parsePhoneNumber, { PhoneNumber } from 'libphonenumber-js/max'
import FlagIcon from '@/components/utils/FlagIcon.vue'

@Component({
  components: {
    FlagIcon
  }
})
export default class PhoneNumberComponent extends Vue {
  @Prop({ type: String, required: true })
  phoneNumber!: string

  @Prop({ type: Boolean, default: false })
  showMobile!: boolean

  @Prop({ type: String, default: '' })
  country!: string

  @Prop({ type: String, default: '' })
  lineType!: string

  get parsedPhoneNumber (): PhoneNumber | undefined {
    try {
      return parsePhoneNumber(this.phoneNumber)
    } catch (e) {
      return undefined
    }
  }

  get formattedNumber () {
    if (!this.parsedPhoneNumber) {
      return this.phoneNumber.replace(/ *\[[^)]*] */g, '')
    }

    return this.parsedPhoneNumber.formatNational()
  }

  get isMobile () {
    if (this.lineType === 'MOBILE') {
      return true
    }

    if (!this.parsedPhoneNumber) {
      return false
    }

    return this.parsedPhoneNumber.getType() === 'MOBILE'
  }

  get countryCode (): string | null {
    if (this.country) {
      return this.country
    }

    if (!this.parsedPhoneNumber) {
      return null
    }

    return this.parsedPhoneNumber.country || null
  }
}
