import { Component, Vue } from 'vue-property-decorator'
import Model from '@infinity/shared/models/base'
import KeywordConfig, { KeywordLeg } from '@infinity/ca-tester/models/keywordConfig'
import { Sentence } from '@infinity/ca-tester/models/sentence'
import SentenceWord from '@infinity/ca-tester/models/sentenceWord'
import SpottedKeyword from '@infinity/ca-tester/models/spottedKeyword'
import { SpottingResult } from '@infinity/ca-tester/models/spottingResult'

@Component
export default class GetKeywordsMixin extends Vue {
  getKeywords (sentence: Sentence, results: SpottingResult) {
    if (sentence.getLeg() === KeywordLeg.Operator) {
      return results.getOperatorKeywords().filter(
        (keyword) => {
          const startTimeS = Math.floor(sentence.getStartTime() / 1000)
          const endTimeS = Math.floor(sentence.getEndTime() / 1000)

          return keyword.getStartTime() >= startTimeS && keyword.getStartTime() <= endTimeS
        }
      )
    }

    return results.getContactKeywords().filter(
      (keyword) => {
        const startTimeS = Math.floor(sentence.getStartTime() / 1000)
        const endTimeS = Math.floor(sentence.getEndTime() / 1000)

        return keyword.getStartTime() >= startTimeS && keyword.getStartTime() <= endTimeS
      }
    )
  }

  getKeyword (keywords: SpottedKeyword[], sentenceWord: SentenceWord): KeywordConfig | Model | undefined {
    if (keywords && keywords.length > 0) {
      const keyword = keywords.find(
        (keyword) => {
          return keyword.getKeywordId() === sentenceWord.getKeywordId()
        }
      )

      if (keyword) {
        return keyword.getKeywordConfig()
      }
    }
  }

  isKeywordSentence (keyword: KeywordConfig, sentence: Sentence, sentenceWord: SentenceWord): boolean {
    const parts = keyword.getPhrase().toLowerCase().split(' ')
    const word = sentenceWord.getWord().toLowerCase()

    if (keyword.getLeg() !== KeywordLeg.All && keyword.getLeg() !== sentence.getLeg()) {
      return false
    }

    if (parts.length === 1) {
      return word === keyword.getPhrase().toLowerCase()
    }

    if (!parts.includes(word)) {
      return false
    }

    // Get all words in the sentence in the same proximity as the keyword phrase
    const sentenceIndex = sentence.getWords().indexOf(sentenceWord)
    const wordIndex = parts.indexOf(word)
    const phraseStartIndex = sentenceIndex - wordIndex
    const phraseEndIndex = phraseStartIndex + parts.length

    if (phraseStartIndex < 0 || phraseEndIndex > sentence.getWords().length) {
      return false
    }

    const phraseWords = sentence.getWords().slice(phraseStartIndex, phraseEndIndex)

    // Loop through each word, if any are not in the same place as the keyword, return false
    for (const [index, phraseWord] of phraseWords.entries()) {
      if (parts.indexOf(phraseWord.getWord().toLowerCase()) !== index) {
        return false
      }
    }

    return true
  }

  getCompareKeyword (keywordConfig: KeywordConfig[], sentence: Sentence, sentenceWord: SentenceWord): KeywordConfig | undefined {
    if (keywordConfig && keywordConfig.length > 0) {
      return keywordConfig.find(
        (keyword) => this.isKeywordSentence(keyword, sentence, sentenceWord)
      )
    }
  }

  getCompareKeywordBool (keywordConfig: KeywordConfig[], sentence: Sentence, sentenceWord: SentenceWord): boolean {
    return keywordConfig.some(
      (keyword) => this.isKeywordSentence(keyword, sentence, sentenceWord)
    )
  }
}
