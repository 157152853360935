









































import { Component, Prop, Vue } from 'vue-property-decorator'
import { msToTimeString } from '@infinity/ca-tester/utils/time'
import { TriggerCall } from '@infinity/ca-tester/models/triggerCall'
import Repository from '@infinity/ca-tester/repos/base'
import { Api } from '@infinity/shared/helpers/api'
import { AuthUtil } from '@infinity/shared/utils/auth'
import axios from 'axios'
import { CallRecording } from '@infinity/ca-tester/models/callRecording'
import { ButtonGroupPlugin, ButtonPlugin, OverlayPlugin, SpinnerPlugin } from 'bootstrap-vue'

Vue.use(ButtonPlugin)
Vue.use(ButtonGroupPlugin)
Vue.use(SpinnerPlugin)
Vue.use(OverlayPlugin)

export const callPlaybackSeek = 'infinity::player::seek'
export const callPlaybackPlay = 'infinity::player::play'

@Component
export default class CallPlayer extends Vue {
  @Prop({ required: true })
  call!: TriggerCall

  @Prop({ default: 1, type: Number })
  offsetSeconds!: number

  private file: string | null = null
  private isLoading = true
  private hasError = false
  private audio: HTMLAudioElement | null = null
  private sliderHoverValue = '00:00'
  private recordingSeconds = 0

  private recordingRepo = new Repository(
    Api.Hub,
    `igrps/${AuthUtil.installationId}/call-recording`,
    CallRecording
  )

  async mounted () {
    await this.fetchRecording()

    this.$store.commit('calls/resetCurrentPlaybackMs')
    this.$root.$on(callPlaybackSeek, this.seek)
    this.$root.$on(callPlaybackPlay, this.play)
  }

  set playbackRate (value: number) {
    if (this.audio) {
      this.audio.playbackRate = value
    }
  }

  get playbackRate () {
    if (this.audio) {
      return this.audio.playbackRate
    }

    return 1
  }

  get isPlaying () {
    return this.$store.state.calls.isPlaying
  }

  set isPlaying (value: boolean) {
    this.$store.commit('calls/setIsPlaying', value)
  }

  get currentMs () {
    return this.$store.state.calls.currentPlaybackMs
  }

  updateSliderValue (e: MouseEvent) {
    if (e.target && e.target instanceof HTMLElement && (e.offsetX >= 0)) {
      const max = e.target.getAttribute('max') || '0'

      this.sliderHoverValue = msToTimeString((e.offsetX / e.target.clientWidth) * parseInt(max, 10))
    }
  }

  download (): void {
    if (this.file) {
      // Stop audio if playing.
      if (this.isPlaying) {
        this.stop()
      }
      // Create a download link.
      const link = document.createElement('a')
      link.download = 'recording.mp3'
      link.href = this.file
      link.click()
    }
  }

  async seek (value: number, play = false, withOffset = true) {
    if (!this.file && !this.hasError) {
      await this.fetchRecording()
    }

    this.$nextTick(
      () => {
        if (this.audio) {
          this.audio.currentTime = (value / 1000) - (withOffset ? this.offsetSeconds : 0)
        }

        if (play && !this.isPlaying) {
          this.play()
        }
      }
    )
  }

  load () {
    if (this.audio && this.audio.readyState >= 2) {
      this.recordingSeconds = this.audio.duration
    }
  }

  stop () {
    if (this.audio) {
      this.isPlaying = false
      this.audio.currentTime = 0
      this.audio.pause()
    }
  }

  async play () {
    if (!this.file && !this.hasError) {
      await this.fetchRecording()
    }

    this.$nextTick(
      () => {
        if (this.audio) {
          if (this.isPlaying) {
            this.audio.pause()

            return
          }

          this.audio.play()
        }
      }
    )
  }

  update () {
    if (this.audio) {
      this.$store.commit('calls/setCurrentPlaybackMs', this.audio.currentTime * 1000)

      return this.currentMs
    }
  }

  async fetchRecording () {
    try {
      this.isLoading = true
      const recording = await this.recordingRepo.find(this.call.getRowId())

      if (recording && recording.getCallRecording()) {
        const response = await axios.get(recording.getCallRecording(), { responseType: 'blob' })

        if (response.data) {
          this.file = window.URL.createObjectURL(response.data)
        }
      }
    } catch (e) {
      this.hasError = true
    }

    if (this.file) {
      this.$nextTick(
        () => {
          this.initPlaybackBar()
        }
      )
    }

    this.isLoading = false
  }

  initPlaybackBar () {
    if (this.file) {
      this.audio = this.$el.querySelectorAll('audio')[0]
      this.audio.addEventListener('timeupdate', this.update)
      this.audio.addEventListener('pause', this.setNotIsPlaying)
      this.audio.addEventListener('play', this.setIsPlaying)
      this.audio.addEventListener('loadeddata', this.load)
    }
  }

  setIsPlaying () {
    this.isPlaying = true
  }

  setNotIsPlaying () {
    this.isPlaying = false
  }

  beforeDestroy () {
    this.$store.commit('calls/resetCurrentPlaybackMs')

    if (this.audio) {
      this.audio.removeEventListener('timeupdate', this.update)
      this.audio.removeEventListener('pause', this.setNotIsPlaying)
      this.audio.removeEventListener('play', this.setIsPlaying)
      this.audio.removeEventListener('loadeddata', this.load)

      this.$root.$off(callPlaybackSeek, this.seek)
      this.$root.$off(callPlaybackPlay, this.play)
    }
  }
}
