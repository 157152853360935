import Vue from 'vue'
import infinityTooltip from '@infinity/ca-tester/directives/infinity-tooltip'
import InfinityPopover from '@infinity/ca-tester/components/layout/InfinityPopover.vue'

export default {
  install: function (vue: typeof Vue) {
    vue.directive('infinity-tooltip', infinityTooltip)
    vue.component('infinity-popover', InfinityPopover)
  }
}
