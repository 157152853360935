










































import { Component, Prop, Vue } from 'vue-property-decorator'
import Installation from '@infinity/ca-tester/models/installation'
import { AuthUtil } from '@infinity/shared/utils/auth'
import Repository from '@infinity/ca-tester/repos/base'
import { Api } from '@infinity/shared/helpers/api'
import { Filter, FilterCollection, FilterOperand } from '@infinity/ca-tester/helpers/filter'
import Model from '@infinity/shared/models/base'

@Component
export default class InstallationPicker extends Vue {
  @Prop({ type: String, default: 'left' }) align?: string
  private installations: Model[] | Installation[] | null = null

  private findRepo = new Repository(Api.Hub, 'igrps', Installation, 'igrp')
  private fetchRepo = new Repository(Api.Hub, 'igrps', Installation, 'igrps')
  private isLoading: boolean | null = null
  private isLoadingSelected: boolean | null = null

  async created () {
    if (AuthUtil.installationId && !this.$store.state.selectedInstallation.id) {
      this.isLoadingSelected = true
      const installation = await this.findRepo.find(AuthUtil.installationId)

      if (installation) {
        this.$store.commit('setInstallation', installation)
        this.isLoadingSelected = false
      }
    }
  }

  async getInstallations () {
    this.isLoading = true
    this.installations = await this.fetchRepo.fetch(null, 5)
    this.isLoading = false
  }

  get dropDownName (): string {
    if (this.$store.state.selectedInstallation.id) {
      return this.$store.state.selectedInstallation.getName()
    }

    return 'Select your installation'
  }

  doSelectInstallation (installation: Installation) {
    this.$store.commit('setInstallation', installation)
    AuthUtil.setInstallationId(installation.getId())
    AuthUtil.setInstallationTimeZone(installation.getTimeZone())
    this.$emit('selected', installation)
    this.$router.go(0)
  }

  async doSearch (value: string) {
    if (value.length > 3) {
      this.isLoading = true

      this.installations = await this.fetchRepo.fetch(
        new FilterCollection()
          .addFilter(
            new Filter('igrpName', FilterOperand.IncludesI, value)
          ),
        5
      )

      this.isLoading = false
    }

    if (!value) {
      await this.getInstallations()
    }
  }
}
