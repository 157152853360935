import Repository from '@infinity/ca-tester/repos/base'
import { Api, JsonData } from '@infinity/shared/helpers/api'
import { AuthUtil } from '@infinity/shared/utils/auth'
import DraftCaConfig from '@infinity/ca-tester/models/draftCaConfig'
import axios from 'axios'

export default class DraftCaConfigRepo extends Repository {
  constructor () {
    super(
      Api.CaTestingService,
      `draft/installation/${AuthUtil.installationId}`,
      DraftCaConfig,
      'data'
    )
  }

  async fetchDraftKwsConfig (): Promise<DraftCaConfig | null> {
    const data = await super.find()

    if (data instanceof DraftCaConfig) {
      return data
    }

    return null
  }

  async saveDraftKwsConfig (model: DraftCaConfig): Promise<JsonData | null> {
    return await super.create(model)
  }

  async deleteDraftKwsConfig () {
    return await axios.delete(`${this.api}/${this.apiRoute}`, {
      ...this.requestConfig
    })
  }
}
