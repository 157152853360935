var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: {
        "call-row": true,
        "d-flex": true,
        "flex-wrap": true,
        "p-3": true,
        "align-items-center": true,
        rounded: true,
        "shadow-sm": true,
        "bg-white": true,
        "border-info": _vm.isSelected
      },
      on: { click: _vm.doClick }
    },
    [
      _c("b-avatar", {
        staticClass: "p-1 mr-3",
        attrs: { icon: _vm.callIcon, variant: _vm.callIconVariant }
      }),
      _c(
        "div",
        {
          directives: [
            {
              name: "test",
              rawName: "v-test",
              value: "call info",
              expression: "'call info'"
            }
          ]
        },
        [
          _vm.isVoicemail
            ? [_c("strong", [_vm._v("Voicemail")]), _vm._v(" from")]
            : _vm.isMissed
            ? [
                _c("strong", [_vm._v("Missed call")]),
                _vm._v(" " + _vm._s(_vm.isInbound ? "from" : "to"))
              ]
            : [_vm._v("Call " + _vm._s(_vm.isInbound ? "from" : "to"))],
          _c(
            "span",
            [
              _c("infinity-phone-number", {
                staticClass: "text-whale ml-1",
                attrs: {
                  "call-trigger": _vm.call,
                  field: "srcPhoneNumber",
                  country: _vm.call.getCountryCode(),
                  "line-type": _vm.call.getLineType()
                }
              })
            ],
            1
          ),
          _c(
            "small",
            { staticClass: "w-100 d-block mt-1" },
            [
              _vm._v(" " + _vm._s(_vm.isOutbound ? "from" : "to") + " "),
              _vm.call.getOperatorRef()
                ? [
                    _c("b-icon-headset", { staticClass: "mr-1" }),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "test",
                            rawName: "v-test",
                            value: "operator ref",
                            expression: "'operator ref'"
                          }
                        ]
                      },
                      [_vm._v(_vm._s(_vm.call.getOperatorRef()))]
                    )
                  ]
                : _c("phone-number-component", {
                    directives: [
                      {
                        name: "test",
                        rawName: "v-test",
                        value: "dest number",
                        expression: "'dest number'"
                      }
                    ],
                    attrs: { "phone-number": _vm.call.getDestPhoneNumber() }
                  }),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "infinity-tooltip",
                      rawName: "v-infinity-tooltip",
                      value: "The tracking pool this call was routed to",
                      expression: "'The tracking pool this call was routed to'"
                    },
                    {
                      name: "test",
                      rawName: "v-test",
                      value: "tracking pool",
                      expression: "'tracking pool'"
                    }
                  ],
                  staticClass: "text-whale"
                },
                [_vm._v(" - " + _vm._s(_vm.call.getTrackingPoolName()))]
              ),
              !_vm.isMissed
                ? _c(
                    "span",
                    { staticClass: "ml-1" },
                    [
                      _vm._v(" | "),
                      _c("b-icon-clock"),
                      _vm._v(" "),
                      _c(
                        "strong",
                        {
                          directives: [
                            {
                              name: "infinity-seconds",
                              rawName: "v-infinity-seconds",
                              value: _vm.call.getCallDuration(),
                              expression: "call.getCallDuration()"
                            },
                            {
                              name: "test",
                              rawName: "v-test",
                              value: "call duration",
                              expression: "'call duration'"
                            }
                          ]
                        },
                        [_vm._v(_vm._s(_vm.call.getCallDuration()))]
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            2
          )
        ],
        2
      ),
      _c("div", { staticClass: "ml-auto" }, [
        _c("div", { staticClass: "w-100 d-flex flex-row-reverse" }, [
          _c(
            "small",
            {
              directives: [
                {
                  name: "infinity-tooltip",
                  rawName: "v-infinity-tooltip",
                  value: "The date and time the call occurred",
                  expression: "'The date and time the call occurred'"
                },
                {
                  name: "test",
                  rawName: "v-test",
                  value: "call date",
                  expression: "'call date'"
                }
              ],
              staticClass: "ml-2"
            },
            [
              _c("b-icon-calendar"),
              _vm._v(" "),
              _c("strong", [_vm._v(_vm._s(_vm.formattedDate))])
            ],
            1
          )
        ]),
        _c("div", { staticClass: "w-100 d-flex flex-row-reverse mt-2" }, [
          _vm.call.getGoalTitle()
            ? _c(
                "small",
                {
                  directives: [
                    {
                      name: "infinity-tooltip",
                      rawName: "v-infinity-tooltip",
                      value: "The goal triggered by this call.",
                      expression: "'The goal triggered by this call.'"
                    },
                    {
                      name: "test",
                      rawName: "v-test",
                      value: "call goal",
                      expression: "'call goal'"
                    }
                  ],
                  staticClass: "ml-2 text-success"
                },
                [
                  _c("b-icon-award-fill"),
                  _vm._v(" " + _vm._s(_vm.call.getGoalTitle()) + " ")
                ],
                1
              )
            : _vm._e(),
          _vm.call.getChannel()
            ? _c(
                "small",
                {
                  directives: [
                    {
                      name: "infinity-tooltip",
                      rawName: "v-infinity-tooltip",
                      value: this.call.getPublisher()
                        ? "This caller found you via " +
                          this.call.getPublisher() +
                          "."
                        : "The channel identified for this caller.",
                      expression:
                        "this.call.getPublisher() ? `This caller found you via ${this.call.getPublisher()}.` : 'The channel identified for this caller.'"
                    },
                    {
                      name: "test",
                      rawName: "v-test",
                      value: "call channel",
                      expression: "'call channel'"
                    }
                  ],
                  staticClass: "ml-2"
                },
                [
                  _c("b-icon-collection-fill"),
                  _vm._v(" " + _vm._s(_vm.call.getChannel()) + " ")
                ],
                1
              )
            : _vm._e()
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }