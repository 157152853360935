import tippy, { Props, ReferenceElement, followCursor as followCursorPlugin } from 'tippy.js'
// eslint-disable-next-line import/no-unresolved
import { DirectiveBinding } from 'vue/types/options'
import { assign } from 'lodash'

export enum PoppyArguments { Follow = 'follow' }

export enum FollowModifiers { Horizontal = 'horizontal', Vertical = 'vertical', Initial = 'initial' }

function getTippyProps (el: ReferenceElement, binding: DirectiveBinding): Partial<Props> {
  const props: Partial<Props> = {
    appendTo: (el.getRootNode() as Element) || document.body,
    arrow: true,
    content: binding.value,
    delay: [5, null],
    allowHTML: false,
    maxWidth: 350,
    hideOnClick: false,
    // Tippy supports using data attributes to set properties. As we'll be setting this on the instance, we can
    // disable this which will also improve performance.
    ignoreAttributes: true
  }

  if (binding.arg === PoppyArguments.Follow) {
    let followCursor: boolean | string = true

    if (binding.modifiers[FollowModifiers.Horizontal]) {
      followCursor = FollowModifiers.Horizontal
    }

    if (binding.modifiers[FollowModifiers.Vertical]) {
      followCursor = FollowModifiers.Vertical
    }

    if (binding.modifiers[FollowModifiers.Initial]) {
      followCursor = FollowModifiers.Initial
    }

    assign(props, { followCursor, plugins: [followCursorPlugin] })
  }

  return props
}

/**
 * A directive for adding a tooltip to an element. It will attempt to bind to the [root node](https://developer.mozilla.org/en-US/docs/Web/API/Node/getRootNode), defaulting to the document body if unavailable.
 * If no content is available in the binding, a tooltip will not be rendered. If a previously empty variable takes on a value, the tooltip will be initialised and rendered.
 *
 * ## Basic usage:
 * ```html
 *  <span v-infinity-tooltip="'This message does not change'">Tooltip with static message</span>
 *  <span v-infinity-tooltip="variableMessage">Tooltip with variable message</span>
 * ```
 *
 * ## Follow cursor:
 * Controls the tippy.js [followCursor plugin](https://atomiks.github.io/tippyjs/v6/all-props/#followcursor)
 * ```html
 *  <!-- followCursor: true -->
 *  <span v-infinity-tooltip:follow="'Message'">I will follow the cursor</span>
 *   <!-- followCursor: 'horizontal' -->
 *  <span v-infinity-tooltip:follow.horizontal="'Message'">I will follow the cursor along the X axis</span>
 *   <!-- followCursor: 'vertical' -->
 *  <span v-infinity-tooltip:follow.vertical="'Message'">I will follow the cursor along the Y axis</span>
 *   <!-- followCursor: 'initial' -->
 *  <span v-infinity-tooltip:follow.initial="'Message'">I will render at the point the cursor was on trigger</span>
 * ```
 */
export default {
  inserted: function (el: Element, binding: DirectiveBinding) {
    if (binding.value) {
      tippy(el, getTippyProps(el, binding))
    }
  },
  update: function (el: ReferenceElement, binding: DirectiveBinding) {
    if (el._tippy) {
      el._tippy.setProps({
        content: binding.value
      })

      return
    }

    tippy(el, getTippyProps(el, binding))
  },
  unbind: function (el: ReferenceElement) {
    if (el._tippy) {
      el._tippy.destroy()
    }
  }
}
