import Model, { ApiFieldDefinition, apiToModelTransformer, modelToApiTransformer } from '@infinity/shared/models/base'
import { Api, JsonData } from '@infinity/shared/helpers/api'

export enum TimeframePlace { First = 'first', Last = 'last' }
export enum TimeframeDimension { Seconds = 'seconds', Percent = 'percent' }

export default class KeywordCriteriaTimeframe extends Model {
  protected fieldDefinition: ApiFieldDefinition = {
    [Api.CaService]: {
      place: 'place',
      duration: 'duration',
      dimension: 'dimension'
    }
  }

  private place = TimeframePlace.First
  private duration = 0
  private dimension = TimeframeDimension.Seconds

  getPlace (): TimeframePlace {
    return this.place
  }

  setPlace (value: TimeframePlace) {
    this.place = value

    return this
  }

  getDuration (): number {
    return this.duration
  }

  setDuration (value: number) {
    this.duration = value

    return this
  }

  getDimension (): TimeframeDimension {
    return this.dimension
  }

  setDimension (value: TimeframeDimension) {
    this.dimension = value

    return this
  }

  fromApiTransformer (data: JsonData, api: Api): KeywordCriteriaTimeframe {
    apiToModelTransformer(this, data, this.fieldDefinition[api])

    return this
  }

  toApiTransformer (api: Api): JsonData {
    return modelToApiTransformer(this, this.fieldDefinition[api])
  }
}
