




























































































import CaConfig from '@infinity/ca-tester/models/caConfig'
import { Component, Prop, Watch } from 'vue-property-decorator'
import { SpottingResult } from '@infinity/ca-tester/models/spottingResult'
import SentenceComponent from '@infinity/ca-tester/components/CallTranscription/Sentence.vue'
import { Sentence, SentenceSpeaker } from '@infinity/ca-tester/models/sentence'
import { mixins } from 'vue-class-component'
import GetKeywordsMixin from '@infinity/ca-tester/mixins/get-keywords'
import { find, findLast } from 'lodash'
import { KeywordLeg } from '@/models/keywordConfig'

@Component({
  components: {
    SentenceComponent
  }
})
export default class CallTranscription extends mixins(GetKeywordsMixin) {
  @Prop({ required: true })
  results!: SpottingResult

  @Prop({ required: true })
  sentences!: Sentence[]

  @Prop({ type: Boolean, default: false })
  isLoading!: boolean

  @Prop({ type: Boolean, default: false })
  canEdit!: boolean

  @Prop({ type: Boolean, default: false })
  canPlayReadOnly!: boolean

  @Prop({ type: Boolean, default: false })
  isSaved!: boolean

  @Prop({ type: Boolean, default: false })
  isDraft!: boolean

  @Prop({ type: Boolean, default: true })
  showSource!: boolean

  observer: IntersectionObserver | null = null
  loadedSentences: Sentence[] = []
  loadedKeywordSentences: Sentence[] = []
  sentenceBatch = 3
  showIvr = false
  batchOffset = 0

  get ivrStartTime () {
    const firstIvrSentence = find(this.loadedSentences, { leg: KeywordLeg.Operator, speaker: SentenceSpeaker.Ivr })

    if (firstIvrSentence instanceof Sentence) {
      return firstIvrSentence.getStartTime()
    }

    return 0
  }

  get ivrEndTime () {
    const lastIvrSentence = findLast(this.loadedSentences, { leg: KeywordLeg.Operator, speaker: SentenceSpeaker.Ivr })

    if (lastIvrSentence instanceof Sentence) {
      return lastIvrSentence.getEndTime()
    }

    return 0
  }

  get visibleSentences () {
    return this.loadedSentences.filter(
      (sentence: Sentence) => {
        return sentence.getEndTime() > this.ivrEndTime
      }
    )
  }

  get ivrSentences () {
    return this.loadedSentences.filter(
      (sentence: Sentence) => {
        return sentence.getStartTime() >= this.ivrStartTime && sentence.getEndTime() <= this.ivrEndTime
      }
    )
  }

  @Watch('isLoading')
  onLoad () {
    if (this.isLoading) {
      return
    }

    // Set up observer
    if (!this.observer) {
      const { keywordContainer } = this.$refs
      if (!keywordContainer || !(keywordContainer instanceof Element)) {
        return
      }

      this.observer = new IntersectionObserver(
        this.onSentenceObserved,
        {
          root: keywordContainer,
          rootMargin: '0px 1px 0px 1px',
          threshold: 1
        }
      )

      // Load first batch of sentences, up to the first sentence after IVR endTime
      const lastAgentIvrSentence = findLast(this.sentences, { leg: KeywordLeg.Operator, speaker: SentenceSpeaker.Ivr })
      if (lastAgentIvrSentence instanceof Sentence) {
        this.batchOffset = this.sentences.indexOf(lastAgentIvrSentence)
      }

      this.loadedSentences = this.sentences.slice(0, this.batchOffset + this.sentenceBatch)

      this.loadedKeywordSentences = this.keywordSentences.slice(0, this.sentenceBatch)
    }
  }

  beforeDestroy () {
    const observer = this.observer
    if (observer) {
      observer.disconnect()
    }
  }

  onSentenceObserved (entries: IntersectionObserverEntry[]) {
    const observer = this.observer
    if (!observer) {
      return
    }

    entries.forEach(({ target, isIntersecting }: IntersectionObserverEntry) => {
      if (!isIntersecting) {
        return
      }

      observer.unobserve(target)

      const indexAttribute = target.getAttribute('data-index')
      if (indexAttribute !== null) {
        const index = parseInt(indexAttribute)

        if (this.showTranscript) {
          this.loadSentences(index + this.batchOffset, this.sentenceBatch + this.batchOffset)
        } else {
          this.loadKeywordSentences(index, this.sentenceBatch)
        }
      }
    })
  }

  loadSentences (lastIndex: number, numOfSentences = 1) {
    for (const sentence of this.sentences.slice(lastIndex + 1, lastIndex + numOfSentences + 1)) {
      if (sentence && this.loadedSentences.indexOf(sentence) === -1) {
        this.loadedSentences.push(sentence)
      }
    }
  }

  loadKeywordSentences (lastIndex: number, numOfSentences = 1) {
    for (const sentence of this.keywordSentences.slice(lastIndex + 1, lastIndex + numOfSentences + 1)) {
      if (sentence && this.loadedKeywordSentences.indexOf(sentence) === -1) {
        this.loadedKeywordSentences.push(sentence)
      }
    }
  }

  loadKeywordSentencesForKeyword (phrase: string) {
    for (const sentence of this.keywordSentences.filter(
      (sentence) => {
        return sentence.getWords().map((word) => { return word.getWord() }).join(' ').toLowerCase().includes(phrase)
      }
    )) {
      if (sentence && this.loadedKeywordSentences.indexOf(sentence) === -1) {
        this.loadedKeywordSentences.push(sentence)
      }
    }
  }

  get sortedLoadedKeywordSentences () {
    return this.loadedKeywordSentences.sort((a, b) => {
      return a.getStartTime() - b.getStartTime()
    })
  }

  get showTranscript () {
    return this.$store.state.transcription.showTranscript
  }

  get compareConfig (): CaConfig {
    return this.$store.state.caConfig.compareConfig
  }

  get scrollTop () {
    return this.$store.state.transcription.scrollTop
  }

  @Watch('scrollTop')
  onScrollChange (scrollTop: number) {
    if (this.$refs.keywordContainer && this.$refs.keywordContainer instanceof Element) {
      if (scrollTop !== this.$refs.keywordContainer.scrollTop) {
        this.$refs.keywordContainer.scrollTop = scrollTop
      }
    }
  }

  get keywordSentences () {
    return this.sentences.filter((sentence) => {
      for (const word of sentence.getWords()) {
        if (word.getIsKeyword() && this.getCompareKeywordBool(this.compareConfig.getKeywordsConfig(), sentence, word)) {
          return true
        }
      }

      return false
    })
  }

  onScroll (event: Event) {
    const target = event.target as HTMLElement
    if (target) {
      this.$store.commit('transcription/setScrollTop', target.scrollTop)
    }
  }

  newKeyword (phrase: Event) {
    this.loadKeywordSentencesForKeyword(String(phrase))
  }
}
