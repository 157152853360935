import Vue from 'vue'
import InfinitySeconds from '@infinity/ca-tester/directives/infinity-seconds'
import InfinityMs from '@infinity/ca-tester/directives/infinity-ms'
import InfinityDateTime from '@infinity/ca-tester/directives/infinity-datetime'
import InfinityTimeAgo from '@infinity/ca-tester/directives/infinity-time-ago'

export default {
  install: function (vue: typeof Vue) {
    vue.directive('infinity-seconds', InfinitySeconds)
    vue.directive('infinity-ms', InfinityMs)
    vue.directive('infinity-datetime', InfinityDateTime)
    vue.directive('infinity-time-ago', InfinityTimeAgo)
  }
}
