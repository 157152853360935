import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import { AuthUtil } from '@infinity/shared/utils/auth'
import Login from '@infinity/ca-tester/views/Login.vue'
import CaTester from '@infinity/ca-tester/views/CaTester.vue'
import Calls from '@infinity/ca-tester/views/Calls.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/',
    name: 'Calls',
    component: Calls
  },
  {
    path: '/tester',
    name: 'CA Tester',
    component: CaTester
  }
]

const router = new VueRouter({
  mode: 'history',
  base: AuthUtil.basePath || process.env.BASE_URL,
  routes
})

router.beforeEach(
  (to, from, next) => {
    if (to.meta && (to.meta.requiresAuth === false)) {
      if (AuthUtil.isAuthenticated) {
        next('/')

        return
      }

      next()

      return
    }

    if (AuthUtil.isAuthenticated === false) {
      next({
        path: '/login',
        query: { redirect: to.fullPath }
      })

      return
    }

    next()
  }
)

export default router
