import Model, { ApiFieldDefinition, apiToModelTransformer, modelToApiTransformer } from '@infinity/shared/models/base'
import { Api, InfinityStatus, JsonData } from '@infinity/shared/helpers/api'
import KeywordConfig from '@infinity/ca-tester/models/keywordConfig'
import { SpottingResult } from '@infinity/ca-tester/models/spottingResult'
import { AuthUtil } from '@infinity/shared/utils/auth'
import KeywordSpottingConfig from '@infinity/ca-tester/models/keywordSpottingConfig'
import KeywordGroupConfig from '@infinity/ca-tester/models/keywordGroupConfig'
import DraftCaConfig from '@infinity/ca-tester/models/draftCaConfig'
import { SentenceResults } from '@infinity/ca-tester/models/sentenceResults'

export default class CaConfig extends Model {
  private id = ''
  private name = ''
  private installationId = 0
  private keywordSpottingConfig: KeywordSpottingConfig[] = []
  private spottingResults = new SpottingResult()
  private sentenceResults = new SentenceResults()

  kwgsSorts = {
    'Keyword Group (A-Z)': true,
    'Keyword Group (Z-A)': false
  }

  selectedKwgsSort = Object.keys(this.kwgsSorts)[0]
  sortedKwgsAsc = true

  protected fieldDefinition: ApiFieldDefinition = {
    [Api.CaTestingService]: {
      id: 'id',
      name: 'name',
      installationId: 'installationId'
    }
  }

  setId (id: string): CaConfig {
    this.id = id

    return this
  }

  getId (): string {
    return this.id
  }

  setName (name: string): CaConfig {
    this.name = name

    return this
  }

  getName (): string {
    return this.name
  }

  getInstallationId (): number {
    if (this.installationId === 0 && AuthUtil.installationId) {
      return parseInt(AuthUtil.installationId)
    }

    return this.installationId
  }

  setInstallationId (value: number) {
    this.installationId = value

    return this
  }

  setKeywordSpottingConfig (config: KeywordSpottingConfig[]) {
    this.keywordSpottingConfig = config

    return this
  }

  getKeywordSpottingConfig () {
    return this.keywordSpottingConfig
  }

  sortKeywordSpottingConfig (asc: boolean) {
    this.sortedKwgsAsc = asc

    if (this.sortedKwgsAsc) {
      this.selectedKwgsSort = Object.keys(this.kwgsSorts)[0]
      this.getKeywordSpottingConfig().sort((a: KeywordSpottingConfig, b: KeywordSpottingConfig) => a.getKeywordGroup().getName().localeCompare(b.getKeywordGroup().getName()))
    } else {
      this.selectedKwgsSort = Object.keys(this.kwgsSorts)[1]
      this.getKeywordSpottingConfig().sort((a: KeywordSpottingConfig, b: KeywordSpottingConfig) => b.getKeywordGroup().getName().localeCompare(a.getKeywordGroup().getName()))
    }
  }

  getDraftConfig (): DraftCaConfig {
    const draft = new DraftCaConfig()
    draft.setKeywordSpottingConfig(this.getKeywordSpottingConfig())

    return draft
  }

  getAllKeywordsConfig (): KeywordConfig[] {
    const keywords: KeywordConfig[] = []

    for (const config of this.keywordSpottingConfig) {
      for (const keyword of config.getKeywordGroup().getKeywords()) {
        keywords.push(keyword)
      }
    }

    return keywords
  }

  getKeywordsConfig (): KeywordConfig[] {
    const keywords: KeywordConfig[] = []

    for (const config of this.keywordSpottingConfig) {
      if (config.getKeywordGroup().getStatus() === InfinityStatus.Ok) {
        for (const keyword of config.getKeywordGroup().getKeywords()) {
          if (keyword.getStatus() === InfinityStatus.Ok) {
            keywords.push(keyword)
          }
        }
      }
    }

    return keywords
  }

  getKeywordGroups () {
    return this.keywordSpottingConfig
      .map(
        (config: KeywordSpottingConfig) => {
          return config.getKeywordGroup()
        }
      )
  }

  getKeywordGroupConfig (id: number) {
    const keywordSpottingConfig = this.keywordSpottingConfig.find(
      (config) => {
        return config.getKeywordGroup().getId() === id
      }
    )

    if (keywordSpottingConfig) {
      return keywordSpottingConfig.getKeywordGroup()
    }
  }

  removeKeywordGroup (group: KeywordGroupConfig) {
    for (const config of this.keywordSpottingConfig) {
      if (config.getKeywordGroup() === group) {
        const index = this.keywordSpottingConfig.indexOf(config)

        if (index > -1) {
          this.keywordSpottingConfig.splice(index, 1)
        }

        break
      }
    }
  }

  getSpottingResults (): SpottingResult {
    return this.spottingResults
  }

  setSpottingResults (value: SpottingResult) {
    this.spottingResults = value
  }

  getSentenceResults (): SentenceResults {
    return this.sentenceResults
  }

  setSentenceResults (value: SentenceResults) {
    this.sentenceResults = value

    return this
  }

  fromApiTransformer (data: JsonData, api: Api): CaConfig {
    apiToModelTransformer(this, data, this.fieldDefinition[api])

    if (
      data.spottingResults &&
      typeof data.spottingResults === 'object' &&
      !Array.isArray(data.spottingResults)
    ) {
      this.spottingResults = new SpottingResult()
        .fromApiTransformer(data.spottingResults, api)
    }

    if (
      data.sentenceResults &&
      typeof data.sentenceResults === 'object' &&
      !Array.isArray(data.sentenceResults)
    ) {
      this.sentenceResults = new SentenceResults()
        .fromApiTransformer(data.sentenceResults, api)
    }

    for (const operatorKeyword of this.spottingResults.getOperatorKeywords()) {
      const keywordConfig = this.getKeywordsConfig().find(
        (keyword) => {
          return keyword.getId() === operatorKeyword.getKeywordId()
        }
      )

      if (keywordConfig) {
        operatorKeyword.setKeywordConfig(keywordConfig)
      }
    }

    for (const contactKeyword of this.spottingResults.getContactKeywords()) {
      const keywordConfig = this.getKeywordsConfig().find(
        (keyword) => {
          return keyword.getId() === contactKeyword.getKeywordId()
        }
      )

      if (keywordConfig) {
        contactKeyword.setKeywordConfig(keywordConfig)
      }
    }

    return this
  }

  toApiTransformer (api: Api): JsonData {
    const data = modelToApiTransformer(this, this.fieldDefinition[api])

    if (!data.id) {
      delete data.id
    }

    delete data.spottingResults

    const keywordsConfig = []

    for (const keyword of this.getKeywordsConfig()) {
      const keywordData = keyword.toApiTransformer(api)

      if (keywordData) {
        if (keywordData.Phrase && typeof keywordData.Phrase === 'string') {
          keywordData.Phrase = keywordData.Phrase.toLowerCase()
        }

        keywordsConfig.push(keywordData)
      }
    }

    data.keywordsConfig = keywordsConfig

    return data
  }
}
