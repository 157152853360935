import Model, { ApiFieldDefinition, apiToModelTransformer, modelToApiTransformer } from '@infinity/shared/models/base'
import { Api, JsonData } from '@infinity/shared/helpers/api'
import KeywordCriteriaFirstSpokenBy from '@infinity/ca-tester/models/keywordCriteriaFirstSpokenBy'
import KeywordCriteriaProximity from '@infinity/ca-tester/models/keywordCriteriaProximity'
import KeywordCriteriaTimeframe from '@infinity/ca-tester/models/keywordCriteriaTimeframe'
import { isObject, isArray } from 'lodash'

export enum KeywordCriteriaType { FirstSpokenBy = 'firstSpokenBy', Proximity = 'proximityTime', Timeframe = 'timeframe' }
export enum KeywordCriteriaCombination { And = 'and', Or = 'or' }
export type KeywordCriteriaConfig = KeywordCriteriaFirstSpokenBy | KeywordCriteriaProximity | KeywordCriteriaTimeframe

export default class KeywordCriteria extends Model {
  protected fieldDefinition: ApiFieldDefinition = {
    [Api.CaService]: {
      keywordCriteriaSpotting: 'spotting',
      keywordCriteriaCombination: 'combination',
      keywordCriteriaType: 'type'
    }
  }

  private spotting = true
  private type = KeywordCriteriaType.FirstSpokenBy
  private combination = KeywordCriteriaCombination.And
  private config: KeywordCriteriaConfig | null = null

  getSpotting (): boolean {
    return this.spotting
  }

  setSpotting (value: boolean | number) {
    if (typeof value === 'number') {
      this.spotting = value === 1

      return this
    }

    this.spotting = value

    return this
  }

  getType (): KeywordCriteriaType {
    return this.type
  }

  setType (value: KeywordCriteriaType) {
    this.type = value

    return this
  }

  getCombination (): KeywordCriteriaCombination {
    return this.combination
  }

  setCombination (value: KeywordCriteriaCombination) {
    this.combination = value

    return this
  }

  getConfig (): KeywordCriteriaConfig | null {
    return this.config
  }

  setConfig (value: KeywordCriteriaConfig | null) {
    this.config = value

    return this
  }

  fromApiTransformer (data: JsonData, api: Api): KeywordCriteria {
    apiToModelTransformer(this, data, this.fieldDefinition[api])

    if (isObject(data.keywordCriteriaConfig) && !isArray(data.keywordCriteriaConfig)) {
      switch (data.keywordCriteriaType) {
        case KeywordCriteriaType.FirstSpokenBy:
          this.config = new KeywordCriteriaFirstSpokenBy()
            .fromApiTransformer(data.keywordCriteriaConfig, api)
          break
        case KeywordCriteriaType.Proximity:
          this.config = new KeywordCriteriaProximity()
            .fromApiTransformer(data.keywordCriteriaConfig, api)
          break
        case KeywordCriteriaType.Timeframe:
          this.config = new KeywordCriteriaTimeframe()
            .fromApiTransformer(data.keywordCriteriaConfig, api)
          break
      }
    }

    return this
  }

  toApiTransformer (api: Api): JsonData {
    const data = modelToApiTransformer(this, this.fieldDefinition[api])

    if (this.config !== null) {
      data.keywordCriteriaConfig = this.config.toApiTransformer(api)
    }

    data.keywordCriteriaSpotting = this.spotting ? 1 : 0

    return data
  }
}
