import { msToTimeString } from '@infinity/ca-tester/utils/time'
// eslint-disable-next-line import/no-unresolved
import { DirectiveBinding } from 'vue/types/options'

function parseElementMs (el: HTMLElement, binding: Partial<DirectiveBinding>) {
  let value = parseInt(binding.value)

  if (!value) {
    value = parseInt(el.innerText)
  }

  if (!isNaN(value)) {
    el.innerText = msToTimeString(value)
  }
}

/**
 * A directive for formatting milliseconds in a hh:mm:ss format.
 *
 * ## Usage:
 * ```html
 *  <span v-infinity-ms>5000</span>
 * ```
 * This method will not update if the content of the element changes. If you require a reactive formatter, use the method below.
 *
 * ## Usage with reactive properties:
 * ```html
 *  <span v-infinity-ms="msVariable">5000</span>
 * ```
 * Binding a variable will replace the content regardless of the text content of the element, but will update when the value changes.
 */
export default {
  inserted: function (el: HTMLElement, binding: Partial<DirectiveBinding>) {
    parseElementMs(el, binding)
  },
  update: function (el: HTMLElement, binding: Partial<DirectiveBinding>) {
    parseElementMs(el, binding)
  }
}
