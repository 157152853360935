var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showTranscript || _vm.keywordsOnly.length
    ? _c(
        "div",
        {
          class: {
            "d-flex": true,
            "flex-row-reverse": _vm.sentence.getLeg() === "operator",
            "align-items-center": true
          },
          attrs: { "data-index": _vm.index }
        },
        [
          _c(
            "div",
            {
              class: {
                operator: _vm.sentence.getLeg() === "operator",
                contact: _vm.sentence.getLeg() === "contact",
                sentence: true
              }
            },
            _vm._l(
              _vm.showTranscript ? _vm.sentence.getWords() : _vm.keywordsOnly,
              function(word, index) {
                var _obj
                return _c(
                  "div",
                  {
                    key: index,
                    class:
                      ((_obj = {
                        word: true,
                        spotted: word.getIsKeyword(),
                        subConfidence:
                          word.getIsKeyword() && !word.getMetConfidence(),
                        altLegSpotted: word.getConfigured(),
                        keywordOnly: !_vm.showTranscript,
                        compareConfig: !!_vm.getCompareKeyword(
                          _vm.compareConfigKeywords,
                          _vm.sentence,
                          word
                        ),
                        active: _vm.getIsActive(word),
                        editable: _vm.canEdit || _vm.canPlayReadOnly
                      }),
                      (_obj[_vm.getPhraseClass(word)] = !!_vm.getPhraseClass(
                        word
                      )),
                      _obj)
                  },
                  [
                    _c(
                      "span",
                      {
                        ref: "word",
                        refInFor: true,
                        on: {
                          mouseover: function($event) {
                            return _vm.setFocusedWord(word)
                          },
                          click: function($event) {
                            return _vm.doClickWord(word)
                          }
                        }
                      },
                      [
                        _vm._v(
                          _vm._s(word.getWord()) + _vm._s(word.getPunctuation())
                        )
                      ]
                    ),
                    _vm.focusedWord === word
                      ? _c(
                          "infinity-popover",
                          {
                            attrs: {
                              target: _vm.$refs.word[index],
                              "custom-class": "word-popover",
                              placement: "top",
                              triggers: "hover",
                              show: _vm.isEditing
                            },
                            on: {
                              disabled: function($event) {
                                _vm.popOverDisabled = true
                              },
                              enabled: function($event) {
                                _vm.popOverDisabled = false
                              }
                            }
                          },
                          [
                            _c(
                              "b-collapse",
                              { attrs: { visible: !_vm.isEditing } },
                              [
                                _vm.getKeywordForEdit(word)
                                  ? _c(
                                      "h6",
                                      {
                                        staticClass:
                                          "popover-group border-bottom py-1 px-3"
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.getKeywordGroupName(word))
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                _c(
                                  "b-list-group",
                                  { attrs: { horizontal: true } },
                                  [
                                    word.getIsKeyword()
                                      ? _c("b-list-group-item", [
                                          _c(
                                            "small",
                                            { staticClass: "d-block w-100" },
                                            [_vm._v("Score")]
                                          ),
                                          _c(
                                            "span",
                                            {
                                              class: {
                                                "text-danger":
                                                  word.getScore() < 0,
                                                "text-success":
                                                  word.getScore() > 0,
                                                "text-silver": !word.getMetConfidence()
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(word.getScore()) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ])
                                      : _vm._e(),
                                    _c("b-list-group-item", [
                                      _c(
                                        "small",
                                        { staticClass: "d-block w-100" },
                                        [_vm._v("Confidence")]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          class: {
                                            "text-danger":
                                              word.getIsKeyword() &&
                                              !word.getMetConfidence()
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(word.getConfidence()) +
                                              "% "
                                          )
                                        ]
                                      )
                                    ]),
                                    _c("b-list-group-item", [
                                      _c(
                                        "small",
                                        { staticClass: "d-block w-100" },
                                        [_vm._v("Time")]
                                      ),
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.getStartTime(word)) +
                                          " "
                                      )
                                    ])
                                  ],
                                  1
                                ),
                                _vm.canEdit
                                  ? _c(
                                      "div",
                                      { staticClass: "mt-3 text-center" },
                                      [
                                        _vm._v(
                                          " Click to " +
                                            _vm._s(
                                              _vm.getKeywordForEdit(word)
                                                ? "edit criteria"
                                                : "spot this word"
                                            ) +
                                            ". "
                                        )
                                      ]
                                    )
                                  : _vm.canPlayReadOnly
                                  ? _c(
                                      "div",
                                      { staticClass: "mt-3 text-center" },
                                      [
                                        _c("b-icon-play-fill"),
                                        _vm._v(" Click to play. ")
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                word.getConfigured() && !word.getIsKeyword()
                                  ? _c(
                                      "div",
                                      { staticClass: "mt-3 text-center" },
                                      [
                                        _vm._v(" This keyword is"),
                                        _c("br"),
                                        _vm._v(
                                          "configured for the " +
                                            _vm._s(
                                              _vm.sentence.getLeg() ===
                                                "operator"
                                                ? "Caller"
                                                : "Agent"
                                            ) +
                                            " "
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ],
                              1
                            ),
                            _c(
                              "b-collapse",
                              { attrs: { visible: _vm.isEditing } },
                              [
                                _vm.isEditing
                                  ? _c(
                                      "b-list-group",
                                      [
                                        _c("keyword-row", {
                                          attrs: {
                                            "ca-config": _vm.compareConfig,
                                            keyword: _vm.editingKeyword,
                                            "id-prefix": word.getWord(),
                                            "can-delete": false
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "mt-3 d-flex justify-content-center"
                                  },
                                  [
                                    _c(
                                      "b-button",
                                      {
                                        attrs: { variant: "outline" },
                                        on: { click: _vm.doCancel }
                                      },
                                      [_vm._v("Cancel")]
                                    ),
                                    _c(
                                      "b-button",
                                      {
                                        attrs: { variant: "outline-success" },
                                        on: { click: _vm.doSave }
                                      },
                                      [_vm._v("Done")]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ],
                  1
                )
              }
            ),
            0
          ),
          _c("span", [_vm._v(" " + _vm._s(_vm.sentenceStartTime) + " ")])
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }