



























import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import KeywordCriteriaComponent from '@infinity/ca-tester/components/KeywordCriteria.vue'
import KeywordCriteria, { KeywordCriteriaCombination } from '@infinity/ca-tester/models/keywordCriteria'
import { ListGroupPlugin } from 'bootstrap-vue'

Vue.use(ListGroupPlugin)

@Component({
  components: {
    KeywordCriteriaComponent
  }
})
export default class KeywordCriteriaGroup extends Vue {
  @Prop({ required: true })
  keywordCriteria!: KeywordCriteria[]

  @Prop({ default: false, type: Boolean })
  spotting!: boolean

  get spottingCombination (): string {
    if (this.keywordCriteria.length) {
      return this.keywordCriteria[0].getCombination()
    }

    return KeywordCriteriaCombination.And
  }

  onSpottingCombinationChange (value: KeywordCriteriaCombination) {
    for (const criteria of this.keywordCriteria) {
      criteria.setCombination(value)
    }
  }

  @Emit('criteriaSpottingChange')
  onCriteriaSpottingChange (criteria: KeywordCriteria) {
    return criteria
  }

  @Emit('delete')
  onDelete (criteria: KeywordCriteria) {
    return criteria
  }
}
