var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-list-group",
    { class: { deleted: _vm.isDeleted } },
    [
      _c(
        "b-list-group-item",
        {
          directives: [
            {
              name: "b-toggle",
              rawName: "v-b-toggle",
              value: _vm.isCreating
                ? null
                : "keywordGroup-" + _vm.keywordGroup.getId(),
              expression:
                "isCreating ? null : `keywordGroup-${keywordGroup.getId()}`"
            },
            {
              name: "test",
              rawName: "v-test",
              value: ["keywordGroupToggle", _vm.keywordGroup.getId()],
              expression: "['keywordGroupToggle', keywordGroup.getId()]"
            }
          ],
          class: {
            "keyword-group-row": true,
            rounded: true,
            "shadow-sm": true,
            deleted: _vm.isDeleted
          }
        },
        [
          !_vm.isCreating
            ? _c(
                "div",
                {
                  staticClass:
                    "d-flex align-items-center justify-content-between"
                },
                [
                  _c("div", [
                    _c(
                      "h5",
                      {
                        directives: [
                          {
                            name: "test",
                            rawName: "v-test",
                            value: "keywordGroupName",
                            expression: "'keywordGroupName'"
                          }
                        ],
                        staticClass: "mb-0"
                      },
                      [
                        _vm._v(" " + _vm._s(_vm.keywordGroup.getName()) + " "),
                        _c(
                          "b-button",
                          {
                            attrs: { size: "sm", variant: "outline" },
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                _vm.isCreating = true
                              }
                            }
                          },
                          [_c("b-icon-pencil")],
                          1
                        ),
                        _c(
                          "small",
                          [
                            _c("b-badge", { attrs: { pill: "" } }, [
                              _vm._v(
                                _vm._s(_vm.keywordGroup.getType().getName())
                              )
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "small",
                      {
                        directives: [
                          {
                            name: "test",
                            rawName: "v-test",
                            value: "keywordGroupDesc",
                            expression: "'keywordGroupDesc'"
                          }
                        ],
                        staticClass: "mb-0"
                      },
                      [_vm._v(_vm._s(_vm.keywordGroup.getDescription()))]
                    )
                  ]),
                  _c(
                    "div",
                    { staticClass: "d-flex align-items-center" },
                    [
                      _c(
                        "b-button",
                        {
                          directives: [
                            {
                              name: "test",
                              rawName: "v-test",
                              value: "addKeyword",
                              expression: "'addKeyword'"
                            }
                          ],
                          attrs: {
                            disabled:
                              _vm.isDeleted ||
                              _vm.$store.state.caConfig.isCreatingKeyword,
                            variant: "outline"
                          },
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              return _vm.addKeyword($event)
                            }
                          }
                        },
                        [_c("b-icon-plus"), _vm._v(" Add keyword or phrase")],
                        1
                      ),
                      _vm.isDeleted
                        ? _c(
                            "b-button",
                            {
                              directives: [
                                {
                                  name: "infinity-tooltip",
                                  rawName: "v-infinity-tooltip",
                                  value: "Restore group",
                                  expression: "'Restore group'"
                                }
                              ],
                              attrs: { variant: "outline" },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm.onRestore($event)
                                }
                              }
                            },
                            [_c("b-icon-arrow-counterclockwise")],
                            1
                          )
                        : _c(
                            "b-button",
                            {
                              directives: [
                                {
                                  name: "infinity-tooltip",
                                  rawName: "v-infinity-tooltip",
                                  value: "Delete group",
                                  expression: "'Delete group'"
                                }
                              ],
                              attrs: { variant: "outline" },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm.onDelete($event)
                                }
                              }
                            },
                            [_c("b-icon-trash")],
                            1
                          ),
                      _c(
                        "div",
                        { staticClass: "row-toggle py-2 ml-2" },
                        [_c("b-icon-chevron-right")],
                        1
                      )
                    ],
                    1
                  )
                ]
              )
            : _c("div", [
                !_vm.keywordGroup.getKeywordGroupTypeId()
                  ? _c(
                      "div",
                      { staticClass: "d-flex flex-wrap" },
                      [
                        _c("p", { staticClass: "w-100" }, [
                          _vm._v(
                            "What kind of keywords and phrases do you want to track? Pick one of our examples or choose your own:"
                          )
                        ]),
                        _vm._l(
                          _vm.$store.state.caConfig.keywordGroupTypes,
                          function(typeConfig) {
                            return _c(
                              "b-button",
                              {
                                directives: [
                                  {
                                    name: "test",
                                    rawName: "v-test",
                                    value: ["selectType", typeConfig.getId()],
                                    expression:
                                      "['selectType', typeConfig.getId()]"
                                  }
                                ],
                                key: typeConfig.getId(),
                                staticClass: "d-flex flex-column w-25 m-1",
                                attrs: { variant: "outline-whale" },
                                on: {
                                  click: function($event) {
                                    return _vm.selectType(typeConfig)
                                  }
                                }
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "w-100 text-center" },
                                  [_vm._v(_vm._s(typeConfig.getName()))]
                                ),
                                _c("small", [
                                  _vm._v(_vm._s(typeConfig.getDescription()))
                                ])
                              ]
                            )
                          }
                        )
                      ],
                      2
                    )
                  : _c(
                      "div",
                      [
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              description:
                                "Give your group a name that encompasses the phrases you're looking for.",
                              label: "Group name",
                              "label-for": "group-name",
                              "invalid-feedback":
                                "You already have a group with this name. Try something different.",
                              state: _vm.isGroupNameValid
                            }
                          },
                          [
                            _c("b-form-input", {
                              directives: [
                                {
                                  name: "test",
                                  rawName: "v-test",
                                  value: "addGroupName",
                                  expression: "'addGroupName'"
                                }
                              ],
                              attrs: {
                                id: "group-name",
                                value: _vm.keywordGroup.getName(),
                                state: _vm.isGroupNameValid,
                                trim: ""
                              },
                              on: {
                                input: function($event) {
                                  return _vm.keywordGroup.setName($event)
                                }
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              description: "Optional",
                              label: "Description",
                              "label-for": "group-desc"
                            }
                          },
                          [
                            _c("b-form-input", {
                              directives: [
                                {
                                  name: "test",
                                  rawName: "v-test",
                                  value: "addGroupDesc",
                                  expression: "'addGroupDesc'"
                                }
                              ],
                              attrs: {
                                id: "group-desc",
                                value: _vm.keywordGroup.getDescription(),
                                trim: ""
                              },
                              on: {
                                input: function($event) {
                                  return _vm.keywordGroup.setDescription($event)
                                }
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                _c(
                  "div",
                  { staticClass: "float-right" },
                  [
                    _vm.$store.state.caConfig.lastCompareGroupId > 0
                      ? _c(
                          "b-button",
                          {
                            attrs: { variant: "outline" },
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                return _vm.cancelNewGroup($event)
                              }
                            }
                          },
                          [_vm._v("Cancel")]
                        )
                      : _vm._e(),
                    _c(
                      "b-button",
                      {
                        directives: [
                          {
                            name: "test",
                            rawName: "v-test",
                            value: "saveKeywordGroup",
                            expression: "'saveKeywordGroup'"
                          }
                        ],
                        attrs: {
                          variant: "success",
                          disabled:
                            _vm.isGroupNameValid !== null &&
                            _vm.keywordGroup.getId() !== 0 &&
                            !_vm.isGroupNameValid
                        },
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.setNewId($event)
                          }
                        }
                      },
                      [_vm._v("Done")]
                    )
                  ],
                  1
                )
              ])
        ]
      ),
      _c(
        "b-collapse",
        {
          attrs: {
            visible: _vm.open,
            id: "keywordGroup-" + _vm.keywordGroup.getId()
          },
          on: {
            input: function($event) {
              _vm.isOpen = $event
            }
          }
        },
        [
          _vm._l(_vm.availableKeywords, function(keyword, index) {
            return _c("keyword-row", {
              directives: [
                {
                  name: "test",
                  rawName: "v-test",
                  value: ["keywordRow", keyword.getId()],
                  expression: "['keywordRow', keyword.getId()]"
                }
              ],
              key: _vm.keywordGroup.getId() + "-" + index,
              attrs: { "ca-config": _vm.caConfig, keyword: keyword },
              on: { save: _vm.onSaveKeyword }
            })
          }),
          _vm.availableKeywords.length === 0 &&
          _vm.$store.state.caConfig.lastCompareKeywordId > 0
            ? _c(
                "b-list-group-item",
                { staticClass: "text-center" },
                [
                  _c("b-icon-info-circle-fill"),
                  _vm._v(" No keywords or phrases in this group ")
                ],
                1
              )
            : _vm._e()
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }