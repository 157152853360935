import Model, { apiToModelTransformer } from '@infinity/shared/models/base'
import { Api, JsonData } from '@infinity/shared/helpers/api'

export class CallRecording extends Model {
  protected fieldDefinition = {
    [Api.Hub]: {
      callRecording: 'callRecording'
    }
  }

  private callRecording = ''

  getCallRecording (): string {
    return this.callRecording
  }

  setCallRecording (value: string) {
    this.callRecording = value
  }

  fromApiTransformer (data: JsonData, api: Api): CallRecording {
    apiToModelTransformer(this, data, this.fieldDefinition[api])

    return this
  }

  toApiTransformer (): JsonData | undefined {
    return undefined
  }
}
