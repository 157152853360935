import Repository, { DefaultLimit } from '@infinity/ca-tester/repos/base'
import { Api } from '@infinity/shared/helpers/api'
import { AuthUtil } from '@infinity/shared/utils/auth'
import { TriggerCall } from '@infinity/ca-tester/models/triggerCall'
import { FilterCollection } from '@infinity/ca-tester/helpers/filter'

export default class TriggerCallRepo extends Repository {
  constructor () {
    super(
      Api.Hub,
      `igrps/${AuthUtil.installationId}/triggers/calls`,
      TriggerCall,
      'callTriggers'
    )
  }

  async fetch (filterCollection: FilterCollection | null = null, limit = DefaultLimit, offset = 0): Promise<TriggerCall[]> {
    const data = await super.fetch(filterCollection, limit, offset)
    const models: TriggerCall[] = []

    for (const call of data) {
      if (call instanceof TriggerCall) {
        models.push(call)
      }
    }

    return models
  }

  async find (id: string | number): Promise<TriggerCall|null> {
    this.getter = 'callDetail'
    const model = await super.find(id)
    this.getter = 'callTriggers'

    if (model instanceof TriggerCall) {
      return model
    }

    return null
  }
}
