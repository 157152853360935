
























import { Component } from 'vue-property-decorator'
import CaLogo from '@infinity/ca-tester/components/layout/CaLogo.vue'
import InstallationPicker from '@infinity/ca-tester/components/InstallationPicker.vue'
import { AuthMethod, AuthUtil } from '@infinity/shared/utils/auth'
import { mixins } from 'vue-class-component'
import DropdownTargetsMixin from '@infinity/ca-tester/mixins/dropdown-targets'
import WebComponentAuthMixin from '@infinity/shared/mixins/web-component-auth'
import WebComponentRootMixin from '@infinity/shared/mixins/web-component-root'

@Component({
  components: {
    CaLogo,
    InstallationPicker
  }
})
export default class App extends mixins(DropdownTargetsMixin, WebComponentAuthMixin, WebComponentRootMixin) {
  async logout () {
    try {
      await AuthUtil.logout()
      this.$store.commit('setAuthenticated', AuthUtil.isAuthenticated)
      await this.$router.push('/login')
    } catch (e) {

    }
  }

  get installationId () {
    return AuthUtil.installationId
  }

  get showHeader () {
    return AuthUtil.authMethod !== AuthMethod.Params
  }
}
