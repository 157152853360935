






















































































































































import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import KeywordCriteria, { KeywordCriteriaType } from '@infinity/ca-tester/models/keywordCriteria'
import KeywordCriteriaProximity from '@infinity/ca-tester/models/keywordCriteriaProximity'
import KeywordCriteriaFirstSpokenBy from '@infinity/ca-tester/models/keywordCriteriaFirstSpokenBy'
import KeywordCriteriaTimeframe from '@infinity/ca-tester/models/keywordCriteriaTimeframe'
import { Api } from '@infinity/shared/helpers/api'
import { ButtonPlugin, FormInputPlugin, FormRadioPlugin, ListGroupPlugin } from 'bootstrap-vue'

Vue.use(ListGroupPlugin)
Vue.use(ButtonPlugin)
Vue.use(FormRadioPlugin)
Vue.use(FormInputPlugin)

@Component
export default class KeywordCriteriaComponent extends Vue {
  @Prop({ required: true })
  keywordCriteria!: KeywordCriteria

  keywordCriteriaCopy = new KeywordCriteria()

  proximityConfig = new KeywordCriteriaProximity()
  firstSpokenConfig = new KeywordCriteriaFirstSpokenBy()
  timeframeConfig = new KeywordCriteriaTimeframe()

  isEditing = false

  created () {
    this.initConfig()
  }

  initConfig () {
    this.keywordCriteriaCopy
      .fromApiTransformer(this.keywordCriteria.toApiTransformer(Api.CaService), Api.CaService)

    const config = this.keywordCriteria.getConfig()

    if (config === null) {
      this.isEditing = true

      return
    }

    if (config instanceof KeywordCriteriaProximity) {
      this.proximityConfig = new KeywordCriteriaProximity()
        .fromApiTransformer(config.toApiTransformer(Api.CaService), Api.CaService)
    }

    if (config instanceof KeywordCriteriaFirstSpokenBy) {
      this.firstSpokenConfig = new KeywordCriteriaFirstSpokenBy()
        .fromApiTransformer(config.toApiTransformer(Api.CaService), Api.CaService)
    }

    if (config instanceof KeywordCriteriaTimeframe) {
      this.timeframeConfig = new KeywordCriteriaTimeframe()
        .fromApiTransformer(config.toApiTransformer(Api.CaService), Api.CaService)
    }
  }

  get isAllConfigValid () {
    for (const key of Object.keys(this.isConfigValid)) {
      if (this.isConfigValid[key] === false) {
        return false
      }
    }

    return true
  }

  get isConfigValid (): { [k: string]: boolean } {
    switch (this.keywordCriteriaCopy.getType()) {
      case KeywordCriteriaType.Timeframe:
        return {
          duration: this.timeframeConfig.getDuration() > 0
        }

      case KeywordCriteriaType.Proximity:
        return {
          word: this.proximityConfig.getWord() !== '',
          withinSeconds: this.proximityConfig.getWithinSeconds() > 0
        }
    }

    return {}
  }

  @Emit('spottingChange')
  onSpottingChange (value: boolean) {
    this.keywordCriteriaCopy.setSpotting(value)

    return this.keywordCriteriaCopy
  }

  @Emit('save')
  doSave (): { old: KeywordCriteria; new: KeywordCriteria } {
    switch (this.keywordCriteriaCopy.getType()) {
      case KeywordCriteriaType.FirstSpokenBy:
        this.keywordCriteriaCopy.setConfig(this.firstSpokenConfig)
        break
      case KeywordCriteriaType.Proximity:
        this.keywordCriteriaCopy.setConfig(this.proximityConfig)
        break
      case KeywordCriteriaType.Timeframe:
        this.keywordCriteriaCopy.setConfig(this.timeframeConfig)
        break
    }

    this.keywordCriteria
      .fromApiTransformer(this.keywordCriteriaCopy.toApiTransformer(Api.CaService), Api.CaService)

    this.isEditing = false

    return { old: this.keywordCriteria, new: this.keywordCriteriaCopy }
  }

  @Emit('cancel')
  doCancel () {
    this.initConfig()

    this.isEditing = false

    return this.keywordCriteria
  }

  @Emit('delete')
  doDelete () {
    return this.keywordCriteria
  }
}
