import { Component, Vue } from 'vue-property-decorator'
import { BvEvent } from 'bootstrap-vue'

@Component
export default class DropdownTargetsMixin extends Vue {
  mounted () {
    // Overrides the dropdown target to work as a web component
    this.$root.$on('bv::dropdown::show', (bvEvent: BvEvent) => {
      // @ts-ignore
      if (typeof bvEvent.vueTarget.$refs.menu.getRootNode !== 'undefined') {
        // @ts-ignore
        bvEvent.vueTarget.focusInElement = bvEvent.vueTarget.$refs.menu.getRootNode()
      }
    })
  }
}
