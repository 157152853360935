var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "caLogo d-flex align-items-center" }, [
    _c(
      "svg",
      {
        staticClass: "mr-2",
        attrs: {
          "aria-hidden": "true",
          width: "64",
          height: "64",
          viewBox: "0 0 64 64"
        }
      },
      [
        _c("path", {
          staticClass: "color-infinity",
          attrs: {
            d:
              "M53.9 1H10.1C6.8 1.1 4.1 3.8 4.1 7.2V63l12.1-12.5h37.7c3.4-0.1 6.1-2.8 6.1-6.2V7.2C59.9 3.8 57.2 1.1 53.9 1zM23.6 39h-8.2V19.1h8.2V39zM36.1 39h-8.2V10.7h8.2V39zM48.6 39h-8.2V27.4h8.2V39z"
          }
        })
      ]
    ),
    _vm.title
      ? _c("h5", { staticClass: "mb-1" }, [_vm._v(_vm._s(_vm.title))])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }