import Model, { ApiFieldDefinition, apiToModelTransformer, modelToApiTransformer } from '@infinity/shared/models/base'
import { Api, InfinityStatus, JsonData } from '@infinity/shared/helpers/api'
import KeywordConfig from '@infinity/ca-tester/models/keywordConfig'
import KeywordGroupTypeConfig from '@infinity/ca-tester/models/keywordGroupTypeConfig'
import { find } from 'lodash'

export enum KeywordLeg { Contact = 'contact', Operator = 'operator', All = 'all' }

export default class KeywordGroupConfig extends Model {
  protected fieldDefinition: ApiFieldDefinition = {
    [Api.CaTestingService]: {
      keywordGroupId: 'id',
      keywordGroupName: 'name',
      keywordGroupStatus: 'status',
      keywordGroupDesc: 'description'
    },
    [Api.CaService]: {
      keywordGroupId: 'id',
      keywordGroupName: 'name',
      keywordGroupStatus: 'status',
      keywordGroupDesc: 'description'
    }
  }

  private id = 0
  private name = ''
  private description = ''
  private status = InfinityStatus.Ok
  private keywords: KeywordConfig[] = []
  private type = new KeywordGroupTypeConfig()
  private isNew = false

  setId (id: number) {
    this.id = id

    return this
  }

  getId () {
    return this.id
  }

  setName (name: string) {
    this.name = name

    return this
  }

  getName () {
    return this.name
  }

  setDescription (description: string) {
    this.description = description

    return this
  }

  getDescription () {
    return this.description
  }

  setStatus (status: InfinityStatus) {
    this.status = status

    return this
  }

  getStatus () {
    return this.status
  }

  setKeywords (keywords: KeywordConfig[]) {
    this.keywords = keywords

    return this
  }

  getKeywords () {
    return this.keywords
  }

  addKeyword (keyword: KeywordConfig) {
    keyword.setKeywordGroup(this)
    this.keywords.push(keyword)

    return this
  }

  removeKeyword (keyword: KeywordConfig) {
    const index = this.keywords.indexOf(keyword)

    if (index > -1) {
      this.keywords.splice(index, 1)
    }
  }

  getKeyword (id: number) {
    return find(this.keywords, { id: id })
  }

  setType (type: KeywordGroupTypeConfig) {
    this.type = type

    return this
  }

  getType () {
    return this.type
  }

  getKeywordGroupTypeId () {
    return this.type.getId()
  }

  setIsNew (isNew: boolean) {
    this.isNew = isNew

    return this
  }

  getIsNew () {
    return this.isNew
  }

  fromApiTransformer (data: JsonData, api: Api): KeywordGroupConfig {
    apiToModelTransformer(this, data, this.fieldDefinition[api])

    if (data.keywordGroupTypeId && typeof data.keywordGroupTypeId === 'number') {
      this.type.setId(data.keywordGroupTypeId)
    }

    return this
  }

  toApiTransformer (api: Api): JsonData {
    const data = modelToApiTransformer(this, this.fieldDefinition[api])
    data.keywordGroupTypeId = this.type.getId()

    return data
  }
}
