var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    [
      _c("phone-number-component", {
        attrs: {
          "phone-number": _vm.numberValue,
          country: _vm.country,
          "line-type": _vm.lineType,
          "show-mobile": _vm.lineType !== ""
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }