import Model, { apiToModelTransformer } from '@infinity/shared/models/base'
import { Api, JsonData } from '@infinity/shared/helpers/api'

export default class SentenceWord extends Model {
  protected fieldDefinition = {
    [Api.CaTestingService]: {
      s: 'startTime',
      e: 'EndTime',
      w: 'Word',
      pu: 'Punctuation',
      m: 'meta',
      kid: 'KeywordId',
      mc: 'MetConfidence',
      c: 'Confidence',
      kwc: 'Configured',
      kwgId: 'KeywordGroupId',
      kwsc: 'Score',
      pp: 'PhrasePart'
    },
    [Api.CaProviderVbService]: {
      s: 'startTime',
      e: 'EndTime',
      w: 'Word',
      pu: 'Punctuation',
      m: 'meta',
      kid: 'KeywordId',
      mc: 'MetConfidence',
      c: 'Confidence',
      kwc: 'Configured',
      kwgId: 'KeywordGroupId',
      kwsc: 'Score',
      pp: 'PhrasePart'
    }
  }

  private startTime = 0
  private endTime = 0
  private word = ''
  private punctuation = false
  private meta = ''
  private keywordId = 0
  private metConfidence = false
  private confidence = 0
  private keywordConfiguredOnAltLeg = false
  private keywordGroupId = 0
  private keywordScore = 0
  private phrasePart = ''

  getConfidence (): number {
    return this.confidence
  }

  setConfidence (value: number) {
    this.confidence = value

    return this
  }

  getStartTime (): number {
    return this.startTime
  }

  setStartTime (value: number) {
    this.startTime = value

    return this
  }

  getEndTime (): number {
    return this.endTime
  }

  setEndTime (value: number) {
    this.endTime = value

    return this
  }

  getWord (): string {
    return this.word
  }

  setWord (value: string) {
    this.word = value

    return this
  }

  getMeta (): string {
    return this.meta
  }

  setMeta (value: string) {
    this.meta = value

    return this
  }

  isPunctuated (): boolean {
    return this.punctuation
  }

  getPunctuation (): string {
    return this.isPunctuated() ? this.meta : ''
  }

  setPunctuation (value: boolean) {
    this.punctuation = value

    return this
  }

  getConfigured (): boolean {
    return this.keywordConfiguredOnAltLeg
  }

  setConfigured (value: boolean) {
    this.keywordConfiguredOnAltLeg = value

    return this
  }

  getKeywordGroupId () {
    return this.keywordGroupId
  }

  setKeywordGroupId (value: number) {
    this.keywordGroupId = value

    return this
  }

  getScore () {
    return this.keywordScore
  }

  setScore (value: number) {
    this.keywordScore = value

    return this
  }

  getKeywordId () {
    return this.keywordId
  }

  setKeywordId (value: number) {
    this.keywordId = value

    return this
  }

  getMetConfidence () {
    return this.metConfidence
  }

  setMetConfidence (value: boolean) {
    this.metConfidence = value

    return this
  }

  getIsKeyword () {
    return this.keywordId > 0
  }

  setPhrasePart (value: string) {
    this.phrasePart = value

    return this
  }

  getPhrasePart (): string {
    return this.phrasePart
  }

  fromApiTransformer (data: JsonData, api: Api): SentenceWord {
    apiToModelTransformer(this, data, this.fieldDefinition[api])

    return this
  }

  toApiTransformer (): JsonData | undefined {
    return undefined
  }
}
