import { formatTriggerDateTime } from '@infinity/ca-tester/utils/time'

export default {
  inserted: function (el: HTMLElement) {
    const innerText = el.innerText

    if (innerText) {
      el.innerText = formatTriggerDateTime(innerText)
    }
  }
}
