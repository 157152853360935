import Model, { apiToModelTransformer } from '@infinity/shared/models/base'
import { Api, JsonData } from '@infinity/shared/helpers/api'
import SentenceWord from '@infinity/ca-tester/models/sentenceWord'
import { KeywordLeg } from '@infinity/ca-tester/models/keywordConfig'
import { msToTimeString } from '@infinity/ca-tester/utils/time'
import { first, last } from 'lodash'

export enum SentenceSpeaker { None = '', Ivr = 'ivr' }

export class Sentence extends Model {
  protected fieldDefinition = {
    [Api.CaTestingService]: {
      et: 'EndTime',
      st: 'StartTime',
      l: 'Leg',
      spk: 'speaker'
    },
    [Api.CaProviderVbService]: {
      et: 'EndTime',
      st: 'StartTime',
      l: 'Leg',
      spk: 'speaker'
    }
  }

  private startTime = 0
  private endTime = 0
  private leg: KeywordLeg = KeywordLeg.Operator
  private words: SentenceWord[] = []
  private speaker = SentenceSpeaker.None

  getStartTime (): number {
    return this.startTime
  }

  setStartTime (value: number | string) {
    if (typeof value === 'string') {
      value = parseInt(value)
    }

    this.startTime = value

    return this
  }

  getEndTime (): number {
    return this.endTime
  }

  setEndTime (value: number | string) {
    if (typeof value === 'string') {
      value = parseInt(value)
    }

    this.endTime = value

    return this
  }

  getLeg (): KeywordLeg {
    return this.leg
  }

  setLeg (value: KeywordLeg) {
    this.leg = value

    return this
  }

  get Leg () {
    if (this.leg === KeywordLeg.Operator) {
      return 'Agent'
    }
    return 'Caller'
  }

  get Sentence (): string {
    const line = this.getWords().reduce((acc, word) => {
      return `${acc}${word.getWord()}${word.getPunctuation()} `
    }, '')

    return `${this.Leg.toUpperCase()} (${msToTimeString(this.getStartTime())} - ${msToTimeString(this.getEndTime())})\n${line}\n\n`
  }

  getWords (): SentenceWord[] {
    return this.words
  }

  getAltLeg (): KeywordLeg {
    if (this.leg === KeywordLeg.Operator) {
      return KeywordLeg.Contact
    }

    return KeywordLeg.Operator
  }

  setSpeaker (value: SentenceSpeaker) {
    this.speaker = value

    return this
  }

  getSpeaker () {
    return this.speaker
  }

  fromApiTransformer (data: JsonData, api: Api): Sentence {
    apiToModelTransformer(this, data, this.fieldDefinition[api])

    if (data.words && Array.isArray(data.words)) {
      for (const word of data.words) {
        this.words.push(
          new SentenceWord()
            .fromApiTransformer(word as JsonData, api)
        )
      }

      const firstWord = first(this.words)

      if (firstWord) {
        this.setStartTime(firstWord.getStartTime())
      }

      const lastWord = last(this.words)

      if (lastWord) {
        this.setEndTime(lastWord.getEndTime())
      }
    }

    return this
  }

  toApiTransformer (): JsonData | undefined {
    return undefined
  }
}
