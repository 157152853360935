import { TriggerCall } from '@infinity/ca-tester/models/triggerCall'
import TriggerCallRepo from '@infinity/ca-tester/repos/triggerCall'
import { Commit } from 'vuex'
import { Filter, FilterCollection, FilterOperand } from '@infinity/ca-tester/helpers/filter'
import { SortDirection } from '@infinity/ca-tester/repos/base'

export class CallState {
  currentPlaybackMs = 0
  isPlaying = false
  selectedCalls: TriggerCall[] = []
}

export default {
  namespaced: true,
  state: new CallState(),
  mutations: {
    setCurrentPlaybackMs (state: CallState, ms: number) {
      state.currentPlaybackMs = ms
    },
    resetCurrentPlaybackMs (state: CallState) {
      state.currentPlaybackMs = 0
    },
    setIsPlaying (state: CallState, isPlaying: boolean) {
      state.isPlaying = isPlaying
    },
    setSelectedCalls (state: CallState, calls: TriggerCall[]) {
      state.selectedCalls = calls
    },
    selectCall (state: CallState, call: TriggerCall) {
      state.selectedCalls.push(call)
    },
    removeCall (state: CallState, call: TriggerCall) {
      const index = state.selectedCalls.indexOf(call)
      if (index > -1) {
        state.selectedCalls.splice(index, 1)
      }
    }
  },
  actions: {
    async loadSelectedCalls ({ commit }: { commit: Commit }, ids: number[]) {
      if (ids.length) {
        const callRepo = new TriggerCallRepo()
        const calls = await callRepo
          .withParams({ tz: 'UTC' })
          .sortBy('txr', SortDirection.Desc)
          .fetch(new FilterCollection(
            new Filter('txr', FilterOperand.In, ids)
          ))

        commit('setSelectedCalls', calls)
      }
    }
  }
}
