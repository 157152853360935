


















































import { Component, Vue } from 'vue-property-decorator'
import { TriggerCall } from '@infinity/ca-tester/models/triggerCall'
import { SortDirection } from '@infinity/ca-tester/repos/base'
import { Filter, FilterCollection, FilterOperand } from '@infinity/ca-tester/helpers/filter'
import CallRow from '@infinity/ca-tester/components/log/CallRow.vue'
import { BvEvent } from 'bootstrap-vue'
import TriggerCallRepo from '@infinity/ca-tester/repos/triggerCall'
import { find, pull } from 'lodash'
import { loadEsModule, ComponentLibrary } from '@infinity/ca-tester/utils/web-components'
import { AuthUtil } from '@infinity/shared/utils/auth'

@Component({
  components: {
    CallRow
  }
})
export default class CallList extends Vue {
  // Assuming initial loading state
  isLoading = true
  private repo = new TriggerCallRepo()

  filters: Filter[] = []

  private currentPage: TriggerCall[] = []
  private sortBy = 'triggerDatetime'
  private sortDesc = true
  private limit = 15
  private page = 1
  private totalRows = 0
  private listAnimation = {
    name: 'fadeUpBig',
    enter: 'fadeInUpBig',
    leave: 'fadeOutDownBig'
  }

  get installationId () {
    return AuthUtil.installationId
  }

  get installationTimeZone () {
    return AuthUtil.installationTimeZone
  }

  get filterParams (): { [k: string]: string | (string | null)[] } {
    if (this.$route) {
      return this.$route.query
    }

    return {}
  }

  async created () {
    await this.fetchCalls()
  }

  applyFilters (event: CustomEvent) {
    const filters = event.detail[0]

    this.$router.push({ name: 'Calls', query: filters })
    this.$store.commit('setQueryParams', filters)

    this.fetchCalls()
  }

  async fetchCalls () {
    this.isLoading = true

    const { parseFiltersToApiParams } = await loadEsModule(ComponentLibrary.MfeShared, 'filter')

    const params = parseFiltersToApiParams ? parseFiltersToApiParams(this.filterParams) : {}
    params.tz = 'UTC'

    const items = await this.repo
      .withParams(params)
      .sortBy(this.sortBy, this.sortDesc ? SortDirection.Desc : SortDirection.Asc)
      .fetch(
        new FilterCollection(
          new Filter('callTranscription', FilterOperand.Equals, '1'),
          this.selectedCallsFilter,
          ...this.filters
        ),
        (this.limit + 1),
        ((this.page - 1) * this.limit)
      )

    this.totalRows = this.limit * this.page

    if (items.length > this.limit) {
      items.splice(this.limit, 1)
      this.totalRows += 1
    }

    this.currentPage = items
    this.isLoading = false
  }

  get selectedCallsFilter () {
    const ids = this.$store.state.calls.selectedCalls.map(
      (call: TriggerCall) => {
        return call.getRowId()
      }
    )

    if (ids.length) {
      return new Filter('rowId', FilterOperand.NotIn, ids)
    }

    return undefined
  }

  get calls () {
    return [...this.$store.state.calls.selectedCalls, ...this.currentPage]
  }

  onClickRow (item: TriggerCall) {
    const storeCall = find(this.$store.state.calls.selectedCalls, { rowId: item.getRowId() })

    if (storeCall) {
      this.$store.commit('calls/removeCall', storeCall)
      this.currentPage.unshift(storeCall)

      return
    }

    this.$store.commit('calls/selectCall', item)
    pull(this.currentPage, item)
  }

  async doChangePage (event: BvEvent, page: number) {
    this.page = page
    await this.fetchCalls()
  }
}
