var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-overlay",
    { attrs: { show: _vm.isLoading, rounded: "sm" } },
    [
      _vm.userHasPii
        ? [
            _c(
              "b-row",
              { staticClass: "mb-3 justify-content-between" },
              [
                _vm.showPlayer
                  ? _c(
                      "b-col",
                      { staticClass: "mb-3", attrs: { cols: "12" } },
                      [
                        _vm.call.hasCallRecording
                          ? _c("call-player", { attrs: { call: _vm.call } })
                          : _vm._e()
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "b-col",
                  {
                    staticClass:
                      "d-flex justify-content-between align-items-center"
                  },
                  [
                    _c(
                      "b-form-checkbox",
                      {
                        attrs: { switch: "" },
                        model: {
                          value: _vm.followTranscript,
                          callback: function($$v) {
                            _vm.followTranscript = $$v
                          },
                          expression: "followTranscript"
                        }
                      },
                      [_vm._v(" Follow transcription when playing call ")]
                    ),
                    _vm.$infinityAuth
                      .getProfile()
                      .hasPermission("callPlayback", 1)
                      ? _c(
                          "div",
                          [
                            this.config.getSentenceResults().hasSentences()
                              ? _c(
                                  "b-button",
                                  {
                                    directives: [
                                      {
                                        name: "infinity-tooltip",
                                        rawName: "v-infinity-tooltip",
                                        value: _vm.TranscriptTooltip,
                                        expression: "TranscriptTooltip"
                                      }
                                    ],
                                    staticClass: "m-3",
                                    attrs: { variant: _vm.TranscriptClass },
                                    on: { click: _vm.downloadTranscript }
                                  },
                                  [_c("b-icon-arrow-down-short")],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "b-button-group",
                              [
                                _c(
                                  "b-button",
                                  {
                                    directives: [
                                      {
                                        name: "infinity-tooltip",
                                        rawName: "v-infinity-tooltip",
                                        value: "Show full transcript",
                                        expression: "'Show full transcript'"
                                      }
                                    ],
                                    attrs: {
                                      variant: "outline-whale",
                                      pressed: _vm.showTranscript
                                    },
                                    on: {
                                      click: function($event) {
                                        _vm.showTranscript = true
                                      }
                                    }
                                  },
                                  [_c("b-icon-file-text")],
                                  1
                                ),
                                _c(
                                  "b-button",
                                  {
                                    directives: [
                                      {
                                        name: "infinity-tooltip",
                                        rawName: "v-infinity-tooltip",
                                        value: "Only show keywords",
                                        expression: "'Only show keywords'"
                                      }
                                    ],
                                    attrs: {
                                      variant: "outline-whale",
                                      pressed: !_vm.showTranscript
                                    },
                                    on: {
                                      click: function($event) {
                                        _vm.showTranscript = false
                                      }
                                    }
                                  },
                                  [_c("b-icon-chat-left-text")],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "b-row",
              [
                _vm.baseConfig
                  ? _c(
                      "b-col",
                      [
                        _c(
                          "b-row",
                          [
                            _c("b-col", [
                              _c(
                                "h5",
                                [
                                  _c("b-icon-person-fill"),
                                  _vm._v("Caller "),
                                  _c("b-badge", [
                                    _vm._v(
                                      "Score: " +
                                        _vm._s(
                                          _vm.baseConfig
                                            .getSpottingResults()
                                            .getTotalContactKwScore()
                                        )
                                    )
                                  ])
                                ],
                                1
                              )
                            ]),
                            _c("b-col", [
                              _c(
                                "h5",
                                { staticClass: "text-right" },
                                [
                                  _c("b-badge", [
                                    _vm._v(
                                      "Score: " +
                                        _vm._s(
                                          _vm.baseConfig
                                            .getSpottingResults()
                                            .getTotalOperatorKwScore()
                                        )
                                    )
                                  ]),
                                  _vm._v(" Agent "),
                                  _c("b-icon-headset")
                                ],
                                1
                              )
                            ])
                          ],
                          1
                        ),
                        _c("CallTranscription", {
                          attrs: {
                            "is-loading": _vm.isLoading,
                            results: _vm.baseConfig.getSpottingResults(),
                            sentences: _vm.baseConfig
                              .getSentenceResults()
                              .getSentences(),
                            "can-edit": _vm.canEdit,
                            "can-play-read-only": _vm.canPlayReadOnly,
                            "show-source": _vm.showSource,
                            "is-draft": false,
                            "is-saved": true
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "b-col",
                  [
                    _c(
                      "b-row",
                      [
                        _c("b-col", [
                          _c(
                            "h5",
                            [
                              _c("b-icon-person-fill"),
                              _vm._v("Caller "),
                              _c("b-badge", [
                                _vm._v(
                                  "Score: " +
                                    _vm._s(
                                      _vm.spottingResult.getTotalContactKwScore()
                                    )
                                )
                              ])
                            ],
                            1
                          )
                        ]),
                        _c("b-col", [
                          _c(
                            "h5",
                            { staticClass: "text-right" },
                            [
                              _c("b-badge", [
                                _vm._v(
                                  "Score: " +
                                    _vm._s(
                                      _vm.spottingResult.getTotalOperatorKwScore()
                                    )
                                )
                              ]),
                              _vm._v(" Agent "),
                              _c("b-icon-headset")
                            ],
                            1
                          )
                        ])
                      ],
                      1
                    ),
                    _vm.call.getTranscriptionFileName() !== ""
                      ? _c("CallTranscription", {
                          attrs: {
                            "can-edit": _vm.canEdit && !_vm.baseConfig,
                            "can-play-read-only": _vm.canPlayReadOnly,
                            "is-loading": _vm.isLoading,
                            results: _vm.config.getSpottingResults(),
                            sentences: _vm.config
                              .getSentenceResults()
                              .getSentences(),
                            "show-source": _vm.showSource,
                            "is-draft": _vm.isDraft || !!_vm.baseConfig,
                            "is-saved": !_vm.baseConfig
                          }
                        })
                      : _vm._e()
                  ],
                  1
                )
              ],
              1
            )
          ]
        : [
            _c(
              "b-alert",
              {
                directives: [
                  {
                    name: "test",
                    rawName: "v-test",
                    value: "cannot access transcription",
                    expression: "'cannot access transcription'"
                  }
                ],
                attrs: { show: "", variant: "light" }
              },
              [
                _c("b-icon-info-circle-fill"),
                _vm._v(
                  " Your user account does not have access to view call transcriptions. "
                )
              ],
              1
            )
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }