var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.$infinityAuth
    ? _c(
        "b-container",
        [
          _c(
            "b-row",
            { attrs: { "align-h": "center" } },
            [
              _c(
                "b-col",
                { attrs: { "align-self": "center", cols: "12" } },
                [
                  _c(
                    "b-row",
                    {
                      staticClass:
                        "my-3 d-flex justify-content-between align-items-center"
                    },
                    [
                      _c("b-col", [
                        _c("h2", [_vm._v("CA Tester")]),
                        _c("p", [
                          _vm._v(
                            "To get started, pick some calls below and click next when you're ready."
                          )
                        ])
                      ])
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { staticClass: "my-3" },
                    [
                      _c("b-col", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$store.state.calls.selectedCalls.length
                            ) +
                            " " +
                            _vm._s(
                              _vm.$store.state.calls.selectedCalls.length !== 1
                                ? "calls"
                                : "call"
                            ) +
                            " selected. "
                        )
                      ]),
                      _c(
                        "b-col",
                        { staticClass: "d-flex flex-row-reverse" },
                        [
                          _c(
                            "b-button",
                            {
                              attrs: {
                                disabled:
                                  _vm.$store.state.calls.selectedCalls
                                    .length === 0,
                                variant: "success"
                              },
                              on: { click: _vm.doNext }
                            },
                            [_vm._v(" Next ")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("call-list")
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }