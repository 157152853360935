import { AuthUtil } from '@infinity/shared/utils/auth'

export const msHour = 3600000

export enum MsTimeInterval { Year = 31536000000, Month = 2592000000, Day = 86400000, Hour = 3600000, Minute = 60000, Second = 1000 }
export enum RelativeTimeFormat { Year = 'year', Month = 'month', Day = 'day', Hour = 'hour', Minute = 'minute', Second = 'second' }

const formatter = new Intl.DateTimeFormat(window.navigator.language, {
  year: 'numeric',
  month: 'short',
  day: 'numeric',
  hour: '2-digit',
  minute: 'numeric',
  second: 'numeric',
  timeZone: AuthUtil.installationTimeZone || 'UTC'
})

const relativeFormatter = new Intl.RelativeTimeFormat(window.navigator.language, { numeric: 'auto' })

export function msToTimeString (ms: number) {
  if (ms >= msHour) {
    return new Date(ms).toISOString().slice(11, -5)
  }

  return new Date(ms).toISOString().slice(14, -5)
}

export function triggerDateTimeToDate (datetime: string): Date {
  const dateParts = datetime.split(' ')
  const dateISOString = `${dateParts[0]}T${dateParts[1]}Z`

  return new Date(dateISOString)
}

export function formatDate (date: Date): string {
  return formatter.format(date)
}

export function formatTriggerDateTime (datetime: string): string {
  return formatDate(triggerDateTimeToDate(datetime))
}

export function formatTimeAgo (date: Date): string | null {
  const diff = (Number(new Date()) - Number(date))

  let interval = diff / MsTimeInterval.Year
  if (interval >= 1) {
    return relativeFormatter.format(-Math.floor(interval), RelativeTimeFormat.Year)
  }

  interval = diff / MsTimeInterval.Month
  if (interval >= 1) {
    return relativeFormatter.format(-Math.floor(interval), RelativeTimeFormat.Month)
  }

  interval = diff / MsTimeInterval.Day
  if (interval >= 1) {
    return relativeFormatter.format(-Math.floor(interval), RelativeTimeFormat.Day)
  }

  interval = diff / MsTimeInterval.Hour
  if (interval >= 1) {
    return relativeFormatter.format(-Math.floor(interval), RelativeTimeFormat.Hour)
  }

  interval = diff / MsTimeInterval.Minute
  if (interval >= 1) {
    return relativeFormatter.format(-Math.floor(interval), RelativeTimeFormat.Minute)
  }

  interval = diff / MsTimeInterval.Day
  if (interval >= 1) {
    return relativeFormatter.format(-Math.floor(interval), RelativeTimeFormat.Day)
  }

  return null
}

export function formatTriggerTimeAgo (datetime: string): string | null {
  return formatTimeAgo(triggerDateTimeToDate(datetime))
}
