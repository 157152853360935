import Model, {
  ApiFieldDefinition,
  apiToModelTransformer,
  modelToApiTransformer
} from '@infinity/shared/models/base'
import { Api, JsonData } from '@infinity/shared/helpers/api'

export default class Installation extends Model {
  // TODO: Use Data Dictionary
  protected fieldDefinition: ApiFieldDefinition = {
    [Api.Hub]: {
      igrp: 'id',
      igrpName: 'name',
      tz: 'timeZone'
    }
  }

  private id = ''
  private name = ''
  private timeZone = ''

  getId (): string {
    return this.id
  }

  setId (id: string): Installation {
    this.id = id

    return this
  }

  getName (): string {
    return this.name
  }

  setName (name: string): Installation {
    this.name = name

    return this
  }

  getTimeZone (): string {
    return this.timeZone
  }

  setTimeZone (value: string) {
    this.timeZone = value
  }

  fromApiTransformer (data: JsonData, api: Api): Installation {
    apiToModelTransformer(this, data, this.fieldDefinition[api])

    return this
  }

  toApiTransformer (api: Api): JsonData {
    return modelToApiTransformer(this, this.fieldDefinition[api])
  }
}
