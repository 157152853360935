var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    [
      _vm.countryCode
        ? _c("FlagIcon", { attrs: { "country-code": _vm.countryCode } })
        : _vm._e(),
      _vm._v(" " + _vm._s(_vm.formattedNumber) + " "),
      _vm.showMobile && _vm.isMobile
        ? _c("b-icon-phone", {
            directives: [
              {
                name: "infinity-tooltip",
                rawName: "v-infinity-tooltip",
                value: "This call was made from a mobile.",
                expression: "'This call was made from a mobile.'"
              },
              {
                name: "test",
                rawName: "v-test",
                value: "mobile icon",
                expression: "'mobile icon'"
              }
            ]
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }