



































































































































































































































import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import { ListGroupPlugin, DropdownPlugin, FormGroupPlugin, FormInputPlugin, ButtonPlugin } from 'bootstrap-vue'
import KeywordConfig, { KeywordLeg } from '@infinity/ca-tester/models/keywordConfig'
import { InfinityStatus } from '@infinity/shared/helpers/api'
import CaConfig from '@infinity/ca-tester/models/caConfig'
import KeywordGroupConfig from '@infinity/ca-tester/models/keywordGroupConfig'
import { PopoverEvent } from '@infinity/ca-tester/components/layout/InfinityPopover.vue'
import KeywordCriteriaGroup from '@infinity/ca-tester/components/KeywordCriteriaGroup.vue'
import KeywordCriteria from '@infinity/ca-tester/models/keywordCriteria'
import KeywordCriteriaComponent from '@infinity/ca-tester/components/KeywordCriteria.vue'
import { track } from '@infinity/ca-tester/utils/mixpanel'

export enum WalkThroughStep { Phrase, Party, Confidence, Score }

Vue.use(ListGroupPlugin)
Vue.use(DropdownPlugin)
Vue.use(FormGroupPlugin)
Vue.use(FormInputPlugin)
Vue.use(ButtonPlugin)

@Component({
  components: {
    KeywordCriteriaComponent,
    KeywordCriteriaGroup
  }
})
export default class KeywordRow extends Vue {
  @Prop({ required: true })
  private caConfig!: CaConfig

  @Prop({ required: true })
  private keyword!: KeywordConfig

  @Prop({ default: 'keyword' })
  private idPrefix!: string

  @Prop({ default: true, type: Boolean })
  showAdvanced!: boolean

  private walkthroughStep: WalkThroughStep | null = null

  private groupQuery = ''
  private showCriteria = false
  private newCriteria: KeywordCriteria | null = null

  mounted () {
    this.$nextTick(
      () => {
        this.$forceUpdate()

        if (this.keyword.getId() === 0) {
          const newId = this.$store.state.caConfig.lastCompareKeywordId + 1
          this.$store.commit('caConfig/setLastCompareKeywordId', newId)
          // flag for adding new keyword to keyword group while keywords are being created.
          this.$store.commit('caConfig/setIsCreatingKeyword', true)
          this.keyword.setId(newId)
          this.keyword.setIsNew(true)

          this.walkthroughStep = WalkThroughStep.Phrase
        }
      }
    )
  }

  updateStep (step: WalkThroughStep | null) {
    if (this.walkthroughStep !== null) {
      this.walkthroughStep = step
    }

    // Init as last step
    let previousStep = WalkThroughStep.Score

    if (step !== null && step > 0) {
      previousStep = step - 1
    }

    this.$root.$emit(PopoverEvent.Hide, `${this.popoverTargetPrefix}-${previousStep}`)

    if (step === null) {
      // reset flag for adding new keyword to keyword group.
      this.$store.commit('caConfig/setIsCreatingKeyword', false)
      this.$emit('save', this.keyword)
    }
  }

  get popoverTargetPrefix () {
    return `${this.idPrefix}-${this.keyword.getId()}`
  }

  get keywordGroups () {
    const groups = []

    for (const config of this.caConfig.getKeywordSpottingConfig()) {
      if (config.getKeywordGroup().getStatus() === InfinityStatus.Ok) {
        if (this.groupQuery !== '' && config.getKeywordGroup().getName().toLowerCase().includes(this.groupQuery) === false) {
          continue
        }

        groups.push(config.getKeywordGroup())
      }
    }

    return groups
  }

  get openPhrase () {
    return this.walkthroughStep === WalkThroughStep.Phrase
  }

  get openParty () {
    return this.walkthroughStep === WalkThroughStep.Party
  }

  get openConfidence () {
    return this.walkthroughStep === WalkThroughStep.Confidence
  }

  get openScore () {
    return this.walkthroughStep === WalkThroughStep.Score
  }

  get keywordReadOnly () {
    return (this.keyword.getId() !== 0) && (this.keyword.getId() <= this.$store.state.caConfig.lastBaseKeywordId)
  }

  get isDeleted () {
    return this.keyword.getStatus() === InfinityStatus.Deleted
  }

  get isGroupDeleted () {
    return this.keyword.getKeywordGroup().getStatus() === InfinityStatus.Deleted
  }

  get isKeywordValid () {
    for (const keywordConfig of this.keyword.getKeywordGroup().getKeywords()) {
      if (
        keywordConfig.getStatus() !== 410 &&
        keywordConfig.getId() !== this.keyword.getId() &&
        (keywordConfig.getPhrase().toLowerCase() === this.keyword.getPhrase().toLowerCase())
      ) {
        return false
      }
    }

    return true
  }

  selectGroup (group: KeywordGroupConfig) {
    this.keyword.setKeywordGroup(group)
  }

  doSetPhrase (value: string) {
    this.keyword.setPhrase(value)
  }

  formatConfidenceScore (val: string) {
    const num = parseInt(val)
    if (num > 0) {
      return String(val).substring(0, 2)
    }
  }

  doSetMinConfidence (value: string) {
    const numberValue = parseInt(value)

    if (isNaN(numberValue) === false) {
      if (numberValue > 90) {
        this.keyword.setMinConfidence(90)
      } else if (numberValue < 10) {
        this.keyword.setMinConfidence(10)
      } else {
        this.keyword.setMinConfidence(numberValue)
      }
    }
  }

  doSelectLeg (value: KeywordLeg) {
    this.keyword.setLeg(value)
  }

  formatKeywordScore (val: string) {
    const num = parseInt(val)
    if (num === 0) {
      return String(num)
    }
    if (num < 0) {
      return String(val).substring(0, 4)
    }
    if (num > 0) {
      return String(val).substring(0, 3)
    }
  }

  doSetScore (value: string) {
    const numberValue = parseInt(value)
    if (!isNaN(numberValue)) {
      if (numberValue > 100) {
        this.keyword.setScore(100)
      } else if (numberValue < -100) {
        this.keyword.setScore(-100)
      } else {
        this.keyword.setScore(numberValue)
      }
    }
  }

  keywordFormatter (value: string) {
    return value.toLowerCase()
  }

  get legOptions () {
    return [
      { value: KeywordLeg.All, text: 'Either' },
      { value: KeywordLeg.Contact, text: 'Caller' },
      { value: KeywordLeg.Operator, text: 'Agent' }
    ]
  }

  get spottingCriteria () {
    return this.keyword.getKeywordCriteria().filter(
      (keywordCriteria) => {
        return keywordCriteria.getSpotting()
      }
    )
  }

  get nonSpottingCriteria () {
    return this.keyword.getKeywordCriteria().filter(
      (keywordCriteria) => {
        return keywordCriteria.getSpotting() === false
      }
    )
  }

  doAddCriteria () {
    this.newCriteria = new KeywordCriteria()
    this.showCriteria = true
  }

  onCancelCriteria (criteria: KeywordCriteria) {
    if (criteria === this.newCriteria) {
      this.newCriteria = null

      if (this.keyword.getKeywordCriteria().length === 0) {
        this.showCriteria = false
      }
    }
  }

  onSaveCriteria ({ old, new: newCriteria }: { old: KeywordCriteria; new: KeywordCriteria }) {
    if (old === this.newCriteria) {
      if (newCriteria.getSpotting() && this.spottingCriteria.length) {
        newCriteria.setCombination(this.spottingCriteria[0].getCombination())
      }

      if (!newCriteria.getSpotting() && this.nonSpottingCriteria.length) {
        newCriteria.setCombination(this.nonSpottingCriteria[0].getCombination())
      }

      this.keyword.addKeywordCriteria(newCriteria)
      this.newCriteria = null
    }
  }

  onDeleteCriteria (criteria: KeywordCriteria) {
    this.keyword.removeKeywordCriteria(criteria)
  }

  onCriteriaSpottingChange (criteria: KeywordCriteria) {
    if (criteria.getSpotting() && this.spottingCriteria.length) {
      criteria.setCombination(this.spottingCriteria[0].getCombination())
    }

    if (!criteria.getSpotting() && this.nonSpottingCriteria.length) {
      criteria.setCombination(this.nonSpottingCriteria[0].getCombination())
    }
  }

  @Emit()
  onRestore () {
    for (const keyword of this.keyword.getKeywordGroup().getKeywords()) {
      if (
        keyword.getId() !== this.keyword.getId() &&
        keyword.getPhrase().toLowerCase() === this.keyword.getPhrase().toLowerCase() &&
        keyword.getStatus() === InfinityStatus.Ok
      ) {
        this.$bvToast.toast(`${keyword.getPhrase()} already exists in the group`, {
          title: 'Cannot restore keyword',
          autoHideDelay: 5000,
          variant: 'warning',
          solid: true,
          toaster: 'b-toaster-top-center'
        })

        return
      }
    }
    this.keyword.setStatus(InfinityStatus.Ok)

    return this.keyword
  }

  @Emit()
  async onDelete () {
    if (this.$store.state.caConfig.isCreatingKeyword) {
      this.$store.commit('caConfig/setIsCreatingKeyword', false)
    }

    if (this.keyword.getIsNew()) {
      const confirmed = window.confirm('Unsaved keywords will be removed from the group. Do you want to continue?')

      if (confirmed) {
        this.keyword.getKeywordGroup().removeKeyword(this.keyword)

        track('settings.conversationAnalytics.tester.keyword.remove', {
          name: this.keyword.getPhrase(),
          group: this.keyword.getKeywordGroup().getName()
        })
      }

      return
    }

    this.keyword.setStatus(InfinityStatus.Deleted)

    track('settings.conversationAnalytics.tester.keyword.remove', {
      name: this.keyword.getPhrase(),
      group: this.keyword.getKeywordGroup().getName()
    })

    return this.keyword
  }
}
