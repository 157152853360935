import Model, { ApiFieldDefinition, apiToModelTransformer } from '@infinity/shared/models/base'
import { Api, JsonData } from '@infinity/shared/helpers/api'

export default class KeywordGroupTypeConfig extends Model {
  protected fieldDefinition: ApiFieldDefinition = {
    [Api.Hub]: {
      keywordGroupTypeId: 'id',
      typeName: 'name',
      description: 'description'
    }
  }

  private id = 0
  private name = ''
  private description = ''

  setId (id: number | string) {
    if (typeof id === 'string') {
      id = parseInt(id)

      if (isNaN(id) === false) {
        this.id = id
      }

      return this
    }

    this.id = id

    return this
  }

  getId () {
    return this.id
  }

  setName (name: string) {
    this.name = name

    return this
  }

  getName () {
    return this.name
  }

  setDescription (description: string) {
    this.description = description

    return this
  }

  getDescription () {
    return this.description
  }

  fromApiTransformer (data: JsonData, api: Api): KeywordGroupTypeConfig {
    apiToModelTransformer(this, data, this.fieldDefinition[api])

    return this
  }

  toApiTransformer (): undefined {
    return undefined
  }
}
