var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.keyword.getKeywordGroup().getName()
    ? _c(
        "b-list-group-item",
        [
          _c(
            "b-dropdown",
            {
              attrs: {
                text: "Please select a group for this keyword",
                variant: "outline-whale"
              }
            },
            [
              _c(
                "b-dropdown-form",
                [
                  _c("b-input", {
                    attrs: {
                      debounce: "250",
                      placeholder: "Find by group name..."
                    },
                    model: {
                      value: _vm.groupQuery,
                      callback: function($$v) {
                        _vm.groupQuery = $$v
                      },
                      expression: "groupQuery"
                    }
                  })
                ],
                1
              ),
              _c("b-dropdown-divider"),
              _vm._l(_vm.keywordGroups, function(group) {
                return _c(
                  "b-dropdown-item",
                  {
                    key: group.getId(),
                    on: {
                      click: function($event) {
                        return _vm.selectGroup(group)
                      }
                    }
                  },
                  [_vm._v(" " + _vm._s(group.getName()) + " ")]
                )
              })
            ],
            2
          )
        ],
        1
      )
    : _c(
        "b-list-group-item",
        {
          class: {
            "d-flex": true,
            "flex-wrap": true,
            "keyword-row": true,
            "px-2": true,
            deleted: _vm.isDeleted
          }
        },
        [
          !_vm.keywordReadOnly
            ? _c(
                "b-button",
                {
                  ref: "phrase",
                  class: {
                    "text-left": true,
                    "text-danger": _vm.keyword.getPhrase() === ""
                  },
                  attrs: { variant: "outline", disabled: _vm.isDeleted }
                },
                [
                  _vm.keyword.getPhrase()
                    ? [_vm._v(" " + _vm._s(_vm.keyword.getPhrase()) + " ")]
                    : [_vm._v("Set keyword")],
                  _c("b-icon-chevron-down", { staticClass: "ml-2" })
                ],
                2
              )
            : _vm._e(),
          !_vm.keywordReadOnly
            ? _c("infinity-popover", {
                attrs: { show: _vm.openPhrase, target: _vm.$refs.phrase },
                on: {
                  shown: function($event) {
                    return _vm.$refs.keywordInput.focus()
                  }
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(ref) {
                        var popover = ref.popover
                        return [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                description:
                                  "We will look for this in the transcription and report any matches.",
                                "invalid-feedback":
                                  "You cannot use a keyword or phrase that's already in this group. Try using a different term or group.",
                                state: _vm.isKeywordValid,
                                label: "Keyword",
                                "label-for":
                                  _vm.idPrefix + "-" + _vm.keyword.getId()
                              },
                              nativeOn: {
                                keydown: function($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  ) {
                                    return null
                                  }
                                  $event.stopPropagation()
                                  $event.preventDefault()
                                  return _vm.updateStep(1)
                                }
                              }
                            },
                            [
                              _c("b-form-input", {
                                ref: "keywordInput",
                                attrs: {
                                  id: _vm.idPrefix + "-" + _vm.keyword.getId(),
                                  value: _vm.keyword.getPhrase(),
                                  formatter: _vm.keywordFormatter,
                                  trim: ""
                                },
                                on: { input: _vm.doSetPhrase }
                              })
                            ],
                            1
                          ),
                          _c(
                            "b-button",
                            {
                              attrs: {
                                disabled:
                                  !_vm.isKeywordValid ||
                                  !_vm.keyword.getPhrase(),
                                variant: "success",
                                block: ""
                              },
                              on: {
                                click: function($event) {
                                  _vm.updateStep(1)
                                  popover.hide()
                                }
                              }
                            },
                            [_vm._v("Done")]
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  3718856490
                )
              })
            : _c("div", { staticClass: "px-3 py-2" }, [
                _vm._v(" " + _vm._s(_vm.keyword.getPhrase()) + " ")
              ]),
          _c(
            "b-button",
            {
              ref: "party",
              staticClass: "ml-auto",
              attrs: {
                disabled: _vm.isDeleted,
                "toggle-class": "text-left",
                variant: "outline"
              }
            },
            [
              _vm.keyword.getLeg() === "all"
                ? [_c("b-icon-people-fill"), _vm._v(" either ")]
                : [
                    _vm.keyword.getLeg() === "contact"
                      ? [_c("b-icon-person-fill"), _vm._v(" caller ")]
                      : [_c("b-icon-headset"), _vm._v(" agent ")]
                  ],
              _c("b-icon-chevron-down", { staticClass: "ml-1" })
            ],
            2
          ),
          _c("infinity-popover", {
            attrs: {
              target: _vm.$refs.party,
              show: _vm.openParty,
              placement: "bottom"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var popover = ref.popover
                  return [
                    _c(
                      "b-form-group",
                      {
                        attrs: {
                          label: "Party",
                          description:
                            "You may choose to only spot this keyword if it was spoken by a particular party in the call."
                        }
                      },
                      [
                        _c("b-form-radio-group", {
                          staticClass: "d-flex",
                          attrs: {
                            id: "leg-" + _vm.keyword.getId(),
                            checked: _vm.keyword.getLeg(),
                            options: _vm.legOptions,
                            "button-variant": "outline-whale",
                            buttons: "",
                            name: "leg-" + _vm.keyword.getId()
                          },
                          on: { change: _vm.doSelectLeg }
                        })
                      ],
                      1
                    ),
                    _c(
                      "b-button",
                      {
                        attrs: { variant: "success", block: "" },
                        on: {
                          click: function($event) {
                            _vm.updateStep(2)
                            popover.hide()
                          }
                        }
                      },
                      [_vm._v("Done")]
                    )
                  ]
                }
              }
            ])
          }),
          _c(
            "b-button",
            {
              ref: "confidence",
              attrs: { disabled: _vm.isDeleted, variant: "outline" }
            },
            [
              _vm._v(" confidence at least "),
              _c("strong", [
                _vm._v(_vm._s(_vm.keyword.getMinConfidence()) + "%")
              ]),
              _vm._v(" "),
              _c("b-icon-chevron-down", { staticClass: "ml-1" })
            ],
            1
          ),
          _c("infinity-popover", {
            attrs: {
              placement: "bottom",
              show: _vm.openConfidence,
              target: _vm.$refs.confidence
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var popover = ref.popover
                  return [
                    _c(
                      "b-form-group",
                      {
                        attrs: {
                          id: "minConfidence-" + _vm.keyword.getId(),
                          description:
                            "How confident, should we be before we consider a keyword as a match? (min 10, max 90)",
                          label: "Minimum Confidence",
                          "label-for": "minConfidence"
                        }
                      },
                      [
                        _c("b-form-input", {
                          attrs: {
                            id: "minConfidence-" + _vm.keyword.getId(),
                            type: "range",
                            min: "10",
                            max: "90",
                            value: _vm.keyword.getMinConfidence(),
                            trim: ""
                          },
                          on: { input: _vm.doSetMinConfidence }
                        }),
                        _c("b-form-input", {
                          attrs: {
                            id: "minConfidenceNumber-" + _vm.keyword.getId(),
                            type: "number",
                            min: "10",
                            max: "90",
                            value: _vm.keyword.getMinConfidence(),
                            formatter: _vm.formatConfidenceScore,
                            trim: ""
                          },
                          on: { input: _vm.doSetMinConfidence },
                          nativeOn: {
                            keydown: function($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              ) {
                                return null
                              }
                              $event.stopPropagation()
                              $event.preventDefault()
                              return _vm.updateStep(3)
                            }
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "b-button",
                      {
                        attrs: { variant: "success", block: "" },
                        on: {
                          click: function($event) {
                            _vm.updateStep(3)
                            popover.hide()
                          }
                        }
                      },
                      [_vm._v("Done")]
                    )
                  ]
                }
              }
            ])
          }),
          _c(
            "b-button",
            {
              ref: "score",
              staticClass: "score",
              attrs: {
                disabled: _vm.isDeleted,
                variant: _vm.keyword.isScorePositive
                  ? "success"
                  : _vm.keyword.isScoreNegative
                  ? "danger"
                  : "primary"
              }
            },
            [
              _vm._v(" " + _vm._s(_vm.keyword.getScore()) + " "),
              _c("b-icon-chevron-down", { staticClass: "ml-1" })
            ],
            1
          ),
          _c("infinity-popover", {
            attrs: {
              target: _vm.$refs.score,
              placement: "bottom",
              show: _vm.openScore
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var popover = ref.popover
                  return [
                    _c(
                      "b-form-group",
                      {
                        attrs: {
                          id: "score-" + _vm.keyword.getId(),
                          description:
                            "A score that should be attributed to this keyword when matched.",
                          label: "Score",
                          "label-for": "score"
                        }
                      },
                      [
                        _c("b-form-input", {
                          attrs: {
                            id: "score-" + _vm.keyword.getId(),
                            type: "range",
                            min: "-100",
                            max: "100",
                            value: _vm.keyword.getScore(),
                            trim: ""
                          },
                          on: { input: _vm.doSetScore }
                        }),
                        _c("b-form-input", {
                          attrs: {
                            id: "scoreNumber=" + _vm.keyword.getId(),
                            type: "number",
                            min: "-100",
                            max: "100",
                            value: _vm.keyword.getScore(),
                            formatter: _vm.formatKeywordScore,
                            trim: ""
                          },
                          on: { input: _vm.doSetScore },
                          nativeOn: {
                            keydown: function($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              ) {
                                return null
                              }
                              $event.stopPropagation()
                              $event.preventDefault()
                              _vm.updateStep(null)
                              popover.hide()
                            }
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "b-button",
                      {
                        attrs: { variant: "success", block: "" },
                        on: {
                          click: function($event) {
                            _vm.updateStep(null)
                            popover.hide()
                          }
                        }
                      },
                      [_vm._v("Done")]
                    )
                  ]
                }
              }
            ])
          }),
          _vm.showAdvanced
            ? _c(
                "span",
                { staticClass: "mx-3" },
                [
                  _c(
                    "b-button",
                    {
                      directives: [
                        {
                          name: "infinity-tooltip",
                          rawName: "v-infinity-tooltip",
                          value: _vm.showCriteria
                            ? "Hide criteria"
                            : "Show criteria",
                          expression:
                            "showCriteria ? 'Hide criteria' : 'Show criteria'"
                        }
                      ],
                      class: {
                        "criteria-toggle": true,
                        "show-criteria": _vm.showCriteria,
                        "text-whale": _vm.showCriteria
                      },
                      attrs: {
                        variant: "outline",
                        disabled: !_vm.keyword.getKeywordCriteria().length
                      },
                      on: {
                        click: function($event) {
                          _vm.showCriteria = !_vm.showCriteria
                        }
                      }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.keyword.getKeywordCriteria().length) +
                          " additional criteria "
                      ),
                      _c("b-icon-chevron-right", {
                        staticClass: "show-criteria-icon"
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-button",
                    {
                      directives: [
                        {
                          name: "infinity-tooltip",
                          rawName: "v-infinity-tooltip",
                          value: "Add criteria",
                          expression: "'Add criteria'"
                        }
                      ],
                      attrs: { variant: "outline" },
                      on: { click: _vm.doAddCriteria }
                    },
                    [_c("b-icon-plus")],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          !_vm.isDeleted
            ? _c(
                "b-button",
                {
                  directives: [
                    {
                      name: "infinity-tooltip",
                      rawName: "v-infinity-tooltip",
                      value: "Delete Keyword",
                      expression: "'Delete Keyword'"
                    }
                  ],
                  attrs: { variant: "outline" },
                  on: { click: _vm.onDelete }
                },
                [_c("b-icon-trash")],
                1
              )
            : _c(
                "b-button",
                {
                  directives: [
                    {
                      name: "infinity-tooltip",
                      rawName: "v-infinity-tooltip",
                      value: "Restore Keyword",
                      expression: "'Restore Keyword'"
                    }
                  ],
                  attrs: { disabled: _vm.isGroupDeleted, variant: "outline" },
                  on: { click: _vm.onRestore }
                },
                [_c("b-icon-arrow-counterclockwise")],
                1
              ),
          _vm.showAdvanced
            ? _c(
                "b-collapse",
                {
                  staticClass: "w-100 mt-3",
                  attrs: {
                    accordion: "advanced-criteria",
                    visible: _vm.showCriteria
                  },
                  on: {
                    hidden: function($event) {
                      _vm.showCriteria = false
                      _vm.newCriteria = null
                    }
                  }
                },
                [
                  _vm.newCriteria
                    ? _c(
                        "keyword-criteria-component",
                        {
                          staticClass: "rounded-lg mb-3",
                          attrs: { "keyword-criteria": _vm.newCriteria },
                          on: {
                            save: _vm.onSaveCriteria,
                            cancel: _vm.onCancelCriteria
                          }
                        },
                        [_c("h5", [_vm._v("Add criteria")])]
                      )
                    : _vm._e(),
                  _vm.spottingCriteria.length
                    ? _c("keyword-criteria-group", {
                        attrs: {
                          "keyword-criteria": _vm.spottingCriteria,
                          spotting: true
                        },
                        on: {
                          delete: _vm.onDeleteCriteria,
                          criteriaSpottingChange: _vm.onCriteriaSpottingChange
                        }
                      })
                    : _vm._e(),
                  _vm.nonSpottingCriteria.length
                    ? _c("keyword-criteria-group", {
                        staticClass: "mt-3",
                        attrs: {
                          "keyword-criteria": _vm.nonSpottingCriteria,
                          spotting: false
                        },
                        on: {
                          delete: _vm.onDeleteCriteria,
                          criteriaSpottingChange: _vm.onCriteriaSpottingChange
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }