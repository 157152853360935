var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "rounded-lg shadow-sm bg-light border" },
    [
      _vm.showSource
        ? [
            _vm.isDraft
              ? _c(
                  "div",
                  { staticClass: "w-100 rounded-top p-2 text-light bg-whale" },
                  [
                    _vm.isSaved
                      ? _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "infinity-tooltip",
                                rawName: "v-infinity-tooltip",
                                value:
                                  "These results are based on your saved draft",
                                expression:
                                  "'These results are based on your saved draft'"
                              }
                            ]
                          },
                          [
                            _c("b-icon-circle-fill", { staticClass: "mr-2" }),
                            _vm._v(" Saved draft")
                          ],
                          1
                        )
                      : _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "infinity-tooltip",
                                rawName: "v-infinity-tooltip",
                                value:
                                  "These results are based on the changes you have made",
                                expression:
                                  "'These results are based on the changes you have made'"
                              }
                            ]
                          },
                          [
                            _c("b-icon-circle-half", { staticClass: "mr-2" }),
                            _vm._v(" Working copy")
                          ],
                          1
                        )
                  ]
                )
              : _vm.isSaved
              ? _c(
                  "div",
                  {
                    staticClass: "w-100 rounded-top p-2 text-light bg-success"
                  },
                  [
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "infinity-tooltip",
                            rawName: "v-infinity-tooltip",
                            value:
                              "These results are based on your saved settings",
                            expression:
                              "'These results are based on your saved settings'"
                          }
                        ]
                      },
                      [
                        _c("b-icon-check-circle-fill", { staticClass: "mr-2" }),
                        _vm._v(" Live settings")
                      ],
                      1
                    )
                  ]
                )
              : _vm._e()
          ]
        : _vm._e(),
      _c(
        "div",
        {
          ref: "keywordContainer",
          staticClass: "keyword-container",
          on: { scroll: _vm.onScroll }
        },
        [
          _c(
            "b-skeleton-wrapper",
            {
              attrs: { loading: _vm.isLoading },
              scopedSlots: _vm._u([
                {
                  key: "loading",
                  fn: function() {
                    return [
                      _c(
                        "b-card",
                        [
                          _c("b-skeleton", { attrs: { width: "85%" } }),
                          _c("b-skeleton", { attrs: { width: "55%" } }),
                          _c("b-skeleton", { attrs: { width: "70%" } })
                        ],
                        1
                      )
                    ]
                  },
                  proxy: true
                }
              ])
            },
            [
              !_vm.isLoading
                ? [
                    _vm.sentences && _vm.showTranscript
                      ? [
                          _vm.ivrStartTime && _vm.ivrEndTime
                            ? [
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "test",
                                        rawName: "v-test",
                                        value: "ivr card",
                                        expression: "'ivr card'"
                                      }
                                    ],
                                    staticClass:
                                      "w-100 my-3 bg-white border rounded-lg py-2 px-3 d-flex align-items-center flex-wrap"
                                  },
                                  [
                                    _c(
                                      "h4",
                                      { staticClass: "mb-0 mr-3" },
                                      [_c("b-icon-diagram3-fill")],
                                      1
                                    ),
                                    _c("h6", { staticClass: "mb-0" }, [
                                      _vm._v(" IVR / Welcome Message "),
                                      _c("br"),
                                      _c(
                                        "small",
                                        { staticClass: "text-secondary" },
                                        [
                                          _c(
                                            "span",
                                            {
                                              directives: [
                                                {
                                                  name: "infinity-ms",
                                                  rawName: "v-infinity-ms",
                                                  value: _vm.ivrStartTime,
                                                  expression: "ivrStartTime"
                                                },
                                                {
                                                  name: "test",
                                                  rawName: "v-test",
                                                  value: "ivrStartTime",
                                                  expression: "'ivrStartTime'"
                                                }
                                              ]
                                            },
                                            [_vm._v(_vm._s(_vm.ivrStartTime))]
                                          ),
                                          _vm._v(" - "),
                                          _c(
                                            "span",
                                            {
                                              directives: [
                                                {
                                                  name: "infinity-ms",
                                                  rawName: "v-infinity-ms",
                                                  value: _vm.ivrEndTime,
                                                  expression: "ivrEndTime"
                                                },
                                                {
                                                  name: "test",
                                                  rawName: "v-test",
                                                  value: "ivrEndTime",
                                                  expression: "'ivrEndTime'"
                                                }
                                              ]
                                            },
                                            [_vm._v(_vm._s(_vm.ivrEndTime))]
                                          )
                                        ]
                                      )
                                    ]),
                                    _c(
                                      "b-checkbox",
                                      {
                                        directives: [
                                          {
                                            name: "test",
                                            rawName: "v-test",
                                            value: "show ivr toggle",
                                            expression: "'show ivr toggle'"
                                          }
                                        ],
                                        staticClass: "ml-auto",
                                        attrs: { switch: "" },
                                        model: {
                                          value: _vm.showIvr,
                                          callback: function($$v) {
                                            _vm.showIvr = $$v
                                          },
                                          expression: "showIvr"
                                        }
                                      },
                                      [_vm._v(" Show ")]
                                    ),
                                    _c(
                                      "b-collapse",
                                      {
                                        staticClass: "w-100 mt-1",
                                        attrs: { visible: _vm.showIvr }
                                      },
                                      _vm._l(_vm.ivrSentences, function(
                                        sentence,
                                        index
                                      ) {
                                        return _c("sentence-component", {
                                          directives: [
                                            {
                                              name: "test",
                                              rawName: "v-test",
                                              value:
                                                "ivr sentence " +
                                                sentence.getLeg() +
                                                " " +
                                                sentence.getStartTime(),
                                              expression:
                                                "`ivr sentence ${sentence.getLeg()} ${sentence.getStartTime()}`"
                                            }
                                          ],
                                          key: index,
                                          staticClass: "my-3",
                                          attrs: {
                                            "can-edit": false,
                                            "can-play-read-only":
                                              _vm.canPlayReadOnly,
                                            index: index,
                                            observer: _vm.observer,
                                            sentence: sentence,
                                            results: _vm.results,
                                            isLive: !_vm.isDraft && _vm.isSaved
                                          }
                                        })
                                      }),
                                      1
                                    )
                                  ],
                                  1
                                )
                              ]
                            : _vm._e(),
                          _vm._l(_vm.visibleSentences, function(
                            sentence,
                            index
                          ) {
                            return _c("sentence-component", {
                              directives: [
                                {
                                  name: "test",
                                  rawName: "v-test",
                                  value: "visible sentences",
                                  expression: "`visible sentences`"
                                }
                              ],
                              key: index,
                              staticClass: "my-3",
                              attrs: {
                                "can-edit": _vm.canEdit,
                                "can-play-read-only": _vm.canPlayReadOnly,
                                index: index,
                                observer: _vm.observer,
                                sentence: sentence,
                                results: _vm.results,
                                isLive: !_vm.isDraft && _vm.isSaved
                              },
                              on: { "new-keyword": _vm.newKeyword }
                            })
                          })
                        ]
                      : _vm.sentences
                      ? _vm._l(_vm.sortedLoadedKeywordSentences, function(
                          sentence,
                          index
                        ) {
                          return _c("sentence-component", {
                            directives: [
                              {
                                name: "test",
                                rawName: "v-test",
                                value: "visible keyword sentences",
                                expression: "`visible keyword sentences`"
                              }
                            ],
                            key: "keywords-" + index,
                            staticClass: "my-3",
                            attrs: {
                              "can-edit": _vm.canEdit,
                              "can-play-read-only": _vm.canPlayReadOnly,
                              index: index,
                              observer: _vm.observer,
                              sentence: sentence,
                              results: _vm.results,
                              isLive: !_vm.isDraft && _vm.isSaved
                            },
                            on: { "new-keyword": _vm.newKeyword }
                          })
                        })
                      : _vm._e()
                  ]
                : _vm._e()
            ],
            2
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }