class TranscriptionState {
  followTranscript = true
  showTranscript = true
  scrollTop = 0
}

export default {
  namespaced: true,
  state: new TranscriptionState(),
  mutations: {
    setFollowTranscript (state: TranscriptionState, followTranscript: boolean) {
      state.followTranscript = followTranscript
    },
    setShowTranscript (state: TranscriptionState, showTranscript: boolean) {
      state.showTranscript = showTranscript
    },
    setScrollTop (state: TranscriptionState, scrollTop: number) {
      state.scrollTop = scrollTop
    }
  }
}
