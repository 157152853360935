import Model, { ApiFieldDefinition, apiToModelTransformer } from '@infinity/shared/models/base'
import { Api, JsonData } from '@infinity/shared/helpers/api'
import SpottedKeyword from '@infinity/ca-tester/models/spottedKeyword'

export class SpottingResult extends Model {
  protected fieldDefinition = {
    [Api.CaTestingService]: {
      TotalConfidence: 'TotalConfidence',
      TotalKwScore: 'TotalKwScore',
      TotalOperatorKwScore: 'TotalOperatorKwScore',
      TotalContactKwScore: 'TotalContactKwScore'
    },
    [Api.CaProviderVbService]: {
      totalConfidence: 'TotalConfidence',
      totalKeywordScore: 'TotalKwScore',
      totalOperatorKeywordScore: 'TotalOperatorKwScore',
      totalContactKeywordScore: 'TotalContactKwScore'
    }
  } as ApiFieldDefinition;

  private totalConfidence = 0
  private totalKwScore = 0
  private totalOperatorKwScore = 0
  private totalContactKwScore = 0
  private operatorKeywords: SpottedKeyword[] = []
  private contactKeywords: SpottedKeyword[] = []

  setTotalConfidence (val: number) {
    this.totalConfidence = val
  }

  getTotalConfidence () {
    return this.totalConfidence
  }

  setTotalKwScore (val: number) {
    this.totalKwScore = val
  }

  getTotalKwScore () {
    return this.totalKwScore
  }

  setTotalOperatorKwScore (val: number) {
    this.totalOperatorKwScore = val
  }

  getTotalOperatorKwScore () {
    return this.totalOperatorKwScore
  }

  setTotalContactKwScore (val: number) {
    this.totalContactKwScore = val
  }

  getTotalContactKwScore () {
    return this.totalContactKwScore
  }

  getOperatorKeywords () {
    return this.operatorKeywords
  }

  getContactKeywords () {
    return this.contactKeywords
  }

  getResults () {
    return [
      {
        totalConfidence: this.getTotalConfidence(),
        totalKwScore: this.getTotalKwScore(),
        totalOperatorKwScore: this.getTotalOperatorKwScore(),
        totalContactKwScore: this.getTotalContactKwScore()
      }
    ]
  }

  fromApiTransformer (data: JsonData, api: Api): SpottingResult {
    apiToModelTransformer(this, data, this.fieldDefinition[api])

    if (data.OperatorKeywords && Array.isArray(data.OperatorKeywords)) {
      this._loadKeywords(this.operatorKeywords, data.OperatorKeywords as JsonData[], api)
    }

    if (data.ContactKeywords && Array.isArray(data.ContactKeywords)) {
      this._loadKeywords(this.contactKeywords, data.ContactKeywords as JsonData[], api)
    }

    if (data.operatorKw && Array.isArray(data.operatorKw)) {
      this._loadKeywords(this.operatorKeywords, data.operatorKw as JsonData[], api)
    }

    if (data.contactKw && Array.isArray(data.contactKw)) {
      this._loadKeywords(this.contactKeywords, data.contactKw as JsonData[], api)
    }

    return this
  }

  toApiTransformer (): JsonData | undefined {
    return undefined
  }

  private _loadKeywords (list: SpottedKeyword[], keywords: JsonData[], api: Api) {
    for (const keyword of keywords) {
      list.push(
        new SpottedKeyword()
          .fromApiTransformer(keyword, api)
      )
    }
  }
}
