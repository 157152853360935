import Model, { ApiFieldDefinition, apiToModelTransformer } from '@infinity/shared/models/base'
import { Api, JsonData } from '@infinity/shared/helpers/api'
import KeywordSpottingConfig from '@infinity/ca-tester/models/keywordSpottingConfig'

export default class DraftCaConfig extends Model {
  private keywordSpottingConfig: KeywordSpottingConfig[] = []
  protected fieldDefinition: ApiFieldDefinition = {
    [Api.CaTestingService]: {
      id: 'id',
      name: 'name'
    }
  }

  getKeywordSpottingConfig (): KeywordSpottingConfig[] {
    return this.keywordSpottingConfig
  }

  setKeywordSpottingConfig (config: KeywordSpottingConfig[]) {
    this.keywordSpottingConfig = config

    return this
  }

  fromApiTransformer (data: JsonData, api: Api): DraftCaConfig {
    apiToModelTransformer(this, data, this.fieldDefinition[api])
    if (data.keywordGroups && Array.isArray(data.keywordGroups)) {
      for (const keywordGroup of data.keywordGroups) {
        this.keywordSpottingConfig.push(
          new KeywordSpottingConfig()
            .fromApiTransformer(keywordGroup as JsonData, api)
        )
      }
    }
    return this
  }

  toApiTransformer (api: Api): JsonData | undefined {
    const data: { keywordGroups: JsonData[] } = {
      keywordGroups: []
    }

    for (const config of this.keywordSpottingConfig) {
      data.keywordGroups.push(config.toApiTransformer(api))
    }

    return data
  }
}
