





































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import SpottedKeyword from '@infinity/ca-tester/models/spottedKeyword'
import { KeywordLeg } from '@infinity/ca-tester/models/keywordConfig'
import CaConfig from '@infinity/ca-tester/models/caConfig'
import Repository from '@infinity/ca-tester/repos/base'
import { Api, JsonData } from '@infinity/shared/helpers/api'
import CallTranscription from '@infinity/ca-tester/components/CallTranscription.vue'
import CallPlayer from '@infinity/ca-tester/components/CallPlayer.vue'
import { TriggerCall } from '@infinity/ca-tester/models/triggerCall'
import TestConfig from '@infinity/ca-tester/models/testConfig'
import { AuthUtil } from '@infinity/shared/utils/auth'
import { formatTriggerDateTime } from '@/utils/time'
import { AlertPlugin, BadgePlugin, CardPlugin, FormCheckboxPlugin, OverlayPlugin, SkeletonPlugin } from 'bootstrap-vue'
import { PermissionMask } from '@infinity/shared/models/profile'

Vue.use(OverlayPlugin)
Vue.use(SkeletonPlugin)
Vue.use(CardPlugin)
Vue.use(FormCheckboxPlugin)
Vue.use(BadgePlugin)
Vue.use(AlertPlugin)

@Component({
  components: {
    CallTranscription,
    CallPlayer
  }
})
export default class ResultPanel extends Vue {
  @Prop({ required: true })
  config!: CaConfig

  @Prop({ required: true })
  call!: TriggerCall

  @Prop({ type: Boolean, default: false })
  canReRun!: boolean

  @Prop({ type: Object, default: null })
  baseConfig!: CaConfig | null

  @Prop({ default: false, type: Boolean })
  isDraft!: boolean

  @Prop({ default: true, type: Boolean })
  showPlayer!: boolean

  @Prop({ default: true, type: Boolean })
  showSource!: boolean

  @Prop({ default: true, type: Boolean })
  canEdit!: boolean

  @Prop({ default: true, type: Boolean })
  canPlayReadOnly!: boolean

  private isLoading = true
  private transcriptionError = false

  private testRepo = new Repository(
    Api.CaTestingService,
    'keywords/spotting',
    CaConfig,
    'data'
  )

  private transcriptionRepo = new Repository(
    Api.Hub,
    `igrps/${AuthUtil.installationId}/call-transcription`,
    TriggerCall
  )

  async created () {
    if (this.userHasPii) {
      try {
        const transcription = await this.transcriptionRepo.find(this.call.getRowId())

        if (transcription && transcription.getCallTranscription()) {
          const url = new URL(transcription.getCallTranscription())
          this.call.setTranscriptionFileName(url.pathname.slice(1))
        }
      } catch (e) {
        this.transcriptionError = true
        this.isLoading = false

        return
      }

      try {
        if (this.baseConfig) {
          await this.doRunTest(this.baseConfig)
        }

        await this.doRunTest(this.config)
      } catch (e) {
        this.transcriptionError = true
      }

      if (this.$infinityAuth.getProfile().hasPermission('callPlayback', 1) === false) {
        this.showTranscript = false
      }
    }

    this.isLoading = false
  }

  async doRunTest (config: CaConfig) {
    if (this.call.getTranscriptionFileName()) {
      const result = await this.testRepo.create(
        new TestConfig()
          .setCall(this.call)
          .setChecks([config])
      )

      if (result && Array.isArray(result.data)) {
        config
          .fromApiTransformer(result.data[0] as JsonData, Api.CaTestingService)
      }
    }
  }

  get userHasPii () {
    return this.$infinityAuth.getProfile().hasPermission('piiData', PermissionMask.View)
  }

  get showTranscript () {
    return this.$store.state.transcription.showTranscript
  }

  set showTranscript (value: boolean) {
    this.$store.commit('transcription/setShowTranscript', value)
  }

  get followTranscript () {
    return this.$store.state.transcription.followTranscript
  }

  set followTranscript (value: boolean) {
    this.$store.commit('transcription/setFollowTranscript', value)
  }

  get nameId () {
    if (this.config.getId()) {
      return `name-${this.config.getId()}`
    }

    if (this.baseConfig) {
      return 'name-comparison'
    }

    return 'name-new'
  }

  get spottingResult () {
    return this.config.getSpottingResults()
  }

  get subConfidenceKeywords () {
    return this.combinedKeywords.filter(
      ({ keyword }: { keyword: SpottedKeyword; leg: KeywordLeg }) => {
        return !keyword.getMetConfidenceLevel()
      }
    )
  }

  get combinedKeywords () {
    const combinedKeywords: { keyword: SpottedKeyword; leg: KeywordLeg }[] = []

    for (const keyword of this.spottingResult.getContactKeywords()) {
      combinedKeywords.push({
        keyword,
        leg: KeywordLeg.Contact
      })
    }

    for (const keyword of this.spottingResult.getOperatorKeywords()) {
      combinedKeywords.push({
        keyword,
        leg: KeywordLeg.Operator
      })
    }

    return combinedKeywords.sort(
      (a, b) => {
        if (a.keyword.getStartTime() > b.keyword.getStartTime()) {
          return 1
        }

        if (a.keyword.getStartTime() < b.keyword.getStartTime()) {
          return -1
        }

        return 0
      }
    )
  }

  get CallTranscriptFileName (): string {
    let filename = this.call.getTranscriptionFileName()
    filename = filename.replaceAll(/installation\/(\d+)\//g, this.call.getCallRef() + '_')
    filename = filename.replaceAll(/_(\d+)/g, '')
    filename = filename.replaceAll('/', '_')
    filename = filename.replaceAll('.json', '.txt')

    return filename
  }

  get TranscriptTooltip (): string {
    if (this.call.canDownloadTranscript) {
      return 'Download Transcript'
    }
    return 'Transcript downloading is disabled for this call'
  }

  get TranscriptClass (): string {
    if (this.call.canDownloadTranscript) {
      return 'outline-whale'
    }
    return 'outline-disabled'
  }

  downloadTranscript () {
    if (this.config.getSentenceResults().hasSentences() && this.call.canDownloadTranscript) {
      const transcript = [
        '** Call Details **\n',
        'Date: ' + formatTriggerDateTime(this.call.getTriggerDatetime()) + '\n',
        'Reference: ' + this.call.getCallRef() + '\n',
        'Duration: ' + this.call.getBridgeDuration() + ' seconds\n\n',
        '** Transcription **\n\n'
      ]

      transcript.push(...this.config.getSentenceResults().Transcript)
      const blob = new Blob(transcript, { type: 'text/plain;charset=utf-8' })
      const blobUrl = URL.createObjectURL(blob)
      const anchor = document.createElement('a')
      anchor.href = blobUrl
      anchor.target = '_blank'
      anchor.download = this.CallTranscriptFileName
      anchor.click()
      URL.revokeObjectURL(blobUrl)
    }
  }
}
