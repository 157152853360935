import Repository, { DefaultLimit } from '@infinity/ca-tester/repos/base'
import { Api } from '@infinity/shared/helpers/api'
import { AuthUtil } from '@infinity/shared/utils/auth'
import { FilterCollection } from '@infinity/ca-tester/helpers/filter'
import KeywordGroupTypeConfig from '@infinity/ca-tester/models/keywordGroupTypeConfig'

export default class KeywordGroupTypeRepo extends Repository {
  constructor () {
    super(
      Api.Hub,
      `config/igrps/${AuthUtil.installationId}/keyword-groups/types`,
      KeywordGroupTypeConfig,
      'keywordGroupTypes'
    )
  }

  async fetch (filterCollection: FilterCollection | null = null, limit: number = DefaultLimit, offset = 0): Promise<KeywordGroupTypeConfig[]> {
    const data = await super.fetch(filterCollection, limit, offset)
    const models = []

    for (const item of data) {
      if (item instanceof KeywordGroupTypeConfig) {
        models.push(item)
      }
    }

    return models
  }
}
