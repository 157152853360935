











































































import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import { BBadge, ListGroupPlugin, FormGroupPlugin, FormInputPlugin, ButtonPlugin, BIconPencil } from 'bootstrap-vue'
import KeywordGroupConfig from '@infinity/ca-tester/models/keywordGroupConfig'
import KeywordRow from '@infinity/ca-tester/components/KeywordRow.vue'
import { InfinityStatus } from '@infinity/shared/helpers/api'
import KeywordConfig from '@infinity/ca-tester/models/keywordConfig'
import KeywordGroupTypeConfig from '@infinity/ca-tester/models/keywordGroupTypeConfig'
import CaConfig from '@infinity/ca-tester/models/caConfig'
import { track } from '@infinity/ca-tester/utils/mixpanel'

Vue.use(ListGroupPlugin)
Vue.use(FormGroupPlugin)
Vue.use(FormInputPlugin)
Vue.use(ButtonPlugin)

@Component({
  components: {
    KeywordRow,
    BBadge,
    BIconPencil
  }
})
export default class KeywordGroupRow extends Vue {
  @Prop({ required: true })
  private caConfig!: CaConfig

  @Prop({ required: true })
  private keywordGroup!: KeywordGroupConfig

  @Prop({ default: false })
  private showDeleted!: boolean

  @Prop({ default: false })
  private open!: boolean

  @Prop({ default: '' })
  query!: string

  oldKeywordName = ''
  isCreating = false

  private isOpen = false

  get isDeleted () {
    return this.keywordGroup.getStatus() === InfinityStatus.Deleted
  }

  created () {
    if (this.keywordGroup.getId() === 0) {
      this.isCreating = true
    }

    this.oldKeywordName = this.keywordGroup.getName()
  }

  @Emit('delete')
  onDelete () {
    // Resets flag if we're creating a new keyword then delete the keyword group.
    if (this.$store.state.caConfig.isCreatingKeyword) {
      this.$store.commit('caConfig/setIsCreatingKeyword', false)
    }

    let confirmed = true

    if (this.keywordGroup.getIsNew()) {
      confirmed = window.confirm('Unsaved groups will be permanently deleted, along with all their keywords. Do you want to continue?')

      if (confirmed) {
        this.caConfig.removeKeywordGroup(this.keywordGroup)

        track('settings.conversationAnalytics.tester.keywordGroup.remove', {
          name: this.keywordGroup.getName()
        })
      }

      return
    }

    if (this.keywordGroup.getKeywords().length) {
      confirmed = window.confirm('Deleting this group will also delete all the keywords within it. Are you sure you want to do this?')
    }

    if (confirmed) {
      this.keywordGroup.setStatus(InfinityStatus.Deleted)

      for (const keyword of this.keywordGroup.getKeywords()) {
        keyword.setStatus(InfinityStatus.Deleted)
      }

      track('settings.conversationAnalytics.tester.keywordGroup.remove', {
        name: this.keywordGroup.getName()
      })
    }
  }

  onSaveKeyword (keyword: KeywordConfig) {
    track(keyword.getIsNew() ? 'keyword.add' : 'keyword.edit', {
      keyword: keyword.getPhrase(),
      group: keyword.getKeywordGroup().getName(),
      leg: keyword.getLeg(),
      minConfidence: keyword.getMinConfidence(),
      score: keyword.getScore()
    })
  }

  onRestore () {
    this.keywordGroup.setStatus(InfinityStatus.Ok)
  }

  addKeyword () {
    this.keywordGroup.getKeywords().push(new KeywordConfig().setKeywordGroup(this.keywordGroup))

    if (this.isOpen === false) {
      this.$root.$emit('bv::toggle::collapse', `keywordGroup-${this.keywordGroup.getId()}`)
    }
  }

  selectType (type: KeywordGroupTypeConfig) {
    this.keywordGroup.setType(type)
      .setName(type.getName())
  }

  setNewId () {
    if (this.isCreating) {
      if (this.keywordGroup.getIsNew()) {
        this.keywordGroup.setId(this.$store.state.caConfig.lastCompareGroupId + 1)
        this.keywordGroup.setIsNew(true)
        this.$store.commit('caConfig/setLastCompareGroupId', this.keywordGroup.getId())

        if (this.$store.state.caConfig.lastCompareKeywordId === 0) {
          this.addKeyword()
        }

        track('settings.conversationAnalytics.tester.keywordGroup.add', {
          name: this.keywordGroup.getName(),
          description: this.keywordGroup.getDescription(),
          type: this.keywordGroup.getType().getName()
        })

        return
      }

      this.oldKeywordName = this.keywordGroup.getName()
      this.isCreating = false
    }
  }

  cancelNewGroup () {
    if (this.keywordGroup.getIsNew()) {
      this.caConfig
        .removeKeywordGroup(this.keywordGroup)
    } else {
      this.keywordGroup.setName(this.oldKeywordName)
    }

    this.isCreating = false
  }

  get isGroupNameValid () {
    if (this.keywordGroup.getName() === '') {
      return null
    }

    for (const config of this.caConfig.getKeywordSpottingConfig()) {
      if (
        config.getKeywordGroup().getId() !== 0 &&
        config.getKeywordGroup().getStatus() !== InfinityStatus.Deleted &&
        config.getKeywordGroup().getName().toLowerCase() === this.keywordGroup.getName().toLowerCase()
      ) {
        return config.getKeywordGroup().getId() === this.keywordGroup.getId() ? null : false
      }
    }

    return true
  }

  get availableKeywords () {
    return this.keywordGroup.getKeywords().filter(
      (keyword) => {
        if (this.showDeleted === false && keyword.getStatus() !== InfinityStatus.Ok) {
          return false
        }

        if (this.query) {
          return keyword.getKeywordGroup().getName().toLowerCase().includes(this.query) ||
            keyword.getPhrase().toLowerCase().includes(this.query)
        }

        return true
      }
    )
  }
}
