import Model, { ApiFieldDefinition, apiToModelTransformer, modelToApiTransformer } from '@infinity/shared/models/base'
import { Api, InfinityStatus, JsonData } from '@infinity/shared/helpers/api'
import KeywordGroupConfig from '@infinity/ca-tester/models/keywordGroupConfig'
import KeywordCriteria from '@infinity/ca-tester/models/keywordCriteria'
import { isArray } from 'lodash'

export enum KeywordLeg { Contact = 'contact', Operator = 'operator', All = 'all' }

export default class KeywordConfig extends Model {
  private id = 0
  private leg = KeywordLeg.All
  private phrase = ''
  private minConfidence = 60
  private score = 0
  private keywordGroup = new KeywordGroupConfig()
  private keywordCriteria: KeywordCriteria[] = []
  private status = InfinityStatus.Ok
  private isNew = false

  protected fieldDefinition: ApiFieldDefinition = {
    [Api.CaTestingService]: {
      KeywordID: 'id',
      Leg: 'leg',
      Phrase: 'phrase',
      MinConfidence: 'minConfidence',
      KwScore: 'score',
      KeywordStatus: 'status'
    },
    [Api.CaService]: {
      keywordId: 'id',
      keywordLeg: 'leg',
      keyword: 'phrase',
      keywordMinConfidence: 'minConfidence',
      keywordScore: 'score',
      keywordStatus: 'status'
    }
  }

  setId (id: number): KeywordConfig {
    this.id = id

    return this
  }

  getId (): number {
    return this.id
  }

  setLeg (leg: KeywordLeg): KeywordConfig {
    this.leg = leg

    return this
  }

  getLeg (): KeywordLeg {
    return this.leg
  }

  setPhrase (phrase: string): KeywordConfig {
    this.phrase = phrase

    return this
  }

  getPhrase (): string {
    return this.phrase
  }

  setMinConfidence (minConfidence: number): KeywordConfig {
    this.minConfidence = minConfidence

    return this
  }

  getMinConfidence (): number {
    return this.minConfidence
  }

  setScore (score: number): KeywordConfig {
    this.score = score

    return this
  }

  getScore (): number {
    return this.score
  }

  get isScorePositive (): boolean {
    return this.score > 0
  }

  get isScoreNegative (): boolean {
    return this.score < 0
  }

  getKeywordGroupId (): number {
    return this.keywordGroup.getId()
  }

  setKeywordGroup (keywordGroup: KeywordGroupConfig) {
    this.keywordGroup = keywordGroup

    return this
  }

  getKeywordGroup () {
    return this.keywordGroup
  }

  setStatus (status: InfinityStatus) {
    this.status = status

    return this
  }

  getStatus () {
    return this.status
  }

  setKeywords (keywords: KeywordConfig[]) {
    this.keywords = keywords

    return this
  }

  setIsNew (isNew: boolean) {
    this.isNew = isNew

    return this
  }

  getIsNew () {
    return this.isNew
  }

  getKeywordCriteria () {
    return this.keywordCriteria
  }

  setKeywordCriteria (keywordCriteria: KeywordCriteria[]) {
    this.keywordCriteria = keywordCriteria

    return this
  }

  addKeywordCriteria (value: KeywordCriteria) {
    this.keywordCriteria.push(value)

    return this
  }

  removeKeywordCriteria (value: KeywordCriteria) {
    const index = this.keywordCriteria.indexOf(value)

    if (index > -1) {
      this.keywordCriteria.splice(index, 1)
    }

    return this
  }

  fromApiTransformer (data: JsonData, api: Api): KeywordConfig {
    apiToModelTransformer(this, data, this.fieldDefinition[api])

    if (api === Api.CaService && isArray(data.keywordCriteria)) {
      for (const criteria of data.keywordCriteria) {
        this.addKeywordCriteria(
          new KeywordCriteria()
            .fromApiTransformer(criteria as JsonData, api)
        )
      }
    }

    if (api === Api.CaTestingService && isArray(data.AdvancedCriteria)) {
      for (const criteria of data.AdvancedCriteria) {
        this.addKeywordCriteria(
          new KeywordCriteria()
            .fromApiTransformer(criteria as JsonData, Api.CaService)
        )
      }
    }

    return this
  }

  toApiTransformer (api: Api): JsonData {
    const data = modelToApiTransformer(this, this.fieldDefinition[api])
    const criteriaData = []

    for (const criteria of this.keywordCriteria) {
      criteriaData.push(criteria.toApiTransformer(Api.CaService))
    }

    if (api === Api.CaService) {
      data.keywordCriteria = criteriaData
    }

    if (api === Api.CaTestingService) {
      data.AdvancedCriteria = criteriaData
    }

    return data
  }
}
