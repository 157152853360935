import Model from '@infinity/shared/models/base'
import { Api, JsonData } from '@infinity/shared/helpers/api'
import { Sentence } from '@infinity/ca-tester/models/sentence'

export class SentenceResults extends Model {
  protected fieldDefinition = {
    [Api.CaTestingService]: {},
    [Api.CaProviderVbService]: {}
  }

  private sentences: Sentence[] = []

  getSentences (): Sentence[] {
    return this.sentences
  }

  hasSentences (): boolean {
    return this.sentences.length > 0
  }

  get Transcript (): string[] {
    return this.getSentences().map(sentence => {
      return sentence.Sentence
    })
  }

  fromApiTransformer (data: JsonData, api: Api): SentenceResults {
    if (data.sentences && Array.isArray(data.sentences)) {
      for (const sentence of data.sentences) {
        this.sentences.push(
          new Sentence()
            .fromApiTransformer(sentence as JsonData, api)
        )
      }
    }

    return this
  }

  toApiTransformer (): JsonData | undefined {
    return undefined
  }
}
