import Model, { ApiFieldDefinition } from '@infinity/shared/models/base'
import { Api, JsonData } from '@infinity/shared/helpers/api'
import KeywordGroupConfig from '@infinity/ca-tester/models/keywordGroupConfig'
import KeywordConfig from '@infinity/ca-tester/models/keywordConfig'

export default class KeywordSpottingConfig extends Model {
  private keywordGroup = new KeywordGroupConfig()

  protected fieldDefinition: ApiFieldDefinition = {
    [Api.CaService]: {}
  }

  setKeywordGroup (keywordGroup: KeywordGroupConfig) {
    this.keywordGroup = keywordGroup

    return this
  }

  getKeywordGroup () {
    return this.keywordGroup
  }

  fromApiTransformer (data: JsonData, api: Api): KeywordSpottingConfig {
    if (data.keywordGroup && typeof data.keywordGroup === 'object' && !Array.isArray(data.keywordGroup)) {
      this.keywordGroup.fromApiTransformer(data.keywordGroup, api)
    }

    if (data.keywords && Array.isArray(data.keywords)) {
      for (const keyword of data.keywords) {
        const keywordConfig = new KeywordConfig()
          .fromApiTransformer(keyword as JsonData, api)
          .setKeywordGroup(this.keywordGroup)

        this.keywordGroup.addKeyword(keywordConfig)
      }
    }

    return this
  }

  toApiTransformer (api: Api): JsonData {
    const keywordGroup = this.keywordGroup.toApiTransformer(api)

    if (keywordGroup.keywordGroupId === 0) {
      delete keywordGroup.keywordGroupId
    }

    const data: { keywordGroup: JsonData; keywords: JsonData[] } = {
      keywordGroup,
      keywords: []
    }

    for (const keywordConfig of this.keywordGroup.getKeywords()) {
      const keywordData = keywordConfig.toApiTransformer(api)

      if (keywordData.keywordId === 0) {
        delete keywordData.keywordId
      }

      if (keywordData) {
        data.keywords.push(keywordData)
      }
    }

    return data
  }
}
