




import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class FlagIcon extends Vue {
  @Prop({ required: true, type: String })
  countryCode!: string

  get imgPath () {
    return `/assets/flags/${this.countryCode.toLowerCase()}.svg`
  }
}
