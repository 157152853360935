var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-container",
        { staticClass: "mt-3" },
        [
          _c(
            "b-row",
            {
              staticClass: "justify-content-center",
              attrs: { "align-v": "center" }
            },
            [
              _c(
                "b-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "b-row",
                    {
                      staticClass:
                        "my-3 d-flex justify-content-between align-items-center"
                    },
                    [
                      _c("b-col", [
                        _c("h3", [
                          _vm._v(
                            "Testing Conversation Analytics on " +
                              _vm._s(_vm.selectedCalls.length) +
                              " " +
                              _vm._s(
                                _vm.selectedCalls.length === 1
                                  ? "call"
                                  : "calls"
                              )
                          )
                        ])
                      ]),
                      _c(
                        "b-col",
                        { attrs: { cols: "6", lg: "4" } },
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "float-right",
                              on: {
                                click: function($event) {
                                  return _vm.$router.push(
                                    "/" + _vm.backWithParams
                                  )
                                }
                              }
                            },
                            [
                              _c("b-icon-chevron-left", {
                                staticClass: "mr-1"
                              }),
                              _vm._v("Go back")
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-collapse",
                    { attrs: { visible: "", id: "results" } },
                    [
                      [
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              [
                                _c(
                                  "b-overlay",
                                  {
                                    attrs: {
                                      show: _vm.isLoading,
                                      rounded: "sm"
                                    }
                                  },
                                  [
                                    _c(
                                      "b-card",
                                      {
                                        staticClass: "results-card",
                                        attrs: { "no-body": "" }
                                      },
                                      [
                                        !_vm.isLoading
                                          ? _c(
                                              "b-tabs",
                                              {
                                                staticClass: "call-tabs",
                                                attrs: {
                                                  card: "",
                                                  small: "",
                                                  lazy: "",
                                                  pills: ""
                                                }
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "px-4 pt-3" },
                                                  [
                                                    _c(
                                                      "p",
                                                      { staticClass: "mb-0" },
                                                      [
                                                        _vm._v(
                                                          "Use the transcription below to check spotting results and tweak your keyword spotting settings."
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                ),
                                                _vm._l(
                                                  _vm.selectedCalls,
                                                  function(call, index) {
                                                    return _c(
                                                      "b-tab",
                                                      {
                                                        key: call.getRowId(),
                                                        attrs: {
                                                          disabled:
                                                            _vm.isLoading,
                                                          active:
                                                            !_vm.isLoading &&
                                                            index === 0
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "title",
                                                              fn: function() {
                                                                return [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "d-flex align-items-center"
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "b-icon-telephone",
                                                                        {
                                                                          staticClass:
                                                                            "mr-3"
                                                                        }
                                                                      ),
                                                                      _c(
                                                                        "div",
                                                                        [
                                                                          _c(
                                                                            "phone-number",
                                                                            {
                                                                              attrs: {
                                                                                "phone-number": call.getSrcPhoneNumber()
                                                                              }
                                                                            }
                                                                          ),
                                                                          _c(
                                                                            "div",
                                                                            [
                                                                              _c(
                                                                                "strong",
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      call.getTrackingPoolName()
                                                                                    )
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ]
                                                                          )
                                                                        ],
                                                                        1
                                                                      ),
                                                                      _c(
                                                                        "b-button-close",
                                                                        {
                                                                          staticClass:
                                                                            "ml-2",
                                                                          attrs: {
                                                                            disabled:
                                                                              _vm
                                                                                .selectedCalls
                                                                                .length ===
                                                                              1
                                                                          },
                                                                          on: {
                                                                            click: function(
                                                                              $event
                                                                            ) {
                                                                              return _vm.removeCall(
                                                                                call
                                                                              )
                                                                            }
                                                                          }
                                                                        }
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ]
                                                              },
                                                              proxy: true
                                                            }
                                                          ],
                                                          null,
                                                          true
                                                        )
                                                      },
                                                      [
                                                        !_vm.isLoading
                                                          ? _c("result-panel", {
                                                              attrs: {
                                                                config:
                                                                  _vm.compareConfig,
                                                                call: call,
                                                                "can-save": true,
                                                                "is-draft":
                                                                  _vm.hasDraft
                                                              }
                                                            })
                                                          : _vm._e()
                                                      ],
                                                      1
                                                    )
                                                  }
                                                )
                                              ],
                                              2
                                            )
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                !_vm.isLoading
                                  ? _c(
                                      "config-create",
                                      {
                                        staticClass: "mt-3",
                                        attrs: {
                                          "ca-config": _vm.compareConfig
                                        }
                                      },
                                      [
                                        _c("p", [
                                          _vm._v(
                                            "Tweak your groups, keywords and phrases to get the best results."
                                          )
                                        ])
                                      ]
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "w-100 my-5 d-flex justify-content-center"
                          },
                          [
                            _c(
                              "b-button",
                              {
                                directives: [
                                  {
                                    name: "infinity-tooltip",
                                    rawName: "v-infinity-tooltip",
                                    value: "Run Comparison",
                                    expression: "'Run Comparison'"
                                  }
                                ],
                                attrs: {
                                  size: "lg",
                                  variant: "primary",
                                  pill: ""
                                },
                                on: { click: _vm.doRunCompare }
                              },
                              [_c("b-icon-shuffle")],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    ],
                    2
                  ),
                  _c(
                    "b-collapse",
                    { attrs: { id: "compare-results" } },
                    [
                      _vm.showComparison
                        ? _c(
                            "b-row",
                            { staticClass: "mb-5" },
                            [
                              _c(
                                "b-col",
                                [
                                  _c(
                                    "b-card",
                                    { attrs: { "no-body": "" } },
                                    [
                                      _c(
                                        "b-overlay",
                                        {
                                          attrs: {
                                            show: _vm.isSaving,
                                            rounded: "sm"
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "overlay",
                                                fn: function() {
                                                  return [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "text-center"
                                                      },
                                                      [
                                                        _c("b-spinner"),
                                                        _c(
                                                          "h5",
                                                          {
                                                            staticClass: "mt-2"
                                                          },
                                                          [
                                                            _vm._v(
                                                              "Updating your Keyword Spotting criteria"
                                                            )
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                },
                                                proxy: true
                                              }
                                            ],
                                            null,
                                            false,
                                            2007438977
                                          )
                                        },
                                        [
                                          _c(
                                            "b-tabs",
                                            {
                                              staticClass: "call-tabs",
                                              attrs: {
                                                card: "",
                                                small: "",
                                                lazy: "",
                                                pills: ""
                                              }
                                            },
                                            [
                                              _vm._l(
                                                _vm.selectedCalls,
                                                function(call, index) {
                                                  return _c(
                                                    "b-tab",
                                                    {
                                                      key: call.getRowId(),
                                                      attrs: {
                                                        active:
                                                          !_vm.isLoading &&
                                                          index === 0
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "title",
                                                            fn: function() {
                                                              return [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "d-flex align-items-center"
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "b-icon-telephone",
                                                                      {
                                                                        staticClass:
                                                                          "mr-3"
                                                                      }
                                                                    ),
                                                                    _c(
                                                                      "div",
                                                                      [
                                                                        _c(
                                                                          "phone-number",
                                                                          {
                                                                            attrs: {
                                                                              "phone-number": call.getSrcPhoneNumber()
                                                                            }
                                                                          }
                                                                        ),
                                                                        _c(
                                                                          "div",
                                                                          [
                                                                            _c(
                                                                              "strong",
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    call.getTrackingPoolName()
                                                                                  )
                                                                                )
                                                                              ]
                                                                            )
                                                                          ]
                                                                        )
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _c(
                                                                      "b-button-close",
                                                                      {
                                                                        staticClass:
                                                                          "ml-2",
                                                                        attrs: {
                                                                          disabled:
                                                                            _vm
                                                                              .selectedCalls
                                                                              .length ===
                                                                            1
                                                                        },
                                                                        on: {
                                                                          click: function(
                                                                            $event
                                                                          ) {
                                                                            return _vm.removeCall(
                                                                              call
                                                                            )
                                                                          }
                                                                        }
                                                                      }
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              ]
                                                            },
                                                            proxy: true
                                                          }
                                                        ],
                                                        null,
                                                        true
                                                      )
                                                    },
                                                    [
                                                      _c("h4", [
                                                        _vm._v(
                                                          "Compare Spotting Results"
                                                        )
                                                      ]),
                                                      _c("result-panel", {
                                                        attrs: {
                                                          "base-config":
                                                            _vm.baseConfig,
                                                          config:
                                                            _vm.compareConfig,
                                                          call: call,
                                                          "show-transcription": false,
                                                          "is-draft":
                                                            _vm.hasDraft
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                }
                                              ),
                                              _c(
                                                "b-card-body",
                                                [
                                                  _c(
                                                    "b-row",
                                                    [
                                                      _c(
                                                        "b-col",
                                                        {
                                                          staticClass:
                                                            "text-center",
                                                          attrs: { cols: "6" }
                                                        },
                                                        [
                                                          _c(
                                                            "b-button",
                                                            {
                                                              attrs: {
                                                                size: "lg",
                                                                variant:
                                                                  "primary"
                                                              },
                                                              on: {
                                                                click:
                                                                  _vm.doSaveDraft
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "b-icon-file-earmark-arrow-up-fill"
                                                              ),
                                                              _vm._v(
                                                                " Save draft "
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "p",
                                                            {
                                                              staticClass:
                                                                "mt-2"
                                                            },
                                                            [
                                                              _vm._v(
                                                                "Save your changes into a draft and continue testing"
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "b-col",
                                                        {
                                                          staticClass:
                                                            "text-center",
                                                          attrs: { cols: "6" }
                                                        },
                                                        [
                                                          _c(
                                                            "b-button",
                                                            {
                                                              attrs: {
                                                                size: "lg",
                                                                variant:
                                                                  "success"
                                                              },
                                                              on: {
                                                                click:
                                                                  _vm.doSaveConfig
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "b-icon-cloud-arrow-up-fill"
                                                              ),
                                                              _vm._v(
                                                                " Save settings "
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "p",
                                                            {
                                                              staticClass:
                                                                "mt-2"
                                                            },
                                                            [
                                                              _vm._v(
                                                                "Your current settings will take effect immediately"
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            2
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "choose-draft",
            size: "md",
            title: "Use draft?",
            centered: "",
            static: "",
            "no-close-on-esc": "",
            "no-close-on-backdrop": "",
            "hide-header-close": ""
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function(ref) {
                var cancel = ref.cancel
                var ok = ref.ok
                return [
                  _c(
                    "b-button",
                    {
                      attrs: { variant: "outline-whale" },
                      on: {
                        click: function($event) {
                          _vm.doUseProduction()
                          ok()
                        }
                      }
                    },
                    [_vm._v(" Use live settings ")]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: { variant: "primary" },
                      on: {
                        click: function($event) {
                          return cancel()
                        }
                      }
                    },
                    [_vm._v(" Continue with draft ")]
                  )
                ]
              }
            }
          ])
        },
        [
          _c("p", [
            _vm._v("We found a saved draft configuration for Keyword Spotting.")
          ]),
          _c("p", [
            _vm._v(
              "Do you want continue with the saved draft, or use your current settings?"
            )
          ]),
          _c("p", { staticClass: "text-whale" }, [
            _vm._v(
              "Saving changes to current settings will overwrite your existing draft."
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }