var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "bg-light p-3 rounded-lg border" },
    [
      _vm.keywordCriteria.length
        ? _c(
            "div",
            { staticClass: "d-flex mb-3 w-100 align-items-center" },
            [
              _c("span", { staticClass: "text-whale" }, [
                _vm._v(_vm._s(_vm.spotting ? "Spot" : "Do not spot"))
              ]),
              _c("span", { staticClass: "mx-2" }, [
                _vm._v("this keyword when")
              ]),
              _c("b-form-radio-group", {
                attrs: {
                  checked: _vm.spottingCombination,
                  options: [
                    { text: "All", value: "and" },
                    { text: "At least one", value: "or" }
                  ],
                  "button-variant": "outline-primary",
                  buttons: ""
                },
                on: {
                  input: function($event) {
                    return _vm.onSpottingCombinationChange($event)
                  }
                }
              }),
              _c("span", { staticClass: "ml-2" }, [
                _vm._v("criteria has been met")
              ])
            ],
            1
          )
        : _vm._e(),
      _vm._l(_vm.keywordCriteria, function(criteria) {
        return _c("keyword-criteria-component", {
          key: criteria.getType() + " - " + Math.random(),
          attrs: { "keyword-criteria": criteria },
          on: {
            delete: _vm.onDelete,
            spottingChange: _vm.onCriteriaSpottingChange
          }
        })
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }