var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "infinity-player bg-light rounded" },
    [
      _c("b-overlay", { attrs: { show: _vm.isLoading } }, [
        _vm.file
          ? _c("audio", { ref: "audiofile", attrs: { src: _vm.file } })
          : _vm._e(),
        _c("div", { staticClass: "d-flex align-items-center" }, [
          _c(
            "div",
            [
              _c(
                "b-button-group",
                { staticClass: "mx-1" },
                [
                  _c(
                    "b-button",
                    {
                      attrs: { variant: "outline", disabled: !_vm.file },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          $event.preventDefault()
                          return _vm.stop($event)
                        }
                      }
                    },
                    [_c("b-icon-stop-fill")],
                    1
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: {
                        variant: "outline",
                        disabled: _vm.isLoading || _vm.hasError
                      },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          $event.preventDefault()
                          return _vm.play($event)
                        }
                      }
                    },
                    [
                      _vm.isLoading
                        ? _c("b-spinner", { attrs: { small: "" } })
                        : _vm.hasError
                        ? _c("b-icon-exclamation-triangle-fill", {
                            directives: [
                              {
                                name: "infinity-tooltip",
                                rawName: "v-infinity-tooltip",
                                value:
                                  "We couldn't find the recording for this call.",
                                expression:
                                  "'We couldn\\'t find the recording for this call.'"
                              }
                            ]
                          })
                        : !_vm.isPlaying
                        ? _c("b-icon-play-fill")
                        : _c("b-icon-pause-fill")
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "seeker" },
            [
              _c("b-form-input", {
                directives: [
                  {
                    name: "infinity-tooltip",
                    rawName: "v-infinity-tooltip:follow.horizontal",
                    value: _vm.sliderHoverValue,
                    expression: "sliderHoverValue",
                    arg: "follow",
                    modifiers: { horizontal: true }
                  }
                ],
                attrs: {
                  type: "range",
                  min: "0",
                  max: _vm.recordingSeconds * 1000,
                  value: _vm.currentMs
                },
                on: {
                  input: function($event) {
                    return _vm.seek($event, false, false)
                  },
                  mousemove: _vm.updateSliderValue
                }
              })
            ],
            1
          ),
          _c(
            "div",
            [
              _c(
                "b-button-group",
                { staticClass: "mx-1" },
                [
                  _c(
                    "b-button",
                    {
                      class: {
                        "text-whale":
                          _vm.audio && _vm.audio.playbackRate === 0.75
                      },
                      attrs: {
                        size: "sm",
                        variant: "outline",
                        disabled: !_vm.audio
                      },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          $event.preventDefault()
                          _vm.playbackRate = 0.75
                        }
                      }
                    },
                    [_vm._v("0.75x")]
                  ),
                  _c(
                    "b-button",
                    {
                      class: {
                        "text-whale":
                          _vm.audio && _vm.audio.playbackRate === 1.0
                      },
                      attrs: {
                        size: "sm",
                        variant: "outline",
                        disabled: !_vm.audio
                      },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          $event.preventDefault()
                          _vm.playbackRate = 1.0
                        }
                      }
                    },
                    [_vm._v("1x")]
                  ),
                  _c(
                    "b-button",
                    {
                      class: {
                        "text-whale":
                          _vm.audio && _vm.audio.playbackRate === 1.25
                      },
                      attrs: {
                        size: "sm",
                        variant: "outline",
                        disabled: !_vm.audio
                      },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          $event.preventDefault()
                          _vm.playbackRate = 1.25
                        }
                      }
                    },
                    [_vm._v("1.25x")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("div", { staticClass: "px-2 ml-1" }, [
            _c(
              "span",
              {
                directives: [
                  {
                    name: "infinity-ms",
                    rawName: "v-infinity-ms",
                    value: _vm.currentMs,
                    expression: "currentMs"
                  }
                ]
              },
              [_vm._v(_vm._s(_vm.currentMs))]
            ),
            _vm._v(" / "),
            _c(
              "span",
              {
                directives: [
                  {
                    name: "infinity-seconds",
                    rawName: "v-infinity-seconds",
                    value: _vm.recordingSeconds,
                    expression: "recordingSeconds"
                  }
                ]
              },
              [_vm._v(_vm._s(_vm.recordingSeconds))]
            )
          ]),
          _c(
            "div",
            [
              _c(
                "b-button-group",
                { staticClass: "mx-1" },
                [
                  _c(
                    "b-button",
                    {
                      directives: [
                        {
                          name: "test",
                          rawName: "v-test",
                          value: "downloadBtn",
                          expression: "'downloadBtn'"
                        }
                      ],
                      attrs: { variant: "outline", disabled: !_vm.file },
                      on: { click: _vm.download }
                    },
                    [_c("b-icon-download")],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }