import axios from 'axios'
const moduleCache = new Map()

export enum ComponentLibrary {
  MfeShared = process.env.VUE_APP_MFE_SHARED_URI
}

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
export const loadEsModule = async (baseUrl: ComponentLibrary, fileName: string | undefined): Promise<{ [k: string]: any }> => {
  const cacheKey = `${baseUrl}~${fileName}`

  if (moduleCache.has(cacheKey)) {
    return moduleCache.get(cacheKey)
  }

  const { data } = await axios.get(`${baseUrl}/files.json`)

  const { js = [] }: { js: string[] } = data

  fileName = js.find((file) => {
    return file.startsWith(`/js/${fileName}.`)
  })

  if (fileName) {
    const moduleData = await import(/* webpackIgnore: true */`${baseUrl}${fileName}`)

    moduleCache.set(cacheKey, moduleData)

    return moduleData
  }

  return {}
}
