






































import { Component, Vue } from 'vue-property-decorator'
import { AuthUtil } from '@infinity/shared/utils/auth'

@Component({
})
export default class Login extends Vue {
  username = '';
  password = '';
  formState: boolean | null = null
  isLoading = false

  async onSubmit () {
    this.isLoading = true

    try {
      await AuthUtil.authenticate(this.username, this.password, null)
      this.$store.commit('setAuthenticated', AuthUtil.isAuthenticated)
      this.formState = null
      await this.$router.push('/')
    } catch (e) {
      this.formState = false
    }

    this.isLoading = false
  }
}
