






































































































































































import { Component } from 'vue-property-decorator'
import CaConfig from '@infinity/ca-tester/models/caConfig'
import { TriggerCall } from '@infinity/ca-tester/models/triggerCall'
import ConfigCreate from '@infinity/ca-tester/components/ConfigCreate.vue'
import ResultPanel from '@infinity/ca-tester/components/ResultPanel.vue'
import PhoneNumber from '@infinity/ca-tester/components/utils/PhoneNumber.vue'
import { mixins } from 'vue-class-component'
import WebComponentAuthMixin from '@infinity/shared/mixins/web-component-auth'

@Component({
  components: {
    PhoneNumber,
    ConfigCreate,
    ResultPanel
  }
})
export default class CaTesterView extends mixins(WebComponentAuthMixin) {
  private isLoading = true
  private isSaving = false
  private showComparison = false
  private useDraft = false

  get hasDraft (): boolean {
    return this.$store.state.caConfig.hasDraft
  }

  get baseConfig (): CaConfig {
    return this.$store.state.caConfig.baseConfig
  }

  get compareConfig (): CaConfig {
    return this.$store.state.caConfig.compareConfig
  }

  set compareConfig (value: CaConfig) {
    this.$store.commit('caConfig/setCompareConfig', value)
  }

  get backWithParams (): URLSearchParams | string {
    const params = new URLSearchParams(this.$store.state.queryParams)

    return params ? `?${params}` : ''
  }

  async created () {
    await this.waitForBoot()

    if (this.$route.query.call) {
      await this.$store.dispatch('calls/loadSelectedCalls', this.$route.query.call)
    }

    if (this.selectedCalls.length === 0) {
      return await this.$router.push('/')
    }

    await this.initTester()

    window.onbeforeunload = () => true
  }

  async initTester () {
    this.isLoading = true

    await this.$store.dispatch('caConfig/loadBaseConfig')
    await this.$store.dispatch('caConfig/loadDraftConfig')

    if (this.hasDraft) {
      this.isLoading = false

      if (this.useDraft === false) {
        this.$bvModal.show('choose-draft')
      }

      return
    }

    await this.$store.dispatch('caConfig/createCompareConfig')
    this.isLoading = false
  }

  appDeactivated () {
    window.onbeforeunload = null
  }

  beforeDestroy () {
    window.onbeforeunload = null
  }

  doRunCompare () {
    this.showComparison = true
    this.$root.$emit('bv::toggle::collapse', 'results')
    this.$root.$emit('bv::toggle::collapse', 'compare-results')
  }

  continueTesting () {
    this.showComparison = false
    this.$root.$emit('bv::toggle::collapse', 'results')
    this.$root.$emit('bv::toggle::collapse', 'compare-results')
  }

  async doUseProduction () {
    this.isLoading = true
    this.useDraft = false
    await this.$store.dispatch('caConfig/createCompareConfig')

    this.isLoading = false
  }

  async doSaveConfig () {
    this.isSaving = true

    try {
      await this.$store.dispatch('caConfig/saveConfig')

      this.$bvToast.toast('Calls from now on will be matched against your new settings.', {
        title: 'Keyword Spotting updated',
        autoHideDelay: 5000,
        variant: 'success',
        solid: true,
        toaster: 'b-toaster-top-center'
      })

      // Reset the compare config for reinitialisation
      this.compareConfig = new CaConfig()
      this.useDraft = false

      await this.initTester()
      this.continueTesting()
    } catch (e) {
      this.$bvToast.toast('We encountered an error updating your settings, please try again later.', {
        title: 'Keyword Spotting not updated',
        variant: 'danger',
        solid: true,
        toaster: 'b-toaster-top-center'
      })
    }

    this.isSaving = false
  }

  async doSaveDraft () {
    this.isSaving = true

    try {
      await this.$store.dispatch('caConfig/saveDraft')

      this.$bvToast.toast('Your draft has been saved for for further editing as required.', {
        title: 'Keyword Spotting draft saved',
        autoHideDelay: 5000,
        variant: 'success',
        solid: true,
        toaster: 'b-toaster-top-center'
      })

      this.useDraft = true

      await this.initTester()
      this.continueTesting()
    } catch (e) {
      this.$bvToast.toast('We encountered an error saving your draft, please try again later.', {
        title: 'Keyword Spotting draft not saved',
        variant: 'danger',
        solid: true,
        toaster: 'b-toaster-top-center'
      })
    }

    this.isSaving = false
  }

  removeCall (call: TriggerCall) {
    this.$store.commit('calls/removeCall', call)
  }

  get selectedCalls (): TriggerCall[] {
    return this.$store.state.calls.selectedCalls
  }
}
