import Model, { ApiFieldDefinition, apiToModelTransformer, modelToApiTransformer } from '@infinity/shared/models/base'
import { Api, JsonData } from '@infinity/shared/helpers/api'
import CaConfig from '@infinity/ca-tester/models/caConfig'
import { TriggerCall } from '@infinity/ca-tester/models/triggerCall'

export default class TestConfig extends Model {
  private call = new TriggerCall()
  private checks: CaConfig[] = []

  protected fieldDefinition: ApiFieldDefinition = {
    [Api.CaTestingService]: {
      transcription: 'transcription',
      bridgeDuration: 'bridgeDuration'
    }
  }

  getCall () {
    return this.call
  }

  setCall (call: TriggerCall) {
    this.call = call

    return this
  }

  getTranscription () {
    return this.call.getTranscriptionFileName()
  }

  getBridgeDuration () {
    return this.call.getBridgeDuration()
  }

  getChecks () {
    return this.checks
  }

  setChecks (value: CaConfig[]) {
    this.checks = value

    return this
  }

  fromApiTransformer (data: JsonData, api: Api): TestConfig {
    apiToModelTransformer(this, data, this.fieldDefinition[api])

    return this
  }

  toApiTransformer (api: Api): JsonData | undefined {
    const data = modelToApiTransformer(this, this.fieldDefinition[api])
    data.checks = [] as JsonData[]

    for (const check of this.checks) {
      const checkData = check.toApiTransformer(api)

      if (checkData) {
        data.checks.push(checkData)
      }
    }

    return data
  }
}
