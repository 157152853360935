






























import { Component, Vue } from 'vue-property-decorator'
import InstallationPicker from '@infinity/ca-tester/components/InstallationPicker.vue'
import CallList from '@infinity/ca-tester/components/CallList.vue'
import { TriggerCall } from '@infinity/ca-tester/models/triggerCall'

@Component({
  components: {
    InstallationPicker,
    CallList
  }
})
export default class Calls extends Vue {
  get selectedCallIds () {
    return this.$store.state.calls.selectedCalls.map(
      (call: TriggerCall) => {
        return call.getCallRef()
      }
    )
  }

  appDeactivated () {
    this.$store.commit('calls/setSelectedCalls', [])
  }

  doNext () {
    this.$router.push({ path: '/tester', query: { call: this.selectedCallIds } })
  }
}
