











import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class CaLogo extends Vue {
  @Prop({ type: String }) title?: string
}
