import Vue from 'vue'
import App from '@infinity/ca-tester/App.vue'
import router from '@infinity/ca-tester/router'
import store from '@infinity/ca-tester/store'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
// @ts-ignore
import VueTestAttribute from 'vue-test-attribute'
// @ts-ignore
import wrap from '@vue/web-component-wrapper'
import InfinityPopover from '@infinity/ca-tester/plugins/infinity-popover'
import InfinityTime from '@infinity/ca-tester/plugins/infinity-time'
import InfinityServices from '@infinity/shared/plugins/infinity-services'
import '@infinity/ca-tester/styles/index.scss'

export const WebComponentTag = 'ca-tester'

Vue.config.productionTip = false

// stop Vue trying to initialise web components as Vue components
Vue.config.ignoredElements = ['filter-bar']

Vue.use(InfinityServices)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VueTestAttribute)
Vue.use(InfinityPopover)
Vue.use(InfinityTime)

Vue.mixin({ router, store })

if (window.customElements.get(WebComponentTag) === undefined) {
  window.customElements.define(WebComponentTag, wrap(Vue, App))
}
