import { formatTriggerTimeAgo } from '@infinity/ca-tester/utils/time'

export default {
  inserted: function (el: HTMLElement) {
    const innerText = el.innerText

    if (innerText) {
      const timeAgo = formatTriggerTimeAgo(innerText)

      if (timeAgo) {
        el.innerText = timeAgo
      }
    }
  }
}
