import Vue from 'vue'
import Vuex from 'vuex'
import { AuthUtil } from '@infinity/shared/utils/auth'
import Installation from '@infinity/ca-tester/models/installation'
import transcription from '@infinity/ca-tester/store/modules/transcription'
import calls from '@infinity/ca-tester/store/modules/calls'
import caConfig from '@infinity/ca-tester/store/modules/ca-config'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isAuthenticated: AuthUtil.isAuthenticated,
    selectedInstallation: Installation,
    followCallTranscript: true,
    showTranscript: true,
    queryParams: ''
  },
  mutations: {
    setAuthenticated (state, authenticated: boolean) {
      state.isAuthenticated = authenticated
    },
    setQueryParams (state, queryParams: string) {
      state.queryParams = queryParams
    },
    setInstallation (state, installation: typeof Installation) {
      state.selectedInstallation = installation
    }
  },
  modules: {
    transcription,
    calls,
    caConfig
  }
})
