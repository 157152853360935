






















































































import { Component, Prop, Watch } from 'vue-property-decorator'
import { PopoverEvent } from '@infinity/ca-tester/components/layout/InfinityPopover.vue'
import KeywordRow from '@infinity/ca-tester/components/KeywordRow.vue'
import Model from '@infinity/shared/models/base'
import CaConfig from '@infinity/ca-tester/models/caConfig'
import KeywordConfig from '@infinity/ca-tester/models/keywordConfig'
import { Sentence } from '@infinity/ca-tester/models/sentence'
import SentenceWord from '@infinity/ca-tester/models/sentenceWord'
import { SpottingResult } from '@infinity/ca-tester/models/spottingResult'
import { msToTimeString } from '@infinity/ca-tester/utils/time'
import { mixins } from 'vue-class-component'
import GetKeywordsMixin from '@infinity/ca-tester//mixins/get-keywords'
import { track } from '@infinity/ca-tester/utils/mixpanel'
import { callPlaybackSeek } from '@/components/CallPlayer.vue'

@Component({
  components: {
    KeywordRow
  }
})
export default class extends mixins(GetKeywordsMixin) {
  @Prop({ required: true })
  index!: number

  @Prop({ required: true })
  observer!: IntersectionObserver

  @Prop({ required: true })
  sentence!: Sentence

  @Prop({ required: true })
  results!: SpottingResult

  @Prop({ type: Boolean, default: false })
  canEdit!: boolean

  @Prop({ type: Boolean, default: false })
  canPlayReadOnly!: boolean

  @Prop({ type: Boolean, default: false })
  isLive!: boolean

  private popOverDisabled = false
  private isEditing = false
  private focusedWord: SentenceWord | null = null
  private editingKeyword: KeywordConfig | null = null

  mounted () {
    if (this.$el.nodeName === 'DIV') {
      this.observer.observe(this.$el)
    }
  }

  get showTranscript () {
    return this.$store.state.transcription.showTranscript
  }

  get followTranscript () {
    return this.$store.state.transcription.followTranscript
  }

  @Watch('sentenceIsActive')
  onSentenceActiveChanged (isActive: boolean) {
    if (this.followTranscript && isActive && this.$parent) {
      const { keywordContainer } = this.$parent.$refs
      if (!keywordContainer || !(keywordContainer instanceof Element)) {
        return
      }

      // @ts-ignore
      const containerHeight = keywordContainer.offsetHeight
      // @ts-ignore
      const elementOffset = this.$el.offsetTop
      // @ts-ignore
      const elementHeight = this.$el.offsetHeight
      const scrollTo = ((elementOffset - (containerHeight / 2)) + elementHeight / 2)

      if (elementOffset > containerHeight / 2 || keywordContainer.scrollTop <= scrollTo) {
        keywordContainer.scrollTo({
          top: scrollTo,
          behavior: 'smooth'
        })
      }
    }
  }

  get callPlaybackTime () {
    return this.$store.state.calls.currentPlaybackMs
  }

  get sentenceIsActive () {
    return (this.callPlaybackTime + 500) >= this.sentence.getStartTime() && this.callPlaybackTime <= (this.sentence.getEndTime() + 500)
  }

  getIsActive (word: SentenceWord) {
    if (!this.sentenceIsActive) {
      return false
    }

    return (this.callPlaybackTime + 500) >= word.getStartTime() && this.callPlaybackTime <= (word.getEndTime() + 500)
  }

  get keywords () {
    return this.getKeywords(this.sentence, this.results)
  }

  get keywordsOnly () {
    return this.sentence.getWords().filter(
      (word) => {
        return word.getIsKeyword() ||
          (!this.isLive && this.getCompareKeyword(this.compareConfigKeywords, this.sentence, word))
      }
    )
  }

  get sentenceStartTime () {
    return msToTimeString(this.sentence.getStartTime())
  }

  getStartTime (word: SentenceWord) {
    return msToTimeString(word.getStartTime())
  }

  get isCallPlaying () {
    return this.$store.state.calls.isPlaying
  }

  get compareConfig (): CaConfig {
    return this.$store.state.caConfig.compareConfig
  }

  get compareConfigKeywords () {
    return this.compareConfig.getKeywordsConfig()
  }

  getKeywordGroupName (word: SentenceWord) {
    if (word.getIsKeyword()) {
      const keyword = this.compareConfigKeywords.find(keyword => keyword.getId() === word.getKeywordId())
      if (keyword) {
        return keyword.getKeywordGroup().getName()
      }
      return undefined
    }
    const keyword = this.getCompareKeyword(this.compareConfigKeywords, this.sentence, word)
    if (keyword) {
      return keyword.getKeywordGroup().getName()
    }
  }

  setFocusedWord (word: SentenceWord) {
    if (!this.popOverDisabled) {
      this.focusedWord = word
    }
  }

  getKeywordForEdit (word: SentenceWord): KeywordConfig | Model | undefined {
    return word.getIsKeyword() ? this.getKeyword(this.keywords, word) : this.getCompareKeyword(this.compareConfigKeywords, this.sentence, word)
  }

  doClickWord (word: SentenceWord) {
    if (this.canEdit) {
      if (!this.getKeywordForEdit(word)) {
        this.doCreateKeyword(word)
      } else {
        this.doEditKeyword(word)
      }

      return
    }

    if (this.canPlayReadOnly) {
      this.$root.$emit(callPlaybackSeek, word.getStartTime(), true)
    }
  }

  doEditKeyword (word: SentenceWord) {
    const keywordForEdit = this.getKeywordForEdit(word)
    if (!this.popOverDisabled && this.canEdit && keywordForEdit && !this.isCallPlaying) {
      this.editingKeyword = new KeywordConfig()
      this.editingKeyword
        .setId(keywordForEdit.getId())
        .setScore(keywordForEdit.getScore())
        .setLeg(keywordForEdit.getLeg())
        .setMinConfidence(keywordForEdit.getMinConfidence())
        .setPhrase(keywordForEdit.getPhrase())
        .setKeywordGroup(keywordForEdit.getKeywordGroup())

      for (const criteria of keywordForEdit.getKeywordCriteria()) {
        this.editingKeyword.addKeywordCriteria(criteria)
      }

      this.isEditing = true
      this.$root.$emit(PopoverEvent.Disable)
    }
  }

  doCreateKeyword (word: SentenceWord) {
    if (!this.popOverDisabled && this.canEdit && !this.isCallPlaying) {
      this.editingKeyword = new KeywordConfig()
      this.editingKeyword
        .setPhrase(word.getWord().toLowerCase())

      this.isEditing = true
      this.$root.$emit(PopoverEvent.Disable)
    }
  }

  doCancel () {
    const editingKeyword = this.editingKeyword
    if (!editingKeyword) {
      this.isEditing = false
      this.$root.$emit(PopoverEvent.Enable)

      return
    }

    if (editingKeyword.getIsNew()) {
      this.$store.commit('caConfig/setLastCompareKeywordId', this.$store.state.caConfig.lastCompareKeywordId - 1)
    }

    this.$root.$emit(PopoverEvent.Enable)
    this.isEditing = false
  }

  doSave () {
    const { editingKeyword } = this
    if (!editingKeyword) {
      this.isEditing = false
      this.$root.$emit(PopoverEvent.Enable)

      return
    }

    if (!editingKeyword.getIsNew() && editingKeyword.getId() <= this.$store.state.caConfig.lastCompareKeywordId) {
      const existing = this.compareConfigKeywords.find(
        (keyword) => {
          return keyword.getId() === editingKeyword.getId()
        }
      )

      if (existing) {
        existing
          .setLeg(editingKeyword.getLeg())
          .setPhrase(editingKeyword.getPhrase())
          .setMinConfidence(editingKeyword.getMinConfidence())
          .setScore(editingKeyword.getScore())
          .setKeywordCriteria(editingKeyword.getKeywordCriteria())
      }

      this.isEditing = false
      this.$root.$emit(PopoverEvent.Enable)

      track(existing ? 'keyword.edit' : 'keyword.add', {
        keyword: editingKeyword.getPhrase(),
        group: editingKeyword.getKeywordGroup().getName(),
        leg: editingKeyword.getLeg(),
        minConfidence: editingKeyword.getMinConfidence(),
        score: editingKeyword.getScore()
      })

      return
    }

    editingKeyword.getKeywordGroup().addKeyword(editingKeyword)

    this.isEditing = false
    this.$root.$emit(PopoverEvent.Enable)
    this.$emit('new-keyword', editingKeyword.getPhrase())

    track('settings.conversationAnalytics.tester.keyword.addFromTranscription', {
      keyword: editingKeyword.getPhrase(),
      group: editingKeyword.getKeywordGroup().getName(),
      leg: editingKeyword.getLeg(),
      minConfidence: editingKeyword.getMinConfidence(),
      score: editingKeyword.getScore()
    })
  }

  getPhraseClass (word: SentenceWord) {
    if (word.getIsKeyword()) {
      return word.getPhrasePart()
    }

    const compareKeyword = this.getCompareKeyword(this.compareConfigKeywords, this.sentence, word)
    if (!compareKeyword) {
      return null
    }

    const parts = compareKeyword.getPhrase().toLowerCase().split(' ')

    if (parts.length === 1) {
      return false
    }

    if (parts.indexOf(word.getWord().toLowerCase()) === 0) {
      return 'phrase-first'
    }

    if (parts.indexOf(word.getWord().toLowerCase()) === parts.length - 1) {
      return 'phrase-last'
    }

    return 'phrase'
  }
}
