













































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { BCard, ListGroupPlugin, FormCheckboxPlugin, ButtonPlugin } from 'bootstrap-vue'
import CaConfig from '@infinity/ca-tester/models/caConfig'
import KeywordGroupRow from '@infinity/ca-tester/components/KeywordGroupRow.vue'
import { InfinityStatus } from '@infinity/shared/helpers/api'
import KeywordSpottingConfig from '@infinity/ca-tester/models/keywordSpottingConfig'
import { track } from '@infinity/ca-tester/utils/mixpanel'
import { PopoverEvent } from '@infinity/shared/components/layout/InfinityPopover.vue'

Vue.use(ListGroupPlugin)
Vue.use(FormCheckboxPlugin)
Vue.use(ButtonPlugin)

@Component({
  components: {
    KeywordGroupRow,
    BCard
  }
})
export default class ConfigCreate extends Vue {
  @Prop({ required: true })
  private caConfig!: CaConfig

  private search = ''
  private showDeleted = false
  private toggleAll = false
  private sortMenuOpen = false

  created () {
    if (this.$store.state.caConfig.lastBaseGroupId === 0) {
      this.toggleAll = true
    }
  }

  get types () {
    return this.$store.state.caConfig.keywordGroupTypes
  }

  addGroup () {
    this.caConfig.getKeywordSpottingConfig().push(
      new KeywordSpottingConfig()
    )
  }

  get availableConfig () {
    if (this.showDeleted === false) {
      return this.caConfig.getKeywordSpottingConfig().filter(
        (config) => {
          return config.getKeywordGroup().getStatus() === InfinityStatus.Ok
        }
      )
    }

    return this.caConfig.getKeywordSpottingConfig()
  }

  get visibleConfig () {
    if (this.search) {
      return this.availableConfig.filter(
        (config) => {
          const query = this.search.toLowerCase()

          if (config.getKeywordGroup().getName().toLowerCase().includes(query)) {
            return true
          }

          for (const keyword of config.getKeywordGroup().getKeywords()) {
            if (keyword.getPhrase().toLowerCase().includes(query)) {
              return keyword.getStatus() === InfinityStatus.Ok || this.showDeleted
            }
          }

          return false
        }
      )
    }

    return this.availableConfig
  }

  toggleShowDeleted (showDeleted: boolean) {
    track('settings.conversationAnalytics.tester.toggleDeleted', {
      showDeleted
    })
  }

  toggleDropdownMenu () {
    switch (this.sortMenuOpen) {
      case true:
        this.$root.$emit(PopoverEvent.Enable)
        break
      default:
        this.$root.$emit(PopoverEvent.Disable)
    }
    this.sortMenuOpen = !this.sortMenuOpen
  }

  onDropdownHide () {
    this.$nextTick(() => {
      this.$root.$emit(PopoverEvent.Disable)
      this.sortMenuOpen = false
    })
  }
}
