import Model, { apiToModelTransformer } from '@infinity/shared/models/base'
import { Api, JsonData } from '@infinity/shared/helpers/api'
import Transcription from '@infinity/ca-tester/models/transcription'
import { isString } from 'lodash'

export enum CallDirection { In = 'in', Out = 'out' }
export enum MatchType { Broad = 'b', Content = 'c', Exact = 'e', FixedNumber = 'f', Phrase = 'p', Unknown = 'u'}

export class TriggerCall extends Model {
  protected fieldDefinition = {
    [Api.Hub]: {
      rowId: 'rowId',
      triggerInstanceId: 'triggerInstanceId',
      triggerLogId: 'triggerLogId',
      dialledPhoneNumber: 'dialledPhoneNumber',
      srcPhoneNumber: 'srcPhoneNumber',
      destPhoneNumber: 'destPhoneNumber',
      callDuration: 'callDuration',
      bridgeDuration: 'bridgeDuration',
      callDirection: 'callDirection',
      queueDuration: 'queueDuration',
      ivrDuration: 'ivrDuration',
      triggerDatetime: 'triggerDatetime',
      operatorRef: 'operatorRef',
      segment: 'segmentId',
      segmentName: 'segmentName',
      segmentGroup: 'segmentGroupId',
      segmentGroupName: 'segmentGroupName',
      dgrp: 'trackingPoolId',
      dgrpName: 'trackingPoolName',
      dialplanRef: 'dialplanRef',
      callTranscription: 'callTranscription',
      rec: 'callRecording',
      // ca
      callConversationAnalytics: 'callConversationAnalytics',
      callKeywordSpotting: 'callKeywordSpotting',
      callScorecards: 'callScorecards',
      txr: 'callRef',
      goalTitle: 'goalTitle',
      pageTitle: 'pageTitle',
      ref: 'ref',
      href: 'pageUrl',
      pub: 'publisher',
      chName: 'channel',
      callStage: 'callStage',
      vid: 'visitorId',
      visitorFirstDatetime: 'visitorFirstSeen',
      visitorCallCount: 'visitorCallCount',
      visitorPageCount: 'visitorPageCount',
      new: 'isNew',
      campaign: 'campaign',
      network: 'network',
      src: 'source',
      matchRef: 'matchRef',
      matchType: 'matchType',
      keywordRef: 'keywordRef',
      callRating: 'callRating',
      adGroup: 'adGroup',
      adRef: 'adRef',
      contactNegativeKeywordCount: 'contactNegativeKeywordCount',
      contactNeutralKeywordCount: 'contactNeutralKeywordCount',
      contactPositiveKeywordCount: 'contactPositiveKeywordCount',
      operatorNegativeKeywordCount: 'operatorNegativeKeywordCount',
      operatorNeutralKeywordCount: 'operatorNeutralKeywordCount',
      operatorPositiveKeywordCount: 'operatorPositiveKeywordCount',
      totalContactKeywordScore: 'totalContactKeywordScore',
      totalKeywordScore: 'totalKeywordScore',
      totalOperatorKeywordScore: 'totalOperatorKeywordScore',
      fc: 'fc',
      countryCode: 'countryCode',
      location: 'location',
      lineType: 'lineType',
      srcPhoneNumberRedacted: 'srcPhoneNumberRedacted'
    }
  }

  private rowId = 0
  private triggerInstanceId = ''
  private triggerLogId = ''
  private dialledPhoneNumber = ''
  private destPhoneNumber = ''
  private callDuration = ''
  private bridgeDuration = ''
  private queueDuration = ''
  private ivrDuration = ''
  private operatorRef = ''
  private callDirection = ''
  private srcPhoneNumber = ''
  private triggerDatetime = ''
  private segmentId = 0
  private segmentName = ''
  private segmentGroupId = 0
  private segmentGroupName = ''
  private trackingPoolId = 0
  private trackingPoolName = ''
  private dialplanRef = ''
  private callTranscription = ''
  private callRecording = ''
  private callConversationAnalytics = ''
  private callScorecards = ''
  private callKeywordSpotting = ''
  private callRef = ''
  private goalTitle = ''
  private pageTitle = ''
  private ref = ''
  private pageUrl = ''
  private publisher = ''
  private channel = ''
  private callStage = ''
  private visitorId = ''
  private isNew = false
  private campaign = ''
  private network = ''
  private source = ''
  private matchRef = ''
  private matchType = ''
  private keywordRef = ''
  private passThroughTags: { [k: string]: string }[] = []
  private customTags: { [k: string]: string }[] = []
  private callRating = ''
  private adGroup = ''
  private visitorFirstSeen = ''
  private visitorCallCount = 0
  private visitorPageCount = 0
  private contactNegativeKeywordCount = 0
  private contactNeutralKeywordCount = 0
  private contactPositiveKeywordCount = 0
  private operatorNegativeKeywordCount = 0
  private operatorNeutralKeywordCount = 0
  private operatorPositiveKeywordCount = 0
  private totalContactKeywordScore = 0
  private totalKeywordScore = 0
  private totalOperatorKeywordScore = 0
  private transcriptionFileName = ''
  private transcription: Transcription | null = null
  private fc: string[] = []
  private countryCode = ''
  private location = ''
  private lineType = ''
  private srcPhoneNumberRedacted = false

  getRowId (): number {
    return this.rowId
  }

  setRowId (value: number) {
    this.rowId = value

    return this
  }

  getTriggerInstanceId (): string {
    return this.triggerInstanceId
  }

  setTriggerInstanceId (value: string) {
    this.triggerInstanceId = value

    return this
  }

  getTriggerLogId (): string {
    return this.triggerLogId
  }

  setTriggerLogId (value: string) {
    this.triggerLogId = value

    return this
  }

  getDialledPhoneNumber (): string {
    return this.dialledPhoneNumber
  }

  setDialledPhoneNumber (value: string) {
    this.dialledPhoneNumber = value

    return this
  }

  getDestPhoneNumber (): string {
    return this.destPhoneNumber
  }

  setDestPhoneNumber (value: string) {
    this.destPhoneNumber = value

    return this
  }

  getCallDuration (): string {
    return this.callDuration
  }

  setCallDuration (value: string) {
    this.callDuration = value

    return this
  }

  getBridgeDuration (): string {
    return this.bridgeDuration
  }

  setBridgeDuration (value: string) {
    this.bridgeDuration = value

    return this
  }

  getQueueDuration () {
    return this.queueDuration
  }

  setQueueDuration (value: string) {
    this.queueDuration = value

    return this
  }

  getIvrDuration () {
    return this.ivrDuration
  }

  setIvrDuration (value: string) {
    this.ivrDuration = value

    return this
  }

  getOperatorRef () {
    return this.operatorRef
  }

  setOperatorRef (value: string) {
    this.operatorRef = value

    return this
  }

  getCallDirection (): string {
    return this.callDirection
  }

  setCallDirection (value: string) {
    this.callDirection = value

    return this
  }

  getSrcPhoneNumber (): string {
    return this.srcPhoneNumber
  }

  setSrcPhoneNumber (value: string) {
    this.srcPhoneNumber = value

    return this
  }

  getTriggerDatetime (): string {
    return this.triggerDatetime
  }

  setTriggerDatetime (value: string) {
    this.triggerDatetime = value

    return this
  }

  getSegmentId (): number {
    return this.segmentId
  }

  setSegmentId (value: number) {
    this.segmentId = value

    return this
  }

  getSegmentName (): string {
    return this.segmentName
  }

  setSegmentName (value: string) {
    this.segmentName = value

    return this
  }

  getSegmentGroupId (): number {
    return this.segmentGroupId
  }

  setSegmentGroupId (value: number) {
    this.segmentGroupId = value

    return this
  }

  getSegmentGroupName (): string {
    return this.segmentGroupName
  }

  setSegmentGroupName (value: string) {
    this.segmentGroupName = value

    return this
  }

  getTrackingPoolId (): number {
    return this.trackingPoolId
  }

  setTrackingPoolId (value: number) {
    this.trackingPoolId = value

    return this
  }

  getTrackingPoolName (): string {
    return this.trackingPoolName
  }

  setTrackingPoolName (value: string) {
    this.trackingPoolName = value

    return this
  }

  getDialplanRef (): string {
    return this.dialplanRef
  }

  setDialplanRef (value: string) {
    this.dialplanRef = value

    return this
  }

  getCallTranscription (): string {
    return this.callTranscription
  }

  setCallTranscription (value: string) {
    this.callTranscription = value

    return this
  }

  get hasCallTranscription (): boolean {
    return this.callTranscription === '1'
  }

  getCallRecording (): string {
    return this.callRecording
  }

  setCallRecording (value: string) {
    this.callRecording = value

    return this
  }

  get hasCallRecording (): boolean {
    return this.callRecording === '1'
  }

  get hasKeywordSpotting (): boolean {
    return this.callKeywordSpotting === '1'
  }

  getCallRef (): string {
    return this.callRef
  }

  setCallRef (value: string) {
    this.callRef = value

    return this
  }

  getGoalTitle () {
    return this.goalTitle
  }

  setGoalTitle (value: string) {
    this.goalTitle = value

    return this
  }

  getPageTitle () {
    return this.pageTitle
  }

  setPageTitle (value: string) {
    this.pageTitle = value

    return this
  }

  getRef () {
    return this.ref
  }

  setRef (value: string) {
    this.ref = value

    return this
  }

  getPageUrl () {
    return this.pageUrl
  }

  setPageUrl (value: string) {
    this.pageUrl = value

    return this
  }

  getPublisher () {
    return this.publisher
  }

  setPublisher (value: string) {
    this.publisher = value

    return this
  }

  getChannel () {
    return this.channel
  }

  setChannel (value: string) {
    this.channel = value

    return this
  }

  getCallStage () {
    return this.callStage
  }

  setCallStage (value: string) {
    this.callStage = value

    return this
  }

  getVisitorId () {
    return this.visitorId
  }

  setVisitorId (value: string) {
    this.visitorId = value

    return this
  }

  getIsNew () {
    return this.isNew
  }

  setIsNew (value: boolean | string) {
    if (typeof value === 'string') {
      this.isNew = value === '1'

      return this
    }

    this.isNew = value

    return this
  }

  getCampaign () {
    return this.campaign
  }

  setCampaign (value: string) {
    this.campaign = value

    return this
  }

  getNetwork () {
    return this.network
  }

  setNetwork (value: string) {
    this.network = value

    return this
  }

  getSource () {
    return this.source
  }

  setSource (value: string) {
    this.source = value

    return this
  }

  getMatchRef () {
    return this.matchRef
  }

  setMatchRef (value: string) {
    this.matchRef = value

    return this
  }

  getMatchType (): MatchType | string {
    return this.matchType
  }

  setMatchType (value: MatchType) {
    this.matchType = value

    return this
  }

  getPassThroughTags () {
    return this.passThroughTags
  }

  getCustomTags () {
    return this.customTags
  }

  getKeywordRef () {
    return this.keywordRef
  }

  setKeywordRef (value: string) {
    this.keywordRef = value

    return this
  }

  getCallRating () {
    return this.callRating
  }

  setCallRating (value: string) {
    this.callRating = value

    return this
  }

  getAdGroup () {
    return this.adGroup
  }

  setAdGroup (value: string) {
    this.adGroup = value

    return this
  }

  getVisitorFirstSeen () {
    return this.visitorFirstSeen
  }

  setVisitorFirstSeen (value: string) {
    this.visitorFirstSeen = value

    return this
  }

  getVisitorCallCount () {
    return this.visitorCallCount
  }

  setVisitorCallCount (value: number) {
    this.visitorCallCount = value

    return this
  }

  getVisitorPageCount () {
    return this.visitorPageCount
  }

  setVisitorPageCount (value: number) {
    this.visitorPageCount = value

    return this
  }

  getContactNegativeKeywordCount (): number {
    return this.contactNegativeKeywordCount
  }

  setContactNegativeKeywordCount (value: number) {
    this.contactNegativeKeywordCount = value

    return this
  }

  getContactNeutralKeywordCount (): number {
    return this.contactNeutralKeywordCount
  }

  setContactNeutralKeywordCount (value: number) {
    this.contactNeutralKeywordCount = value

    return this
  }

  getContactPositiveKeywordCount (): number {
    return this.contactPositiveKeywordCount
  }

  setContactPositiveKeywordCount (value: number) {
    this.contactPositiveKeywordCount = value

    return this
  }

  getOperatorNegativeKeywordCount (): number {
    return this.operatorNegativeKeywordCount
  }

  setOperatorNegativeKeywordCount (value: number) {
    this.operatorNegativeKeywordCount = value

    return this
  }

  getOperatorNeutralKeywordCount (): number {
    return this.operatorNeutralKeywordCount
  }

  setOperatorNeutralKeywordCount (value: number) {
    this.operatorNeutralKeywordCount = value

    return this
  }

  getOperatorPositiveKeywordCount (): number {
    return this.operatorPositiveKeywordCount
  }

  setOperatorPositiveKeywordCount (value: number) {
    this.operatorPositiveKeywordCount = value

    return this
  }

  getTotalContactKeywordScore (): number {
    return this.totalContactKeywordScore
  }

  setTotalContactKeywordScore (value: number) {
    this.totalContactKeywordScore = value

    return this
  }

  getTotalKeywordScore (): number {
    return this.totalKeywordScore
  }

  setTotalKeywordScore (value: number) {
    this.totalKeywordScore = value

    return this
  }

  getTotalOperatorKeywordScore (): number {
    return this.totalOperatorKeywordScore
  }

  setTotalOperatorKeywordScore (value: number) {
    this.totalOperatorKeywordScore = value

    return this
  }

  getTranscriptionFileName () {
    return this.transcriptionFileName
  }

  setTranscriptionFileName (value: string) {
    this.transcriptionFileName = value

    return this
  }

  getTranscription (): Transcription | null {
    return this.transcription
  }

  setTranscription (value: Transcription) {
    this.transcription = value

    return this
  }

  getFc (): string[] {
    return this.fc
  }

  setFc (value: string[]) {
    this.fc = value

    return this
  }

  get canDownloadTranscript (): boolean {
    return this.fc.includes('transcriptDownload')
  }

  getCountryCode (): string {
    return this.countryCode
  }

  setCountryCode (value: string) {
    this.countryCode = value

    return this
  }

  getLocation (): string {
    return this.location
  }

  setLocation (value: string) {
    this.location = value

    return this
  }

  getLineType (): string {
    return this.lineType
  }

  setLineType (value: string) {
    this.lineType = value

    return this
  }

  getSrcPhoneNumberRedacted (): boolean {
    return this.srcPhoneNumberRedacted
  }

  setSrcPhoneNumberRedacted (value: boolean | string) {
    if (typeof value === 'string') {
      this.srcPhoneNumberRedacted = value === '1'

      return this
    }

    this.srcPhoneNumberRedacted = value

    return this
  }

  getCallConversationAnalytics (): string {
    return this.callConversationAnalytics
  }

  setCallConversationAnalytics (value: string) {
    this.callConversationAnalytics = value

    return this
  }

  getCallScorecards (): string {
    return this.callScorecards
  }

  setCallScorecards (value: string) {
    this.callScorecards = value

    return this
  }

  getCallKeywordSpotting (): string {
    return this.callKeywordSpotting
  }

  setCallKeywordSpotting (value: string) {
    this.callKeywordSpotting = value

    return this
  }

  fromApiTransformer (data: JsonData, api: Api): TriggerCall {
    apiToModelTransformer(this, data, this.fieldDefinition[api])

    for (const key in data) {
      const value = data[key]
      if (data[key] && isString(value)) {
        if (key.startsWith('pt_')) {
          this.passThroughTags.push({ [key]: value })
        }

        if (key.startsWith('c_')) {
          this.customTags.push({ [key]: value })
        }
      }
    }

    return this
  }

  toApiTransformer (): JsonData | undefined {
    return undefined
  }
}
