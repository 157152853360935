import Model, { ApiFieldDefinition, apiToModelTransformer, modelToApiTransformer } from '@infinity/shared/models/base'
import { Api, JsonData } from '@infinity/shared/helpers/api'
import { KeywordLeg } from '@infinity/ca-tester/models/keywordConfig'

export default class KeywordCriteriaFirstSpokenBy extends Model {
  protected fieldDefinition: ApiFieldDefinition = {
    [Api.CaService]: {
      leg: 'leg'
    }
  }

  private leg = KeywordLeg.Operator

  getLeg (): KeywordLeg {
    return this.leg
  }

  setLeg (value: KeywordLeg) {
    this.leg = value

    return this
  }

  fromApiTransformer (data: JsonData, api: Api): KeywordCriteriaFirstSpokenBy {
    apiToModelTransformer(this, data, this.fieldDefinition[api])

    return this
  }

  toApiTransformer (api: Api): JsonData {
    return modelToApiTransformer(this, this.fieldDefinition[api])
  }
}
