var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-list-group-item",
    { staticClass: "d-flex py-3 flex-wrap align-items-center" },
    [
      _vm._t("default"),
      _vm.isEditing
        ? [
            _c(
              "div",
              {
                staticClass:
                  "w-100 d-flex align-items-center flex-sm-wrap flex-md-wrap"
              },
              [
                _c("b-form-radio-group", {
                  attrs: {
                    checked: _vm.keywordCriteriaCopy.getSpotting(),
                    options: [
                      { text: "Spot", value: true },
                      { text: "Do not spot", value: false }
                    ],
                    "button-variant": "outline-primary",
                    buttons: ""
                  },
                  on: {
                    input: function($event) {
                      return _vm.onSpottingChange($event)
                    }
                  }
                }),
                _c("span", { staticClass: "mx-2" }, [
                  _vm._v("when this keyword is spoken")
                ]),
                _c("b-form-radio-group", {
                  attrs: {
                    checked: _vm.keywordCriteriaCopy.getType(),
                    options: [
                      { text: "First by", value: "firstSpokenBy" },
                      { text: "In proximity of", value: "proximityTime" },
                      { text: "Within the", value: "timeframe" }
                    ],
                    "button-variant": "outline-primary",
                    buttons: ""
                  },
                  on: {
                    input: function($event) {
                      return _vm.keywordCriteriaCopy.setType($event)
                    }
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass:
                  "d-flex align-items-center flex-sm-wrap flex-md-wrap"
              },
              [
                _vm.keywordCriteriaCopy.getType() === "firstSpokenBy"
                  ? [
                      _c("span", { staticClass: "mr-3 mt-3" }, [_vm._v("the")]),
                      _c("b-form-radio-group", {
                        staticClass: "mt-3",
                        attrs: {
                          checked: _vm.firstSpokenConfig.getLeg(),
                          options: [
                            { text: "Caller", value: "contact" },
                            { text: "Agent", value: "operator" }
                          ],
                          "button-variant": "outline-primary",
                          buttons: ""
                        },
                        on: {
                          input: function($event) {
                            return _vm.firstSpokenConfig.setLeg($event)
                          }
                        }
                      })
                    ]
                  : _vm.keywordCriteriaCopy.getType() === "proximityTime"
                  ? [
                      _c("b-form-input", {
                        staticClass: "mt-3 proximity-word",
                        attrs: {
                          value: _vm.proximityConfig.getWord(),
                          placeholder: "Word",
                          state: _vm.isConfigValid.word
                        },
                        on: {
                          input: function($event) {
                            return _vm.proximityConfig.setWord($event)
                          }
                        }
                      }),
                      _c("span", { staticClass: "mt-3 mx-2" }, [
                        _vm._v("spoken by")
                      ]),
                      _c("b-form-radio-group", {
                        staticClass: "mt-3",
                        attrs: {
                          checked: _vm.proximityConfig.getLeg(),
                          options: [
                            { text: "The caller", value: "contact" },
                            { text: "The agent", value: "operator" },
                            { text: "Either party", value: "all" }
                          ],
                          "button-variant": "outline-primary",
                          buttons: ""
                        },
                        on: {
                          input: function($event) {
                            return _vm.proximityConfig.setLeg($event)
                          }
                        }
                      }),
                      _c("span", { staticClass: "mt-3 mx-2" }, [
                        _vm._v("within")
                      ]),
                      _c("b-form-input", {
                        staticClass: "mt-3 proximity-seconds",
                        attrs: {
                          value: _vm.proximityConfig.getWithinSeconds(),
                          formatter: parseInt,
                          min: "0",
                          type: "number",
                          state: _vm.isConfigValid.withinSeconds,
                          "no-wheel": ""
                        },
                        on: {
                          input: function($event) {
                            return _vm.proximityConfig.setWithinSeconds($event)
                          }
                        }
                      }),
                      _c("span", { staticClass: "mt-3 mx-2" }, [
                        _vm._v("seconds")
                      ]),
                      _c("b-form-radio-group", {
                        staticClass: "mt-3",
                        attrs: {
                          checked: _vm.proximityConfig.getDirection(),
                          options: [
                            { text: "Before", value: "before" },
                            { text: "After", value: "after" },
                            { text: "In any direction of", value: "any" }
                          ],
                          "button-variant": "outline-primary",
                          buttons: ""
                        },
                        on: {
                          input: function($event) {
                            return _vm.proximityConfig.setDirection($event)
                          }
                        }
                      }),
                      _c("span", { staticClass: "mt-3 mx-2" }, [
                        _vm._v("the keyword.")
                      ])
                    ]
                  : _vm.keywordCriteriaCopy.getType() === "timeframe"
                  ? [
                      _c("b-form-radio-group", {
                        staticClass: "mt-3",
                        attrs: {
                          checked: _vm.timeframeConfig.getPlace(),
                          options: [
                            { text: "First", value: "first" },
                            { text: "Last", value: "last" }
                          ],
                          "button-variant": "outline-primary",
                          buttons: ""
                        },
                        on: {
                          input: function($event) {
                            return _vm.timeframeConfig.setPlace($event)
                          }
                        }
                      }),
                      _c("b-form-input", {
                        staticClass: "mt-3 mx-2 timeframe-duration",
                        attrs: {
                          value: _vm.timeframeConfig.getDuration(),
                          formatter: parseInt,
                          min: "0",
                          type: "number",
                          state: _vm.isConfigValid.duration
                        },
                        on: {
                          input: function($event) {
                            return _vm.timeframeConfig.setDuration($event)
                          }
                        }
                      }),
                      _c("b-form-radio-group", {
                        staticClass: "mt-3",
                        attrs: {
                          checked: _vm.timeframeConfig.getDimension(),
                          options: [
                            { text: "Seconds", value: "seconds" },
                            { text: "Percent", value: "percent" }
                          ],
                          "button-variant": "outline-primary",
                          buttons: ""
                        },
                        on: {
                          input: function($event) {
                            return _vm.timeframeConfig.setDimension($event)
                          }
                        }
                      }),
                      _c("span", { staticClass: "mt-3 ml-3" }, [
                        _vm._v("of the call")
                      ])
                    ]
                  : _vm._e()
              ],
              2
            )
          ]
        : [
            _c(
              "div",
              [
                _vm.keywordCriteriaCopy.getType() === "firstSpokenBy"
                  ? _c("span", { staticClass: "text-whale" }, [
                      _vm._v("First by")
                    ])
                  : _vm.keywordCriteriaCopy.getType() === "proximityTime"
                  ? _c("span", { staticClass: "text-whale" }, [
                      _vm._v("In proximity of")
                    ])
                  : _vm.keywordCriteriaCopy.getType() === "timeframe"
                  ? _c("span", { staticClass: "text-whale" }, [
                      _vm._v("Within the")
                    ])
                  : _vm._e(),
                _vm.keywordCriteriaCopy.getType() === "firstSpokenBy"
                  ? [
                      _c("span", { staticClass: "mx-2" }, [_vm._v("the")]),
                      _c("span", { staticClass: "text-whale" }, [
                        _vm._v(
                          _vm._s(
                            _vm.firstSpokenConfig.getLeg() === "contact"
                              ? "Caller"
                              : "Agent"
                          )
                        )
                      ])
                    ]
                  : _vm.keywordCriteriaCopy.getType() === "proximityTime"
                  ? [
                      _c("span", { staticClass: "mx-2 text-whale" }, [
                        _vm._v(_vm._s(_vm.proximityConfig.getWord()))
                      ]),
                      _c("span", { staticClass: "mr-2" }, [
                        _vm._v("spoken by")
                      ]),
                      _vm.proximityConfig.getLeg() === "contact"
                        ? _c("span", { staticClass: "text-whale" }, [
                            _vm._v("The caller")
                          ])
                        : _vm.proximityConfig.getLeg() === "operator"
                        ? _c("span", { staticClass: "text-whale" }, [
                            _vm._v("The agent")
                          ])
                        : _vm.proximityConfig.getLeg() === "all"
                        ? _c("span", { staticClass: "text-whale" }, [
                            _vm._v("Either party")
                          ])
                        : _vm._e(),
                      _c("span", { staticClass: "mx-2" }, [_vm._v("within")]),
                      _c("span", { staticClass: "text-whale" }, [
                        _vm._v(_vm._s(_vm.proximityConfig.getWithinSeconds()))
                      ]),
                      _c("span", { staticClass: "mx-2" }, [_vm._v("seconds")]),
                      _vm.proximityConfig.getDirection() === "before"
                        ? _c("span", { staticClass: "text-whale" }, [
                            _vm._v("Before")
                          ])
                        : _vm.proximityConfig.getDirection() === "after"
                        ? _c("span", { staticClass: "text-whale" }, [
                            _vm._v("After")
                          ])
                        : _vm.proximityConfig.getDirection() === "any"
                        ? _c("span", { staticClass: "text-whale" }, [
                            _vm._v("In any direction of")
                          ])
                        : _vm._e(),
                      _c("span", { staticClass: "mx-2" }, [
                        _vm._v("the keyword.")
                      ])
                    ]
                  : _vm.keywordCriteriaCopy.getType() === "timeframe"
                  ? [
                      _c("span", { staticClass: "text-whale mx-2" }, [
                        _vm._v(
                          _vm._s(
                            _vm.timeframeConfig.getPlace() === "first"
                              ? "First"
                              : "Last"
                          )
                        )
                      ]),
                      _c("span", { staticClass: "text-whale" }, [
                        _vm._v(_vm._s(_vm.timeframeConfig.getDuration()))
                      ]),
                      _c("span", { staticClass: "text-whale mx-2" }, [
                        _vm._v(
                          _vm._s(
                            _vm.timeframeConfig.getDimension() === "seconds"
                              ? "Seconds"
                              : "Percent"
                          )
                        )
                      ]),
                      _c("span", [_vm._v("of the call")])
                    ]
                  : _vm._e()
              ],
              2
            )
          ],
      _vm.isEditing
        ? _c(
            "div",
            { staticClass: "ml-auto mt-3" },
            [
              _c(
                "b-button",
                { attrs: { variant: "outline" }, on: { click: _vm.doCancel } },
                [_vm._v("Cancel")]
              ),
              _c(
                "b-button",
                {
                  attrs: {
                    disabled: !_vm.isAllConfigValid,
                    variant: "outline-success"
                  },
                  on: { click: _vm.doSave }
                },
                [_c("b-icon-check"), _vm._v(" Done")],
                1
              )
            ],
            1
          )
        : _c(
            "div",
            { staticClass: "ml-auto" },
            [
              _c(
                "b-button",
                {
                  directives: [
                    {
                      name: "infinity-tooltip",
                      rawName: "v-infinity-tooltip",
                      value: "Edit Criteria",
                      expression: "'Edit Criteria'"
                    }
                  ],
                  attrs: { size: "sm", variant: "outline" },
                  on: {
                    click: function($event) {
                      _vm.isEditing = true
                    }
                  }
                },
                [_c("b-icon-pencil-fill")],
                1
              ),
              _c(
                "b-button",
                {
                  directives: [
                    {
                      name: "infinity-tooltip",
                      rawName: "v-infinity-tooltip",
                      value: "Remove Criteria",
                      expression: "'Remove Criteria'"
                    }
                  ],
                  attrs: { size: "sm", variant: "outline" },
                  on: { click: _vm.doDelete }
                },
                [_c("b-icon-x-circle-fill")],
                1
              )
            ],
            1
          )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }