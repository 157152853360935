import Model, { ApiFieldDefinition, apiToModelTransformer, modelToApiTransformer } from '@infinity/shared/models/base'
import { Api, JsonData } from '@infinity/shared/helpers/api'
import { KeywordLeg } from '@infinity/ca-tester/models/keywordConfig'

export enum ProximityDirection { Any = 'any', Before = 'before', After = 'after' }

export default class KeywordCriteriaProximity extends Model {
  protected fieldDefinition: ApiFieldDefinition = {
    [Api.CaService]: {
      word: 'word',
      leg: 'leg',
      withinSeconds: 'withinSeconds',
      direction: 'direction'
    }
  }

  private direction = ProximityDirection.Any
  private word = ''
  private leg = KeywordLeg.All
  private withinSeconds = 0

  getDirection (): ProximityDirection {
    return this.direction
  }

  setDirection (value: ProximityDirection) {
    this.direction = value

    return this
  }

  getWord (): string {
    return this.word
  }

  setWord (value: string) {
    this.word = value.toLowerCase()

    return this
  }

  getLeg (): KeywordLeg {
    return this.leg
  }

  setLeg (value: KeywordLeg) {
    this.leg = value

    return this
  }

  getWithinSeconds (): number {
    return this.withinSeconds
  }

  setWithinSeconds (value: number) {
    this.withinSeconds = value

    return this
  }

  fromApiTransformer (data: JsonData, api: Api): KeywordCriteriaProximity {
    apiToModelTransformer(this, data, this.fieldDefinition[api])

    return this
  }

  toApiTransformer (api: Api): JsonData {
    return modelToApiTransformer(this, this.fieldDefinition[api])
  }
}
