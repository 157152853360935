var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "call-list" },
    [
      _c("filter-bar", {
        attrs: {
          "installation-id": _vm.installationId,
          "installation-time-zone": _vm.installationTimeZone,
          "filter-value": JSON.stringify(_vm.filterParams)
        },
        on: { apply: _vm.applyFilters }
      }),
      _c(
        "div",
        { staticClass: "d-flex justify-content-between align-items-center" },
        [
          _c(
            "div",
            { staticClass: "my-3 w-100" },
            [
              _c("b-pagination", {
                staticClass: "my-0",
                attrs: {
                  "total-rows": _vm.totalRows,
                  "per-page": _vm.limit,
                  "hide-goto-end-buttons": true,
                  align: "right",
                  pills: ""
                },
                on: { "page-click": _vm.doChangePage },
                model: {
                  value: _vm.page,
                  callback: function($$v) {
                    _vm.page = $$v
                  },
                  expression: "page"
                }
              })
            ],
            1
          )
        ]
      ),
      _c(
        "b-overlay",
        { attrs: { show: _vm.isLoading, rounded: "sm" } },
        [
          _c(
            "transition-group",
            {
              attrs: {
                name: _vm.listAnimation.name,
                "enter-active-class": _vm.listAnimation.enter,
                "leave-active-class": _vm.listAnimation.leave,
                mode: "out-in"
              }
            },
            _vm._l(_vm.calls, function(call) {
              return _c("call-row", {
                directives: [
                  {
                    name: "test",
                    rawName: "v-test",
                    value: "call row " + call.getRowId(),
                    expression: "`call row ${call.getRowId()}`"
                  }
                ],
                key: call.getRowId(),
                staticClass: "mb-2",
                attrs: { call: call },
                on: { select: _vm.onClickRow }
              })
            }),
            1
          ),
          _vm.isLoading && !_vm.calls.length
            ? _c("b-skeleton-table", {
                directives: [
                  {
                    name: "test",
                    rawName: "v-test",
                    value: "loading skeleton",
                    expression: "'loading skeleton'"
                  }
                ],
                attrs: { rows: 20, columns: 1 }
              })
            : _vm._e()
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "flex-row-reverse" },
        [
          _c(
            "b-col",
            { staticClass: "my-3", attrs: { cols: "12", md: "6" } },
            [
              _c("b-pagination", {
                staticClass: "my-0",
                attrs: {
                  "total-rows": _vm.totalRows,
                  "per-page": _vm.limit,
                  "hide-goto-end-buttons": true,
                  align: "right",
                  pills: ""
                },
                on: { "page-click": _vm.doChangePage },
                model: {
                  value: _vm.page,
                  callback: function($$v) {
                    _vm.page = $$v
                  },
                  expression: "page"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }