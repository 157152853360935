export enum FilterOperand {
  Equals = 'eq',
  EqualsI = 'eqi',
  NotEquals = 'ne',
  NotEqualsI = 'nei',
  LessThan = 'lt',
  GreaterThan = 'gt',
  LessThanEquals = 'le',
  GreaterThanEquals = 'ge',
  Includes = 'inc',
  IncludesI = 'inci',
  NotIncludes = 'ninc',
  NotIncludesI = 'ninci',
  Regexp = 're',
  Begins = 'begins',
  BeginsI = 'beginsi',
  In = 'in',
  NotIn = 'nin',
  InI = 'ini',
  NotInI = 'nini',
  IncludesList = 'inclist',
  NIncludesList = 'ninclist'
}

export class Filter {
  readonly field: string | undefined
  readonly operand: FilterOperand | undefined
  readonly value: string | number | string[] | number[] | undefined

  constructor (field: string, operand: FilterOperand, value: string | number | string[] | number[]) {
    this.field = field
    this.operand = operand
    this.value = value
  }

  public toApi (): string {
    let value = this.value

    if (Array.isArray(value)) {
      value = value.join(',')
    }

    return `${this.field}-${this.operand}-value-${value}`
  }
}

export class FilterCollection {
  private filters: Filter[] = []

  constructor (...args: Array<Filter|undefined>) {
    for (const filter of args) {
      if (filter !== undefined) {
        this.filters.push(filter)
      }
    }
  }

  public toApi (): string[] {
    return this.filters
      .map(
        (filter) => {
          return filter.toApi()
        }
      )
  }

  public addFilter (filter: Filter): FilterCollection {
    this.filters.push(filter)

    return this
  }

  /**
   * Removes a filter from a collection. Returns false if filter did not exist
   * @param filter
   */
  public removeFilter (filter: Filter): FilterCollection | false {
    const index = this.filters.indexOf(filter)

    if (index > -1) {
      this.filters.splice(index, 1)

      return this
    }

    return false
  }
}
