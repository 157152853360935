import { Commit, Dispatch } from 'vuex'
import KeywordGroupTypeConfig from '@infinity/ca-tester/models/keywordGroupTypeConfig'
import CaConfig from '@infinity/ca-tester/models/caConfig'
import KeywordSpottingConfigRepo from '@infinity/ca-tester/repos/keywordSpottingConfig'
import DraftCaConfigRepo from '@infinity/ca-tester/repos/draftCaConfig'
import KeywordSpottingConfig from '@infinity/ca-tester/models/keywordSpottingConfig'
import { Api } from '@infinity/shared/helpers/api'
import KeywordGroupTypeRepo from '@infinity/ca-tester/repos/keywordGroupType'
import { isEqual } from 'lodash'
import KeywordGroupConfig from '@infinity/ca-tester/models/keywordGroupConfig'
import axios from 'axios'
import { AuthUtil } from '@infinity/shared/utils/auth'

export type ThreePartId = {
  instanceId: number;
  instanceLogId: number;
  callDatetime: string;
}

export type ReprocessResultDateRange = { start: Date; end: Date }

export type ReprocessResultMetrics = {
  count: number;
  failCount: number;
  runningCount: number;
  successCount: number;
  executionDtRange: ReprocessResultDateRange;
}

export type ReprocessResult = {
  id: string;
  configHash: string;
  status: string;
  callIds: string[] | null;
  dtRange: ReprocessResultDateRange;
  metrics: ReprocessResultMetrics;
}

export function enrichKeywordGroupTypes (caConfig: CaConfig, types: KeywordGroupTypeConfig[]) {
  for (const config of caConfig.getKeywordSpottingConfig()) {
    const type = types.find(
      (typeConfig) => {
        return typeConfig.getId() === config.getKeywordGroup().getType().getId()
      }
    )

    if (type) {
      config.getKeywordGroup().setType(type)
    }
  }
}

export function zeroSoftIds (caConfig: CaConfig) {
  // Sets soft IDs to 0 from groups and keywords, these will be removed by the transformer
  for (const config of caConfig.getKeywordSpottingConfig()) {
    if (config.getKeywordGroup().getIsNew()) {
      config.getKeywordGroup().setId(0)
    }

    for (const keyword of config.getKeywordGroup().getKeywords()) {
      if (keyword.getIsNew()) {
        keyword.setId(0)
      }
    }
  }
}

export function addSoftIds (caConfig: CaConfig, groupId: number, keywordId: number) {
  for (const config of caConfig.getKeywordSpottingConfig()) {
    if (config.getKeywordGroup().getId() === 0) {
      groupId += 1
      config.getKeywordGroup().setId(groupId)
    }

    for (const keyword of config.getKeywordGroup().getKeywords()) {
      if (keyword.getId() === 0) {
        keywordId += 1
        keyword.setId(keywordId)
      }
    }
  }

  return { groupId, keywordId }
}

export function getLastCompareIds (config: KeywordSpottingConfig[], caConfig: CaConfig) {
  if (config.length) {
    const sortedGroups = config.sort(
      (a, b) => {
        return a.getKeywordGroup().getId() - b.getKeywordGroup().getId()
      }
    )

    const sortedKeywords = caConfig.getAllKeywordsConfig().sort(
      (a, b) => {
        return a.getId() - b.getId()
      }
    )

    return {
      groupId: sortedGroups[sortedGroups.length - 1].getKeywordGroup().getId(),
      keywordId: sortedKeywords[sortedKeywords.length - 1].getId()
    }
  }
}

/**
 * Takes a base and compare CA Config, returning a new Config containing only the changes between the two.
 *
 * @param baseConfig
 * @param compareConfig
 */
export function getChangedConfig (baseConfig: CaConfig, compareConfig: CaConfig) {
  const changedConfig = new CaConfig()
    .setId(compareConfig.getId())
    .setInstallationId(compareConfig.getInstallationId())

  for (const keywordGroup of compareConfig.getKeywordGroups()) {
    const baseGroup = baseConfig.getKeywordGroupConfig(keywordGroup.getId())
    const changedGroup = new KeywordGroupConfig()
      .fromApiTransformer(keywordGroup.toApiTransformer(Api.CaService), Api.CaService)
      .setIsNew(!baseGroup)

    for (const keyword of keywordGroup.getKeywords()) {
      const baseKeyword = baseGroup?.getKeyword(keyword.getId())

      // API data gives a better comparison
      if (!isEqual(baseKeyword?.toApiTransformer(Api.CaService), keyword.toApiTransformer(Api.CaService))) {
        changedGroup.addKeyword(keyword)
      }
    }

    if (
      !isEqual(baseGroup?.toApiTransformer(Api.CaService), keywordGroup.toApiTransformer(Api.CaService)) ||
      changedGroup.getKeywords().length > 0
    ) {
      changedConfig.getKeywordSpottingConfig().push(
        new KeywordSpottingConfig()
          .setKeywordGroup(changedGroup)
      )
    }
  }

  return changedConfig
}

export class CaConfigState {
  baseConfig = new CaConfig()
  compareConfig = new CaConfig()
  reprocessConfigHash: string | null = null
  threePartId: ThreePartId = {
    instanceId: 0,
    instanceLogId: 0,
    callDatetime: ''
  }

  lastBaseGroupId = 0
  lastBaseKeywordId = 0
  lastCompareGroupId = 0
  lastCompareKeywordId = 0
  keywordGroupTypes: KeywordGroupTypeConfig[] = []
  hasDraft = false
  hasBaseConfig = false
  isCreatingKeyword = false
}

export default {
  namespaced: true,
  state: new CaConfigState(),
  getters: {
    changedConfig: (state: CaConfigState) => {
      return getChangedConfig(state.baseConfig, state.compareConfig)
    }
  },
  mutations: {
    setBaseConfig (state: CaConfigState, config: CaConfig) {
      state.baseConfig = config
    },
    setBaseKeywordConfig (state: CaConfigState, keywordConfig: KeywordSpottingConfig[]) {
      if (state.baseConfig) {
        state.baseConfig.setKeywordSpottingConfig(keywordConfig)
      }
    },
    setCompareConfig (state: CaConfigState, config: CaConfig) {
      state.compareConfig = config
    },
    setCompareKeywordConfig (state: CaConfigState, keywordConfig: KeywordSpottingConfig[]) {
      if (state.compareConfig) {
        state.compareConfig.setKeywordSpottingConfig(keywordConfig)
      }
    },
    setLastBaseGroupId (state: CaConfigState, id: number) {
      state.lastBaseGroupId = id
    },
    setLastBaseKeywordId (state: CaConfigState, id: number) {
      state.lastBaseKeywordId = id
    },
    setLastCompareGroupId (state: CaConfigState, id: number) {
      state.lastCompareGroupId = id
    },
    setLastCompareKeywordId (state: CaConfigState, id: number) {
      state.lastCompareKeywordId = id
    },
    setKeywordGroupTypes (state: CaConfigState, types: KeywordGroupTypeConfig[]) {
      state.keywordGroupTypes = types
    },
    setHasBaseConfig (state: CaConfigState, value: boolean) {
      state.hasBaseConfig = value
    },
    setHasDraft (state: CaConfigState, value: boolean) {
      state.hasDraft = value
    },
    setIsCreatingKeyword (state: CaConfigState, value: boolean) {
      state.isCreatingKeyword = value
    },
    setReprocessConfigHash (state: CaConfigState, hash: string) {
      state.reprocessConfigHash = hash
    },
    setThreePartCallId (state: CaConfigState, id: ThreePartId) {
      state.threePartId = id
    }
  },
  actions: {
    async loadBaseConfig ({ commit, state, dispatch }: { commit: Commit; state: CaConfigState; dispatch: Dispatch }) {
      const configRepo = new KeywordSpottingConfigRepo()
      const caConfig = new CaConfig()
      const config = await configRepo.fetch()
      caConfig.setKeywordSpottingConfig(config)

      if (!state.keywordGroupTypes.length) {
        await dispatch('loadKeywordGroupTypes')
      }

      enrichKeywordGroupTypes(caConfig, state.keywordGroupTypes)

      if (config.length) {
        const sortedGroups = config.sort(
          (a, b) => {
            return a.getKeywordGroup().getId() - b.getKeywordGroup().getId()
          }
        )

        commit('setLastBaseGroupId', sortedGroups[sortedGroups.length - 1].getKeywordGroup().getId())
        commit('setLastCompareGroupId', sortedGroups[sortedGroups.length - 1].getKeywordGroup().getId())

        const sortedKeywords = caConfig.getAllKeywordsConfig().sort(
          (a, b) => {
            return a.getId() - b.getId()
          }
        )

        commit('setLastBaseKeywordId', sortedKeywords[sortedKeywords.length - 1].getId())
        commit('setLastCompareKeywordId', sortedKeywords[sortedKeywords.length - 1].getId())
      }

      commit('setBaseConfig', caConfig)
      commit('setHasBaseConfig', true)
    },
    async loadDraftConfig ({ commit, state, dispatch }: { commit: Commit; state: CaConfigState; dispatch: Dispatch }) {
      commit('setHasDraft', false)

      const caConfig = new CaConfig()
      const draftRepo = new DraftCaConfigRepo()
      const draft = await draftRepo.fetchDraftKwsConfig()

      if (draft) {
        const config = draft.getKeywordSpottingConfig()
        caConfig.setKeywordSpottingConfig(config)

        if (!state.keywordGroupTypes.length) {
          await dispatch('loadKeywordGroupTypes')
        }

        enrichKeywordGroupTypes(caConfig, state.keywordGroupTypes)

        let lastCompareIds = getLastCompareIds(config, caConfig)
        if (lastCompareIds) {
          lastCompareIds = addSoftIds(caConfig, lastCompareIds.groupId, lastCompareIds.keywordId)

          commit('setLastCompareGroupId', lastCompareIds.groupId)
          commit('setLastCompareKeywordId', lastCompareIds.keywordId)
        }

        commit('setHasDraft', true)
      }
    },
    createCompareConfig ({ commit, state }: { commit: Commit; state: CaConfigState }) {
      if (state.baseConfig) {
        const caConfig = new CaConfig()

        const config: KeywordSpottingConfig[] = []

        for (const baseConfig of state.baseConfig.getKeywordSpottingConfig()) {
          config.push(
            new KeywordSpottingConfig()
              .fromApiTransformer(baseConfig.toApiTransformer(Api.CaService), Api.CaService)
          )
        }

        caConfig.setKeywordSpottingConfig(config)

        const lastCompareIds = getLastCompareIds(config, caConfig)
        if (lastCompareIds) {
          commit('setLastCompareGroupId', lastCompareIds.groupId)
          commit('setLastCompareKeywordId', lastCompareIds.keywordId)
        }

        caConfig.getKeywordSpottingConfig().sort((a: KeywordSpottingConfig, b: KeywordSpottingConfig) => a.getKeywordGroup().getName().localeCompare(b.getKeywordGroup().getName()))

        commit('setHasDraft', false)
        commit('setCompareConfig', caConfig)
      }
    },
    async loadKeywordGroupTypes ({ commit }: { commit: Commit }) {
      const typesRepo = new KeywordGroupTypeRepo()
      const types = await typesRepo.fetch()

      commit('setKeywordGroupTypes', types)
    },
    async saveConfig ({ state, commit, getters }: { state: CaConfigState; commit: Commit; getters: { changedConfig: CaConfig } }) {
      const changedConfig = getters.changedConfig
      zeroSoftIds(changedConfig)

      const configRepo = new KeywordSpottingConfigRepo()
      if (changedConfig.getKeywordSpottingConfig().length) {
        await configRepo.postBulk(changedConfig.getKeywordSpottingConfig())
      }

      if (state.hasDraft) {
        const draftRepo = new DraftCaConfigRepo()
        await draftRepo.deleteDraftKwsConfig()

        commit('setHasDraft', false)
      }
    },
    async saveDraft ({ state }: { state: CaConfigState }) {
      zeroSoftIds(state.compareConfig)

      const draftRepo = new DraftCaConfigRepo()
      await draftRepo.saveDraftKwsConfig(state.compareConfig.getDraftConfig())
    },
    async setConfigHash ({ commit }: { commit: Commit }, triggerDatetime: Date) {
      const { data: { data: { jobs } } } = await axios.get<Record<'data', Record<'jobs', Array<ReprocessResult>>>>(
        process.env.VUE_APP_CA_INSIGHTS_SERVICE_API_URI + `/installation/${AuthUtil.installationId}/resultsJob`,
        {
          headers: {
            Authorization: 'Token ' + AuthUtil.accessToken
          }
        })

      for (const job of jobs) {
        if (job.dtRange.start < triggerDatetime && job.dtRange.end > triggerDatetime) {
          commit('setReprocessConfigHash', job.configHash)
          break
        }
      }
    },
    async setThreePartId ({ commit }: { commit: Commit }, txr: string) {
      const { data: { callTriggers } } = await axios.get(
        Api.Hub + `/igrps/${AuthUtil.installationId}/triggers/calls`,
        {
          headers: {
            'X-Auth-Token': AuthUtil.accessToken
          },
          params: {
            tz: 'UTC',
            limit: 1,
            filters: [
              `txr-eq-value-${txr}`,
              'includeThreePartId-eq-value-1'
            ]
          }
        })
      if (callTriggers.length) {
        commit('setThreePartCallId', {
          instanceId: callTriggers[0].triggerInstanceId,
          instanceLogId: callTriggers[0].triggerLogId,
          callDatetime: callTriggers[0].triggerDatetime
        })
      }
    },
    async loadS2Config ({ state, commit }: { state: CaConfigState; commit: Commit }, callId: number) {
      const configRepo = new KeywordSpottingConfigRepo()
      const caConfig = new CaConfig()
      const config = await configRepo.fetch()
      caConfig.setKeywordSpottingConfig(config)

      const { data: { data } } = await axios.get(
        Api.CaProviderVbService + `/keywordSpottingV0/installation/${AuthUtil.installationId}/call/${callId}`,
        {
          headers: {
            Authorization: `Token ${AuthUtil.accessToken}`
          },
          params: {
            configHash: state.reprocessConfigHash,
            instanceId: state.threePartId.instanceId,
            instanceLogId: state.threePartId.instanceLogId,
            callDatetime: state.threePartId.callDatetime
          }
        })

      caConfig.fromApiTransformer(data, Api.CaTestingService)

      commit('setBaseConfig', caConfig)
    }
  }
}
