import Model, { apiToModelTransformer } from '@infinity/shared/models/base'
import { Api, JsonData } from '@infinity/shared/helpers/api'
import KeywordConfig from '@infinity/ca-tester/models/keywordConfig'

export default class SpottedKeyword extends Model {
  protected fieldDefinition = {
    [Api.CaTestingService]: {
      keywordId: 'keywordId',
      startTime: 'startTime',
      confidence: 'confidence',
      keywordScore: 'score',
      metConfidenceLevel: 'metConfidenceLevel',
      keywordGroupId: 'keywordGroupId'
    },
    [Api.CaProviderVbService]: {
      keywordId: 'keywordId',
      startTime: 'startTime',
      confidence: 'confidence',
      keywordScore: 'score',
      metConfidenceLevel: 'metConfidenceLevel',
      keywordGroupId: 'keywordGroupId'
    }
  }

  private keywordId = 0
  private keywordConfig: Model | KeywordConfig = new KeywordConfig()
  private startTime = 0
  private confidence = 0
  private score = 0
  private metConfidenceLevel = false
  private keywordGroupId = 0

  getKeywordId (): number {
    return this.keywordId
  }

  setKeywordId (value: number) {
    this.keywordId = value
  }

  getKeywordConfig (): Model | KeywordConfig {
    return this.keywordConfig
  }

  setKeywordConfig (value: Model | KeywordConfig) {
    this.keywordConfig = value
  }

  getPhrase () {
    return this.keywordConfig.getPhrase()
  }

  getStartTime (): number {
    return this.startTime
  }

  setStartTime (value: number) {
    this.startTime = value
  }

  getConfidence (): number {
    return this.confidence
  }

  setConfidence (value: number) {
    this.confidence = value
  }

  getScore (): number {
    return this.score
  }

  setScore (value: number) {
    this.score = value
  }

  getMetConfidenceLevel (): boolean {
    return this.metConfidenceLevel
  }

  setMetConfidenceLevel (value: boolean) {
    this.metConfidenceLevel = value
  }

  getKeywordGroupId (): number {
    return this.keywordGroupId
  }

  setKeywordGroupId (value: number) {
    this.keywordGroupId = value
  }

  fromApiTransformer (data: JsonData, api: Api): SpottedKeyword {
    apiToModelTransformer(this, data, this.fieldDefinition[api])

    return this
  }

  toApiTransformer (): undefined {
    return undefined
  }
}
