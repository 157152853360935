import Repository, { DefaultLimit } from '@infinity/ca-tester/repos/base'
import { Api, JsonData } from '@infinity/shared/helpers/api'
import { AuthUtil } from '@infinity/shared/utils/auth'
import KeywordSpottingConfig from '@infinity/ca-tester/models/keywordSpottingConfig'
import { FilterCollection } from '@infinity/ca-tester/helpers/filter'

export default class KeywordSpottingConfigRepo extends Repository {
  constructor () {
    super(
      Api.CaService,
      `installation/${AuthUtil.installationId}/keywordConfig`,
      KeywordSpottingConfig,
      'data'
    )
  }

  async fetch (filterCollection: FilterCollection | null = null, limit: number = DefaultLimit, offset = 0): Promise<KeywordSpottingConfig[]> {
    const data = await super.fetch(filterCollection, limit, offset)
    const models = []

    for (const item of data) {
      if (item instanceof KeywordSpottingConfig) {
        models.push(item)
      }
    }

    return models
  }

  async postBulk (models: KeywordSpottingConfig[], getter: string | null = null): Promise<JsonData | null> {
    return await super.postBulk(models, getter)
  }
}
